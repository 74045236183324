export const VIEW_DASHBOARD = 'view_dashboard';
export const VIEW_SURVEY = 'view_survey';
export const VIEW_TO_DO_LIST = 'view_to_do_list';
export const VIEW_ALL_RESPONSE = 'view_all_responses';
export const VIEW_TAG_TAG_GROUP = 'view_tag_tag_group';
export const CREATE_EDIT_SURVEY = 'create_edit_survey';
export const CREATE_EDIT_TAG_TAG_GROUP = 'edit_tag_tag_group';
export const VIEW_MY_TODO = 'view_my_to_do';
export const EDIT_RESPONSE = 'edit_responses';
export const DELETE_RESPONSE = 'delete_responses';
