import classNames from 'classnames';
import {
  useCallback, useEffect, useState,
} from 'react';

import ConfirmModal from 'components/ConfirmModal';
import CustomInputGroup from 'components/CustomInputGroup';
import CustomModal from 'components/CustomModal';
import { TagGroupIcon } from 'components/Icons';
import TagItem from 'components/TagItem';
import { ISearchSurveyTagResponse } from 'models/ISearchSurveyTagResponse';
import Tag from 'models/Tag';
import TagGroup from 'models/TagGroup';
import { searchSurveyTags } from 'services/tag';
import { debounce } from 'utils/debounce';
import { useTranslation } from 'react-i18next';

interface ITagGroupModal {
  onAddTag: (tagObj: Tag) => void,
  onCloseModal: () => void,
  tagGroupModal: {
    title: string,
    placeHolder: string,
    buttonText: string,
    mode: string
  }
  tagGroup: TagGroup | undefined,
  handleRemoveTagItem: (tagId: number, fetchTagDataByGroupTag?: boolean) => void
}

const TagGroupModal = (props: ITagGroupModal) => {
  const {
    onAddTag,
    onCloseModal,
    tagGroupModal,
    tagGroup,
    handleRemoveTagItem,
  } = props;

  const [searchTag, setSearchTag] = useState<ISearchSurveyTagResponse>();
  const [listSearchTags, setListSearchTags] = useState<ISearchSurveyTagResponse[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [active, setActive] = useState(0);
  const [isOpenConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [tagId, setTagId] = useState<number>(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (searchTag) {
      setSearchText(searchTag?.tagName ?? '');
      setListSearchTags([]);
    }
  }, [searchTag]);

  const keyDownHandler = (event: any) => {
    if (event.keyCode === 38 && active > 0) {
      setActive(active - 1);
      event.preventDefault();
    } else if (event.keyCode === 40 && active < listSearchTags.length - 1) {
      setActive(active + 1);
      event.preventDefault();
    }
  };

  const onChangeInput = (data: string) => {
    setSearchText(data);
    setSearchTag(undefined);
  };

  const onSearchTag = useCallback(debounce((data: string) => {
    if (data) {
      (async () => {
        const result = await searchSurveyTags({ tagName: data });
        setListSearchTags(result);
        if (!result || result.length === 0) {
          setSearchTag(undefined);
        }
      })();
    } else {
      setListSearchTags([]);
    }
  }, 300), []);

  useEffect(() => {
    if (searchText
      && searchTag?.tagName !== searchText
      && searchText.length >= 3) {
      onSearchTag(searchText);
    }
  }, [searchText]);

  const onRemoveTag = (tagIdValue: number) => {
    setOpenConfirmModal(true);
    setTagId(tagIdValue);
  };

  const handleOpenConfirmModal = () => {
    setOpenConfirmModal(!isOpenConfirmModal);
  };

  const handleAddTag = () => {
    onAddTag(searchTag ?? { tagName: searchText, tagId: 0 });
    setSearchText('');
    setListSearchTags([]);
  };

  if (isOpenConfirmModal) {
    return (
      <ConfirmModal
        title={t('TS.TAG.REMOVE_TAG')}
        content={t('TS.TAG.ADD_REMOVE_TAG')}
        okLabel={t('TS.YES')}
        onConfirmModal={async () => {
          await handleRemoveTagItem(tagId, true);
          handleOpenConfirmModal();
        }}
        onCloseModal={handleOpenConfirmModal}
      />
    );
  }

  return (
    <CustomModal
      className="tag-group-modal"
      title={tagGroupModal.title}
      onCancel={onCloseModal}
      visible
      width={400}
    >
      <CustomInputGroup
        onChangeInput={onChangeInput}
        onSubmitButton={handleAddTag}
        value={searchText}
        placeholder={tagGroupModal.placeHolder}
        buttonText={tagGroupModal.buttonText || t('TS.ADD')}
        iconBefore={<TagGroupIcon />}
        onKeyDown={keyDownHandler}
        autoFocus
      />
      <div className="tags-select__list">
        {
          (listSearchTags.length > 0 && listSearchTags.map((tag, index) => (
            <button
              key={tag.tagId}
              type="button"
              className={classNames({
                'tags-select__item': true,
                'tags-select__item--active': active === index,
              })}
              onClick={() => setSearchTag(tag)}
            >
              {tag.tagName}

            </button>
          ))) || (searchText.length >= 3 && !searchTag && <span>{t('TS.NEW_TAG')}</span>)
        }
      </div>
      <div className="tag-modal__tag-items">
        {
          tagGroup?.tags?.map((tag) => (
            <TagItem
              tag={tag}
              onClick={() => onRemoveTag(tag.tagId ?? 0)}
              key={tag.tagId}
              showEdit={false}
            />
          ))
        }
      </div>

    </CustomModal>
  );
};

export default TagGroupModal;
