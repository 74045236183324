import { Col, Row, Typography } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

import Chart from './Chart';
import ClubsModal from './ClubsModal';
import MentionList from './MentionList';
import TableClub from './TableClub';
import TotalNps from './TotalNps';

import CustomRangeDatePicker from 'components/CustomRangeDatePicker';
import CustomSelect from 'components/CustomSelect';
import Loading from 'components/Loading';
import { DATE_FORMAT_DD_MM_YYYY, DATE_FORMAT_YYYY_MM_DD } from 'constants/date';
import {
  FILTER_CLUB,
  FILTER_DATE_PICKER,
  FILTER_MOST_MENTION,
  FILTER_MOST_RECENT,
  FILTER_TYPE_ALL,
  FILTER_DASHBOARD_DEFAULT,
  FILER_TYPE_SE,
} from 'constants/filter';
import { REQUEST_DASHBOARD_DEFAULT } from 'constants/request';
import Club from 'models/Club';
import Filter from 'models/Dashboard/Filter';
import RequestDashboard from 'models/Dashboard/RequestDashboard';
import { IChartData } from 'models/IChartData';
import { ImageSurveyFeedback } from 'models/Survey/ImageSurveyFeedback';
import Survey from 'models/Survey/Survey';
import { sortClubByPoint } from 'selectors/club';
import { getClubListDashBoard } from 'services/club';
import { getDashBoardFeedbacks, getDashBoardPoints } from 'services/survey';
import { getTagGroupChartData } from 'services/tag';
import { NOTIFICATION_TYPE } from 'utils/constants';
import { showNotification } from 'utils/helpers';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const DashboardPage = () => {
  const [requestDashboard, setRequestDashboard] = useState<RequestDashboard>(
    REQUEST_DASHBOARD_DEFAULT,
  );

  const { t } = useTranslation();
  const [averagePoint, setAveragePoint] = useState(0);
  const [clubPoint, setClubPoint] = useState<Club[]>([]);
  const [isLoadingPoint, setLoadingPoint] = useState<boolean>(false);
  const [isLoadingFeedback, setLoadingFeedback] = useState<boolean>(false);
  const [totalNegativePoint, setTotalNegativePoint] = useState<number>(0);
  const [totalNeutralPoint, setTotalNeutralPoint] = useState<number>(0);
  const [totalPositivePoint, setTotalPositivePoint] = useState<number>(0);
  const [surveyPositiveFeedbacks, setSurveyPositiveFeedbacks] = useState<
    Survey[]
  >([]);
  const [surveyNeutralFeedbacks, setSurveyNeutralFeedbacks] = useState<
    Survey[]
  >([]);
  const [surveyNegativeFeedbacks, setSurveyNegativeFeedbacks] = useState<
    Survey[]
  >([]);
  const [tagGroupNameTemplate, setTagGroupNameTemplate] = useState<string>('');
  const [shouldShowClubsModal, setShouldShowClubsModal] = useState<boolean>(false);
  const [chartData, setChartData] = useState<IChartData[]>([]);
  const [clubs, setClub] = useState<Club[]>([]);
  const [currentMention, setCurrentMention] = useState<number>(FILTER_MOST_MENTION);

  const resetRequestDashboard = useCallback(() => {
    requestDashboard.filters = [...FILTER_DASHBOARD_DEFAULT];
    setRequestDashboard(new RequestDashboard({ ...requestDashboard }));
  }, []);

  useEffect(() => {
    resetRequestDashboard();
  }, [resetRequestDashboard]);

  const formSurveyFeedback = (
    feedbacks: Survey[],
    imageFeedbacks: ImageSurveyFeedback[],
  ) => {
    const result = feedbacks.map((feedback: Survey) => {
      const imgFeedbacks = imageFeedbacks.find(
        (imgFeedback: ImageSurveyFeedback) => imgFeedback.memberId === feedback.memberId,
      );
      if (imgFeedbacks) {
        return {
          ...feedback,
          imageUrl: imgFeedbacks.imagePath,
        };
      }
      return feedback;
    });
    return result;
  };

  const handleShowError = (error: any) => {
    if (
      typeof error.response.data.isSuccess !== 'undefined'
      && error.response.data.isSuccess !== false
    ) {
      showNotification(
        t('TS.ERROR'),
        t('TS_NOTIFICATION_SERVICES_NOTIFICATION'),
        NOTIFICATION_TYPE.ERROR,
      );
    }
  };

  const fetchDashboardFeedBack = useCallback(
    async (request: RequestDashboard, isMostRecent: boolean) => {
      try {
        setLoadingFeedback(true);
        const feedbacks = await getDashBoardFeedbacks(
          request.formatDashBoardFeedbackForApi(),
          isMostRecent,
        );
        const positiveFeedbacks = formSurveyFeedback(
          feedbacks.surveyPositiveFeedbacks || [],
          feedbacks.imageFeedbacks || [],
        );
        const negativeFeedbacks = formSurveyFeedback(
          feedbacks.surveyNegativeFeedbacks || [],
          feedbacks.imageFeedbacks || [],
        );
        const neutralFeedbacks = formSurveyFeedback(
          feedbacks.surveyNeutralFeedbacks || [],
          feedbacks.imageFeedbacks || [],
        );
        setTotalNegativePoint(feedbacks.totalNegativePoint);
        setTotalNeutralPoint(feedbacks.totalNeutralPoint);
        setTotalPositivePoint(feedbacks.totalPositivePoint);
        setSurveyNegativeFeedbacks(negativeFeedbacks);
        setSurveyNeutralFeedbacks(neutralFeedbacks);
        setSurveyPositiveFeedbacks(positiveFeedbacks);
        setTagGroupNameTemplate(feedbacks.tagGroupName ?? '');
        setLoadingFeedback(false);
      } catch (error: any) {
        handleShowError(error);
      } finally {
        setLoadingFeedback(false);
      }
    },
    [],
  );

  const fetchDashboardPoint = useCallback(async (request: RequestDashboard) => {
    try {
      setLoadingPoint(true);
      const response = await getDashBoardPoints(
        request.formatDashboardPointForApi(),
      );
      setAveragePoint(response.averagePoint);
      setClubPoint(response.clubPointDtos);
      setLoadingPoint(false);
    } catch (error: any) {
      handleShowError(error);
    } finally {
      setLoadingPoint(false);
    }
  }, []);

  const getChartData = async (request: RequestDashboard) => {
    const filterDatePicker = request.findFilterByName(FILTER_DATE_PICKER);
    const filterClub = request.findFilterByName(FILTER_CLUB);
    if (
      filterDatePicker
      && typeof filterDatePicker.value1 === 'string'
      && typeof filterDatePicker.value2 === 'string'
    ) {
      try {
        const res = await getTagGroupChartData(
          filterDatePicker.value1,
          filterDatePicker.value2,
          filterClub ? Number(filterClub.value1) : null,
        );
        setChartData(
          res.map((grp: any) => ({
            group: grp.tagGroupName,
            positive: grp.positivePer,
            neutral: grp.neutralPer,
            negative: grp.negativePer,
            positiveNum: grp.positive,
            neutralNum: grp.neutral,
            negativeNum: grp.negative,
          })),
        );
      } catch (error: any) {
        handleShowError(error);
      }
    }
  };

  const handleChangeSearchDate = (dates: [moment.Moment, moment.Moment]) => {
    const filterDatePicker = requestDashboard.findFilterByName(FILTER_DATE_PICKER);
    if (filterDatePicker) {
      if (typeof filterDatePicker.value1 !== 'undefined') {
        filterDatePicker.value1 = dates[0].format(DATE_FORMAT_YYYY_MM_DD);
      }
      if (typeof filterDatePicker.value2 !== 'undefined') {
        filterDatePicker.value2 = dates[1].format(DATE_FORMAT_YYYY_MM_DD);
      }
      const newRequestDashboard = new RequestDashboard({
        filters: [
          ...requestDashboard.filters
            .filter((filter) => filter.name !== FILTER_DATE_PICKER)
            .concat([filterDatePicker]),
        ],
      });
      setRequestDashboard(newRequestDashboard);
      fetchDashboardPoint(newRequestDashboard);
      getChartData(newRequestDashboard);
    }
  };

  const handleChangeMention = (mentionSelected: string) => {
    setCurrentMention(Number(mentionSelected));
    fetchDashboardFeedBack(requestDashboard, mentionSelected === FILTER_MOST_RECENT.toString());
  };

  const handleShowClubsModal = () => {
    setShouldShowClubsModal(!shouldShowClubsModal);
  };

  const handleShowAllClub = () => {
    requestDashboard.filters.push(
      new Filter({
        name: FILTER_CLUB,
        type: FILTER_TYPE_ALL,
      }),
    );
    setRequestDashboard(
      new RequestDashboard({
        filters: [...requestDashboard.filters],
      }),
    );
    handleShowClubsModal();
  };

  const getClubForDashBoard = () => {
    getClubListDashBoard().then((response) => {
      setClub(response);
      if (response.length > 0) {
        const clubId = response[0].id;
        requestDashboard.filters.push(
          new Filter({
            name: FILTER_CLUB,
            type: clubId ? FILER_TYPE_SE : FILTER_TYPE_ALL,
            value1: clubId ? [clubId] : undefined,
          }),
        );
      } else {
        requestDashboard.filters.push(
          new Filter({
            name: FILTER_CLUB,
            type: FILTER_TYPE_ALL,
          }),
        );
      }
      setRequestDashboard(
        new RequestDashboard({
          ...requestDashboard,
          filters: requestDashboard.filters,
        }),
      );
      fetchDashboardFeedBack(
        requestDashboard,
        currentMention === FILTER_MOST_RECENT,
      );
      fetchDashboardPoint(requestDashboard);
      getChartData(requestDashboard);
    });
  };

  useEffect(() => {
    getClubForDashBoard();
  }, []);

  const handleOnChangeClub = (clubId: string) => {
    const filterClub = requestDashboard.findFilterByName(FILTER_CLUB);
    if (filterClub) {
      filterClub.type = clubId !== 'undefined' ? FILER_TYPE_SE : FILTER_TYPE_ALL;
      filterClub.value1 = clubId !== 'undefined' ? [Number(clubId)] : undefined;
      const newRequestDashBoard = new RequestDashboard({
        filters: [
          ...requestDashboard.filters.filter(
            (filter) => filter.name !== FILTER_CLUB,
          ),
          filterClub,
        ],
      });
      setRequestDashboard(newRequestDashBoard);
      fetchDashboardFeedBack(
        newRequestDashBoard,
        currentMention === FILTER_MOST_RECENT,
      );
      fetchDashboardPoint(newRequestDashBoard);
      getChartData(newRequestDashBoard);
    }
  };

  const filterClub = requestDashboard.findFilterByName(FILTER_CLUB);

  return (
    <>
      <div className="dashboard">
        <div className="dashboard__wrapper">
          {clubs.length > 0 && (
            <div className="dashboard__wrapper-clubs-dropdown">
              <CustomSelect
                label={t('ALL_RESPONSES_FILTER_CLUB')}
                options={clubs.map((club) => ({
                  label: club.name,
                  value: String(club.id),
                }))}
                onChange={handleOnChangeClub}
                value={String(filterClub?.value1)}
              />
            </div>
          )}
          <Row>
            <Col span={14}>
              <div className="dashboard__overall">
                <div className="dashboard__overall-header">
                  <Title level={5}>{t('TS.DASHBOARD.OVERALL')}</Title>
                  <CustomRangeDatePicker
                    label={t('TS.DASHBOARD.RECEIVED_RESPONSES_DATE')}
                    value={requestDashboard.getDatePickerForMoment()}
                    separator={<span>{t('TS.TO').toLocaleLowerCase()}</span>}
                    onChange={handleChangeSearchDate}
                    format={DATE_FORMAT_DD_MM_YYYY}
                  />
                </div>
                <div className="dashboard__overall-body">
                  <div
                    className="dashboard__overall-body-report"
                    id="report-content"
                  >
                    <TotalNps total={averagePoint} loading={isLoadingPoint} />
                    <TableClub
                      clubPoint={sortClubByPoint(clubPoint).slice(0, 3)}
                      onShowAllClub={handleShowAllClub}
                      loading={isLoadingPoint}
                    />
                  </div>
                  <Chart
                    data={chartData}
                    height={400}
                    width={
                      document.getElementById('report-content')?.clientWidth
                    }
                  />
                </div>
              </div>
            </Col>
            <Col span={10}>
              <div className="dashboard__mention">
                <div className="dashboard__mention-header">
                  <Title level={5}>{tagGroupNameTemplate}</Title>
                  <CustomSelect
                    onChange={handleChangeMention}
                    value={currentMention.toString()}
                    options={[
                      {
                        value: String(FILTER_MOST_MENTION),
                        label: t('TS.MOST.MENTION'),
                      },
                      {
                        value: String(FILTER_MOST_RECENT),
                        label: t('TS.MOST.RECENT'),
                      },
                    ]}
                    showSearch={false}
                    wrapperClassName="dashboard__mention-dropdown"
                  />
                </div>
                {isLoadingFeedback ? (
                  <Loading loadingMode="component" />
                ) : (
                  <MentionList
                    surveyPositiveFeedbacks={surveyPositiveFeedbacks}
                    surveyNeutralFeedbacks={surveyNeutralFeedbacks}
                    surveyNegativeFeedbacks={surveyNegativeFeedbacks}
                    totalPositivePoint={totalPositivePoint}
                    totalNeutralPoint={totalNeutralPoint}
                    totalNegativePoint={totalNegativePoint}
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {shouldShowClubsModal && (
        <ClubsModal
          onCloseModal={handleShowClubsModal}
          clubPoints={clubPoint}
          loadingClubs={isLoadingPoint}
        />
      )}
    </>
  );
};

export default DashboardPage;
