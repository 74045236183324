import {
  Col, Form, Row,
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import MemberItem from './MemberItem';

import CustomButton from 'components/CustomButton';
import { AddIcon } from 'components/Icons';

export interface IMemberContent {
  form: any;
}

const MemberContent: React.FC<IMemberContent> = ({ form }) => {
  const { t } = useTranslation();
  const addField = (): void => {
    const surveyMember = form.getFieldValue('surveyMember') || [];
    form.setFieldsValue({
      surveyMember: [
        ...surveyMember,
        {},
      ],
    });
  };
  return (
    <>
      <Row>
        <Col className="d-flex" md={7}>
          <p>{t('TS.CREATE_SURVEY.CRITERIA')}</p>
        </Col>
        <Col className="d-flex m-16" md={7}>
          <p>{t('TS.CREATE_SURVEY.OPERATOR')}</p>
        </Col>
        <Col className="d-flex" md={7}>
          <p>{t('TS.CREATE_SURVEY.VALUE')}</p>
        </Col>
      </Row>

      <Form.List name="surveyMember">
        {(fields, { remove }) => (
          <>
            {fields.map((field, parentIdx) => (
              <div key={field.key}>
                <MemberItem
                  form={form}
                  remove={remove}
                  groupField={field}
                  parentIdx={parentIdx}
                  fieldsLength={fields.length}
                />
              </div>
            ))}
          </>
        )}
      </Form.List>
      <div className="create-survey__section-footer">
        <CustomButton
          label={t('TS.CREATE_SURVEY.ADD_BUTTON')}
          backgroundColor="white"
          icon={<AddIcon />}
          onClick={addField}
        />
      </div>
    </>
  );
};

export default MemberContent;
