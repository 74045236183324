export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_FORMAT_DD_MM_YYYY = 'DD/MM/YYYY';
export const DATE_DEFAULT = 'YYYY-MM-DD';
export const DATE_FORMAT_DAY_MONTH = 'DD MMM';
export const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';
export const DATE_FORMAT_MONTH_DAY = 'MMM DD';
export const DATE_FORMAT_WITH_TIME = 'DD MMM YYYY, HH:mm A';

export default {
  DATE_FORMAT,
  DATE_FORMAT_DD_MM_YYYY,
  DATE_DEFAULT,
  DATE_FORMAT_DAY_MONTH,
  DATE_FORMAT_YYYY_MM_DD,
  DATE_FORMAT_MONTH_DAY,
};
