import { Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

import TagGroupItem from './TagGroupItem';

import ConfirmModal from 'components/ConfirmModal';
import CreateEditTagGroupModal from 'components/CreateEditTagGroupModal';
import CustomButton from 'components/CustomButton';
import CustomInputGroup from 'components/CustomInputGroup';
import { AddIcon } from 'components/Icons';
import Loading from 'components/Loading';
import TagGroupModal from 'components/TagGroupModal';
import { ORANGE } from 'constants/button';
import { CREATE_EDIT_TAG_TAG_GROUP } from 'constants/roles';
import {
  MODE_ADD,
  MODE_ADD_TAG,
  MODE_EDIT,
} from 'constants/tagGroup';
import Tag, { ITag } from 'models/Tag';
import TagGroup from 'models/TagGroup';
import { getOperatorId } from 'services/auth';
import {
  assignTagToTagGroup,
  createTagGroup,
  deleteTagGroup,
  editTagGroup,
  getAllTagGroups,
  getAllTagsByTagGroup,
  unAssignTagToTagGroup,
} from 'services/tag';
import { useAppSelector } from 'store/hooks';
import { NOTIFICATION_TYPE } from 'utils/constants';
import { showNotification } from 'utils/helpers';

const { Title } = Typography;

interface ITagGroupModal {
  title: string;
  placeHolder: string;
  buttonText: string;
  mode: string;
}

const TagGroupPage = () => {
  const { t } = useTranslation();
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [isOpenCreateEditModal, setIsOpenCreateEditModal] = useState<boolean>(false);
  const [tagGroupModal, setTagGroupModal] = useState<ITagGroupModal>({
    title: '',
    placeHolder: '',
    buttonText: '',
    mode: '',
  });
  const [tagGroupList, setTagGroupList] = useState<TagGroup[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [tagGroup, setTagGroup] = useState<TagGroup>();
  const operatorId = getOperatorId(useAuth().user);
  const { permissions } = useAppSelector((state) => state.role);
  const [loading, setLoading] = useState<boolean>(false);
  const [openModalDeleteTagItem, setOpenModalDeleteTagItem] = useState(false);
  const [
    openConfirmModalDeleteTagGroup,
    setOpenConfirmModalDeleteTagGroup,
  ] = useState<boolean>(false);
  const [tagIdDeleted, setTagIdDeleted] = useState<number>();
  let isClosePopupModal = false;

  const fetchTagGroupData = useCallback(async (name?: string) => {
    setLoading(true);
    try {
      const { data } = await getAllTagGroups({ tagGroupName: name ?? '' });
      setTagGroupList(data);
      setLoading(false);
    } catch (e) {
      showNotification(
        t('TS.ERROR'),
        t('TS_NOTIFICATION_SERVICES_NOTIFICATION'),
        NOTIFICATION_TYPE.ERROR,
      );
      setLoading(false);
    }
  }, [t]);

  const fetchAllTagByTagGroup = (async (tagGroupId?: number) => {
    try {
      if (tagGroupId && tagGroup) {
        const { data } = await getAllTagsByTagGroup({ tagGroupId });
        tagGroup.tags = data;
        setTagGroup(new TagGroup(tagGroup));
      }
    } catch (err) {
      showNotification(
        t('TS.ERROR'),
        t('TS_NOTIFICATION_SERVICES_NOTIFICATION'),
        NOTIFICATION_TYPE.ERROR,
      );
    }
  });

  useEffect(() => {
    fetchTagGroupData();
  }, [fetchTagGroupData]);

  const onAddTag = ({ tagName, tagId }: ITag, tagGroupId: number) => {
    (async () => {
      try {
        const { error, status } = await assignTagToTagGroup({
          operatorId: Number(operatorId),
          tagName,
          tagGroupId,
          tagId: tagId ?? 0,
        });
        if (status && tagGroup?.id) {
          fetchTagGroupData();
          fetchAllTagByTagGroup(tagGroup?.id);
        } else {
          showNotification('Error', error, NOTIFICATION_TYPE.ERROR);
        }
      } catch (err) {
        showNotification(
          t('TS.ERROR'),
          t('TS_NOTIFICATION_SERVICES_NOTIFICATION'),
          NOTIFICATION_TYPE.ERROR,
        );
      }
    })();
  };

  const handleOpenModalTagGroup = (mode: string, tagG?: TagGroup) => {
    switch (mode) {
      case MODE_ADD:
        setTagGroup(new TagGroup({ tagGroupName: '' }));
        setTagGroupModal({
          title: t('TS.TAG.CREATE_TAG'),
          placeHolder: t('TS.TAG.CREATE.PLACEHOLDER'),
          buttonText: t('TS.CREATE'),
          mode: MODE_ADD,
        });
        setIsOpenCreateEditModal(true);
        break;
      case MODE_EDIT:
        if (tagG) {
          setTagGroup(new TagGroup(tagG));
        }
        setTagGroupModal({
          title: t('TS.EDIT_TAG_GROUP'),
          placeHolder: '',
          buttonText: t('TS.SAVE'),
          mode: MODE_EDIT,
        });
        setIsOpenCreateEditModal(true);
        break;
      case MODE_ADD_TAG:
        if (tagG) {
          setTagGroup(new TagGroup(tagG));
        }
        setTagGroupModal({
          title: t('TS.TAG.ADD_TAG_MODAL_TITLE'),
          placeHolder: t('TS.TAG.SEARCH_TAG'),
          buttonText: t('TS.ADD'),
          mode: MODE_ADD_TAG,
        });
        setOpenModal(true);
        break;
      default:
        break;
    }
  };

  const handleCloseModalTagGroup = () => {
    setOpenModal(false);
    setIsOpenCreateEditModal(false);
  };

  const handleOpenConfirmModalDeleteTagGroup = (tagGrp? : TagGroup) => {
    setOpenConfirmModalDeleteTagGroup(!openConfirmModalDeleteTagGroup);
    setTagGroup(tagGrp);
  };

  const checkTagGroupNameDuplicate = (error: string) => {
    if (error.includes('exists')) {
      isClosePopupModal = false;
    } else {
      isClosePopupModal = true;
    }
    showNotification(t('TS.ERROR'), error, NOTIFICATION_TYPE.ERROR);
  };

  const createNewTagGroup = async () => {
    setLoading(true);
    try {
      const res = await createTagGroup(
        tagGroup?.tagGroupName ?? '',
        operatorId,
      );
      if (res.status) {
        fetchTagGroupData();
        isClosePopupModal = true;
      } else {
        checkTagGroupNameDuplicate(res.error);
      }
    } catch {
      showNotification(
        t('TS.ERROR'),
        t('TS_NOTIFICATION_SERVICES_NOTIFICATION'),
        NOTIFICATION_TYPE.ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const updateTagGroup = async () => {
    setLoading(true);
    try {
      const res = await editTagGroup(
        tagGroup?.id ?? 0,
        operatorId,
        tagGroup?.tagGroupName ?? '',
      );
      if (res.status) {
        fetchTagGroupData();
        isClosePopupModal = true;
      } else {
        checkTagGroupNameDuplicate(res.error);
      }
    } catch (error) {
      showNotification(
        t('TS.ERROR'),
        t('TS_NOTIFICATION_SERVICES_NOTIFICATION'),
        NOTIFICATION_TYPE.ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const removeTagGroup = async () => {
    setLoading(true);
    try {
      const res = await deleteTagGroup(operatorId, tagGroup?.id ?? 0);
      if (res.status) {
        fetchTagGroupData();
      } else {
        showNotification(t('TS.ERROR'), res.error, NOTIFICATION_TYPE.ERROR);
      }
    } catch (error) {
      showNotification(
        t('TS.ERROR'),
        t('TS_NOTIFICATION_SERVICES_NOTIFICATION'),
        NOTIFICATION_TYPE.ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveTag = () => {
    handleOpenConfirmModalDeleteTagGroup();
    removeTagGroup();
  };

  const handleSubmitTagGroupModal = async () => {
    if (tagGroupModal.mode === MODE_ADD) {
      await createNewTagGroup();
    } else if (tagGroupModal.mode === MODE_EDIT) {
      await updateTagGroup();
    }
    if (isClosePopupModal) {
      handleCloseModalTagGroup();
    }
  };

  const handleChangeCreateEditTagGroup = (value: string) => {
    if (tagGroup) {
      tagGroup.tagGroupName = value;
      setTagGroup(new TagGroup(tagGroup));
    }
  };

  const handleAddTag = (tagObj: Tag) => {
    onAddTag(
      { tagName: tagObj.tagName, tagId: tagObj.tagId },
      Number(tagGroup?.id),
    );
  };

  const onChangeSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleSearch = () => {
    fetchTagGroupData(searchValue);
  };

  const handleOpenDeleteModalTagItem = () => {
    setOpenModalDeleteTagItem(!openModalDeleteTagItem);
  };

  const handleRemoveTagItem = async (tagId: number, fetchTagDataByTagGroup?: boolean) => {
    try {
      const { status } = await unAssignTagToTagGroup({
        tagGroupId: Number(tagGroup?.id ?? 0),
        operatorId: Number(operatorId),
        tagId: (tagId ?? tagIdDeleted ?? 0),
      });
      if (status && tagGroup?.id && fetchTagDataByTagGroup) {
        fetchTagGroupData();
        fetchAllTagByTagGroup(tagGroup?.id);
      } else if (!fetchTagDataByTagGroup) {
        fetchTagGroupData();
      }
      setOpenModalDeleteTagItem(false);
    } catch (err) {
      showNotification(
        t('TS.ERROR'),
        t('TS_NOTIFICATION_SERVICES_NOTIFICATION'),
        NOTIFICATION_TYPE.ERROR,
      );
    }
  };

  return (
    <>
      <div className="tag-page">
        <div className="tag-page__header">
          <Title className="tag-page__header-title" level={5}>
            {t('TS.TAG.TITLE')}
          </Title>
        </div>
        <div className="tag-page__body">
          <div className="tag-page__body-header">
            {permissions.includes(CREATE_EDIT_TAG_TAG_GROUP) && (
            <CustomButton
              label={t('TS.TAG.CREATE_TAG').toUpperCase()}
              onClick={() => handleOpenModalTagGroup(MODE_ADD)}
              backgroundColor={ORANGE}
              icon={<AddIcon />}
            />
            )}
            <CustomInputGroup
              placeholder={t('TS.TAG.SEARCH')}
              onChangeInput={onChangeSearch}
              onSubmitButton={handleSearch}
              value={searchValue}
              defaultValue=""
              buttonText={t('TS.SEARCH')}
            />
          </div>
          {
          loading ? <Loading loadingMode="component" /> : (
            <div className="tag-page__body-content">
              {tagGroupList && tagGroupList?.length > 0 ? (
                tagGroupList?.map((tagGroupItem) => (
                  <TagGroupItem
                    key={tagGroupItem.createdDate}
                    tagGroup={tagGroupItem}
                    onChangeClickTagGroup={handleOpenModalTagGroup}
                    onRemoveTagGroup={handleOpenConfirmModalDeleteTagGroup}
                    permissions={permissions}
                    setTagIdDeleted={setTagIdDeleted}
                    setTagGroup={setTagGroup}
                    onOpenDeleteTagItemModal={handleOpenDeleteModalTagItem}
                  />
                ))
              ) : (
                <span className="tag-page__body-no-tag">
                  {t('TS.TAG.NO_TAG')}
                </span>
              )}
            </div>
          )
        }
        </div>
      </div>
      {isOpenModal && (
        <TagGroupModal
          tagGroupModal={tagGroupModal}
          tagGroup={tagGroup}
          onAddTag={handleAddTag}
          onCloseModal={handleCloseModalTagGroup}
          handleRemoveTagItem={handleRemoveTagItem}
        />
      )}
      {isOpenCreateEditModal && (
        <CreateEditTagGroupModal
          value={tagGroup?.tagGroupName ?? ''}
          title={tagGroupModal.title}
          placeholder={tagGroupModal.placeHolder}
          buttonText={tagGroupModal.buttonText}
          onSubmitModal={handleSubmitTagGroupModal}
          onCloseModal={handleCloseModalTagGroup}
          onChangeTagGroup={handleChangeCreateEditTagGroup}
        />
      )}
      {openConfirmModalDeleteTagGroup && (
        <ConfirmModal
          title={t('TS.TAG.REMOVE_TAG_GROUP')}
          content={t('TS.TAG.ADD_REMOVE_TAG_GROUP')}
          onConfirmModal={handleRemoveTag}
          onCloseModal={handleOpenConfirmModalDeleteTagGroup}
        />
      )}
      {openModalDeleteTagItem && (
        <ConfirmModal
          title={t('TS.TAG.REMOVE_TAG')}
          content={t('TS.TAG.ADD_REMOVE_TAG')}
          okLabel={t('TS.YES')}
          onConfirmModal={() => handleRemoveTagItem(tagIdDeleted ?? 0, false)}
          onCloseModal={handleOpenDeleteModalTagItem}
        />
      )}
    </>
  );
};
export default TagGroupPage;
