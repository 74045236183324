import { http } from './common';
import {
  ASSIGN_OPERATOR_TO_FEEDBACK,
  CREATE_SURVEY_NOTE,
  GET_NOTES,
  GET_OPERATORS,
  GET_RESPONSES,
  GET_SURVEYS,
} from './common/apis';

import { IAssignOperatorFeedbackRequest } from 'models/IAssignOperatorFeedbackRequest';
import { IAssignOperatorFeedbackResponse } from 'models/IAssignOperatorFeedbackResponse';
import { IOperatorResponse } from 'models/IOperatorResponse';
import { IResponseRequest } from 'models/IResponseRequest';
import { IResponseResponse } from 'models/IResponseResponse';
import { ISurveyResponse } from 'models/ISurveyResponse';

export const getResponses = async (body: IResponseRequest): Promise<IResponseResponse> => {
  const res = await http.post(GET_RESPONSES, body);
  return res.data;
};

export const getSurveys = async (): Promise<ISurveyResponse> => {
  const res = await http.get(GET_SURVEYS);
  return res.data;
};

export const getOperators = async (): Promise<IOperatorResponse> => {
  const res = await http.get(GET_OPERATORS);
  return res.data;
};

export const assignOperatorToFeedback = async (data: IAssignOperatorFeedbackRequest):
  Promise<IAssignOperatorFeedbackResponse> => {
  const res = await http.post(ASSIGN_OPERATOR_TO_FEEDBACK, data);
  return res.data;
};

export const getNoteList = async (payload: {
  FeedbackId: number,
  pageIndex: number,
  pageSize: number
}) => {
  const response = await http.post(GET_NOTES, payload);
  return response.data;
};

export const createSurveyNote = async (payload: {
  operatorId: number,
  feedbackId: number,
  note: string
}) => {
  const response = await http.post(CREATE_SURVEY_NOTE, payload);
  return response.data;
};

export default {
  getResponses,
  getSurveys,
  getOperators,
  getNoteList,
};
