import { http } from './common';
import { GET_ALL_ROLE_RIGHTS } from './common/apis';

import { IRole } from 'models/Role';

const getAllRole = async (operatorId: any): Promise<IRole[]> => {
  const res = await http.get(GET_ALL_ROLE_RIGHTS.replace('{operatorId}', operatorId));

  return res.data.data;
};

export default getAllRole;
