import {
  Collapse,
} from 'antd';
import classNames from 'classnames';
import { useState } from 'react';

import TagList from '../../../../components/TagList';

import ResponseHeader from 'components/ResponseHeader';
import TagModal from 'components/TagModal';
import Operator from 'models/Operator';
import Response, { IResponse } from 'models/Response';

const { Panel } = Collapse;

interface IResponseList {
  responses: Response[];
  operators: Operator[];
  onClickHistory: (response: IResponse, event?: Event) => void,
  assignOperator?: (assigneeId: string, feedbackId: string) => void,
  fetchDataResponse: () => void
}

const ResponseList = (props: IResponseList) => {
  const {
    responses,
    operators,
    assignOperator = () => null,
    onClickHistory,
    fetchDataResponse,
  } = props;

  const [shouldShowAddTagModal, setShouldShowAddTagModal] = useState<boolean>(false);
  const [selectedFeedbackId, setSelectedFeedbackId] = useState<number>();
  const [editTagId, setEditTagId] = useState<number>();
  const [focusedAddTag, setFocusedAddTag] = useState<boolean>(false);
  const [responseIdList, setResponseIdList] = useState<string[]>([]);

  const onClickAddTag = (feedbackId: number) => {
    setSelectedFeedbackId(feedbackId);
    setEditTagId(undefined);
    setShouldShowAddTagModal(true);
    setFocusedAddTag(true);
  };

  const onEditTag = (tagId: number, feedbackId: number) => {
    setEditTagId(tagId);
    setSelectedFeedbackId(feedbackId);
    setShouldShowAddTagModal(true);
  };

  const handleOnCloseAddTag = () => {
    setFocusedAddTag(false);
    setShouldShowAddTagModal(false);
    fetchDataResponse();
  };

  const handleOnChangeCollapse = (items: string[] | string) => {
    if (Array.isArray(items)) {
      setResponseIdList(items);
    } else {
      setResponseIdList([items]);
    }
  };

  return (
    <div className="response-collapse">
      <Collapse
        bordered={false}
        onChange={handleOnChangeCollapse}
      >
        {responses
          && responses.map((response: Response, index: number) => (
            <Panel
              header={(
                <ResponseHeader
                  response={response}
                  onClickHistory={onClickHistory}
                  operators={operators}
                  assignOperator={assignOperator}
                  isDisableAssignOperator
                  responseIdList={responseIdList}
                  fetchToDoList={fetchDataResponse}
                />
              )}
              className={classNames({
                'response-collapse__panel--even': index % 2 === 0,
                'response-collapse__panel--odd': index % 2 !== 0,
              })}
              key={response.responseId}
            >
              <TagList
                feedbackId={response.responseId}
                onEditTag={onEditTag}
                onClickAddTag={onClickAddTag}
                tags={response.tags}
              />
            </Panel>
          ))}
        {
          shouldShowAddTagModal && selectedFeedbackId
          && (
            <TagModal
              title="Tags"
              onCloseModal={handleOnCloseAddTag}
              feedbackId={selectedFeedbackId}
              editTagId={editTagId}
              focusedAddTag={focusedAddTag}
              tags={responses.find((response) => response.responseId === selectedFeedbackId)?.tags
                ?? []}
            />
          )
        }
      </Collapse>
    </div>
  );
};

export default ResponseList;
