import { useTranslation } from 'react-i18next';
import Loading from 'components/Loading';

interface ITotalNps {
  total: number,
  loading: boolean
}

const TotalNps = (props: ITotalNps) => {
  const { t } = useTranslation();
  const { total, loading } = props;

  return (
    <div className="total-nps">
      <div className="total-nps__points">
        <span className="total-nps__title">
          {t('TS.DASHBOARD.TOTAL_NPS')}
        </span>
        { loading
          ? <Loading loadingMode="component" />
          : (
            <>
              <span className="total-nps__total">
                { total }
              </span>
              <span className="total-nps__label">
                { t('TS.POINTS') }
              </span>
            </>
          )}
      </div>
    </div>
  );
};

export default TotalNps;
