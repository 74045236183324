import { VerticalLeftOutlined, VerticalRightOutlined } from '@ant-design/icons';
import { Pagination, Tooltip } from 'antd';
import React, { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import CustomButton from 'components/CustomButton';
import CustomSelect from 'components/CustomSelect';
import { DownArrow } from 'components/Icons';
import { PER_PAGES_DROPDOWN } from 'constants/pagination';
import { IPagination } from 'models/utility/Pagination';

interface ICustomPagination {
  paging: IPagination,
  setPaging: (value: SetStateAction<any>) => void,
  hidePageRow?: boolean;
}

const CustomPagination:React.FC<ICustomPagination> = ({ paging, setPaging, hidePageRow }) => {
  const { t } = useTranslation();

  const handleChangeItemsPerPage = (page: number) => {
    setPaging({
      pageIndex: page,
      pageSize: paging.pageSize,
    });
  };

  const handleChangePageSize = (pageSize: number | string) => {
    setPaging({
      pageIndex: 1,
      pageSize,
    });
  };

  const pageSize = () => {
    if (paging.totalRecords === 0) {
      return 0;
    }
    return paging.pageIndex === 1 ? 1 : (paging.pageIndex - 1) * paging.pageSize + 1;
  };

  return (
    <div className="custom-pagination">
      <div className="custom-pagination__show-total">
        {t('PAGINATION_FROM_TO_TOTAL', {
          from: pageSize(),
          to: paging.pageIndex * paging.pageSize > paging.totalRecords
            ? paging.totalRecords : paging.pageIndex * paging.pageSize,
          total: paging.totalRecords,
        })}
      </div>
      <div className="custom-pagination__paging">
        <CustomButton
          className="custom-pagination__paging-first"
          disabled={paging.pageIndex === 1}
          icon={(
            <Tooltip title={t('TS.FIRST.PAGE')} placement="top">
              <div className="custom-pagination__paging-button">
                <VerticalRightOutlined />
              </div>
            </Tooltip>
            )}
          onClick={() => handleChangeItemsPerPage(1)}
        />
        <Pagination
          total={paging.totalRecords}
          defaultPageSize={paging.pageSize}
          pageSize={paging.pageSize}
          defaultCurrent={paging.pageIndex}
          current={paging.pageIndex}
          onChange={handleChangeItemsPerPage}
        />
        <CustomButton
          className="custom-pagination__paging-last"
          icon={(
            <Tooltip title={t('TS.LAST.PAGE')} placement="top">
              <div className="custom-pagination__paging-button">
                <VerticalLeftOutlined />
              </div>
            </Tooltip>
          )}
          disabled={Math.ceil(paging.totalRecords / paging.pageSize) === paging.pageIndex}
          onClick={() => handleChangeItemsPerPage(paging.totalPages)}
        />
      </div>

      <div className="custom-pagination__items-per-page">
        { !hidePageRow && (
        <>
          <span>{t('PAGINATION_ROW_PER_PAGE')}</span>
          <CustomSelect
            options={PER_PAGES_DROPDOWN}
            value={paging.pageSize.toString()}
            suffixIcon={<DownArrow />}
            showSearch={false}
            onSelect={handleChangePageSize}
          />
        </>
        )}
      </div>
    </div>
  );
};
export default CustomPagination;
