import axios from 'axios';

import { ACCESS_TOKEN, SUBTENANT_ID, TENANT_ID } from 'utils/constants';

const { _env } = window;
export const http = axios.create({
  baseURL: _env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

http.interceptors.request.use((config) => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
    'x-tenant-id': localStorage.getItem(TENANT_ID),
    'x-subtenant-id': localStorage.getItem(SUBTENANT_ID),
    'cache-control': 'no-cache',
  },
}));

http.interceptors.response.use(undefined, (error) => Promise.reject(error));

export const httpConman = axios.create({
  baseURL: _env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

httpConman.interceptors.request.use((config) => ({
  ...config,
  headers: {
    ...config.headers,
  },
}));

httpConman.interceptors.response.use(undefined, (error) => Promise.reject(error));
