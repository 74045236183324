import _ from 'lodash';

import Club from 'models/Club';

export const sortClubByPoint = (clubs: Club[]) => _.orderBy(
  clubs,
  ['point'],
  ['desc'],
);

export const sortClubByAlpha = (clubs: Club[]) => _.orderBy(
  clubs,
  [(club) => club.name.toLowerCase()],
  ['asc'],
);

export const findClubNameByClubId = (clubs: Club[], clubId: number) => {
  const clubFound = clubs.find((club: Club) => club.id === clubId);
  if (clubFound) {
    return clubFound.name;
  }
  return '';
};

export default {
  sortClubByPoint,
};
