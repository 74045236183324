

import ClubItem from '../TableClub/ClubItem';

import CustomModal from 'components/CustomModal';
import Loading from 'components/Loading';
import Club from 'models/Club';
import { useTranslation } from 'react-i18next';
import { sortClubByPoint } from 'selectors/club';

interface IClubsModal {
  onCloseModal: () => void,
  loadingClubs: boolean;
  clubPoints: Club[];
}

const ClubsModal = (props: IClubsModal) => {
  const { onCloseModal, loadingClubs, clubPoints } = props;
  const { t } = useTranslation();
  return (
    <CustomModal
      onCancel={onCloseModal}
      visible
      title={t('ALL_RESPONSES_FILTER_CLUB_ALL')}
      width={500}
      className="clubs-modal"
    >
      { loadingClubs
        ? <Loading loadingMode="component" />
        : sortClubByPoint(clubPoints).map((club, index) => (
          <ClubItem
            club={club}
            key={club.id}
            order={index + 1}
          />
        ))}
    </CustomModal>
  );
};

export default ClubsModal;
