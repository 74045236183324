import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import getAllRole from 'services/role';

interface RolesState {
  loading: boolean;
  permissions: string[];
}

const initialState: RolesState = {
  loading: false,
  permissions: [],
};

export const getRolesAsync = createAsyncThunk(
  'rolesState',
  async (operatorId: any) => {
    const roles = await getAllRole(operatorId);
    return roles;
  },
);

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRolesAsync.pending, ($state, action) => {
      $state.loading = true;
    });
    builder.addCase(getRolesAsync.fulfilled, ($state, action) => {
      $state.loading = false;
      $state.permissions = action.payload.reduce(
        (permissions: string[], roleRight) => [...permissions, ...roleRight.rights],
        [],
      );
    });
  },
});

export default rolesSlice.reducer;
