import Tag from './Tag';

interface ITagGroup {
  tagGroupName: string,
  id?: number | null;
  tags?: Tag[];
  createdDate?: string,
}
class TagGroup implements ITagGroup {
  tagGroupName: string;
  id?: number | null;
  createdDate?: string;
  tags?: Tag[];
  constructor(
    tagGroup: ITagGroup,
  ) {
    this.id = tagGroup.id;
    this.tagGroupName = tagGroup.tagGroupName;
    this.tags = tagGroup.tags;
    this.createdDate = tagGroup.createdDate;
  }
}

export default TagGroup;
