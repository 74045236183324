import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import Operator from '../models/Operator';

import { getOperators } from 'services/response';

interface OperatorState {
  loading: boolean;
  operators: Operator[];
}

const initialState: OperatorState = {
  loading: false,
  operators: [],
};

export const getOperatorsAsync = createAsyncThunk(
  'operatorState',
  async () => {
    const operators = await getOperators();
    return operators;
  },
);

export const operatorSlice = createSlice({
  name: 'operator',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOperatorsAsync.pending, ($state, action) => {
      $state.loading = true;
    });
    builder.addCase(getOperatorsAsync.fulfilled, ($state, action) => {
      $state.loading = false;
      $state.operators = action.payload.operators.map(
        (operator) => ({ ...operator }),
      );
    });
  },
});

export default operatorSlice.reducer;
