import moment from 'moment';

import RequestSurveyFilter, { IRequestSurveyFilter } from './RequestSurveyFilter';
import { IRequestSurveySort } from './RequestSurveySort';

import { DATE_DEFAULT } from 'constants/date';
import {
  FILTER_DATE_FROM, FILTER_DATE_TO, FILTER_STATUS, FILTER_TYPE_ALL,
} from 'constants/filter';

export interface IRequestSurvey {
  pageIndex: number,
  pageSize: number,
  filter: IRequestSurveyFilter[],
  sorts: IRequestSurveySort | string,
  totalPages?: number,
  totalRecords?: number
}

class RequestSurvey implements IRequestSurvey {
  pageIndex: number;
  pageSize: number;
  filter: IRequestSurveyFilter[];
  sorts: IRequestSurveySort;
  totalPages?: number;
  totalRecords?: number;

  constructor(
    pageIndex: number,
    pageSize:number,
    filter:IRequestSurveyFilter[],
    sorts: IRequestSurveySort,
    totalPages?: number,
    totalRecords?: number,
  ) {
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.filter = filter;
    this.sorts = sorts;
    this.totalPages = totalPages;
    this.totalRecords = totalRecords;
  }

  findFilterByKey(queryKey: string): IRequestSurveyFilter | undefined {
    return this.filter.find((filter) => filter.queryKey === queryKey) || undefined;
  }

  addFilterWithKey(queryKey: string, queryValue: string) {
    if (Array.isArray(this.filter)) {
      this.filter.push(
        new RequestSurveyFilter(
          '=',
          'string',
          queryKey,
          queryValue,
        ),
      );
    }
  }

  formatForRequestApi() {
    const filterStatus = this.findFilterByKey(FILTER_STATUS);

    let filters: IRequestSurveyFilter[] = [...this.filter] as IRequestSurveyFilter[];

    if (filterStatus && filterStatus.queryValue === FILTER_TYPE_ALL) {
      filters = filters.filter((filter) => filter.queryKey !== FILTER_STATUS);
    }
    const filterFromDate = this.findFilterByKey(FILTER_DATE_FROM);
    const filterToDate = this.findFilterByKey(FILTER_DATE_TO);

    if (filterFromDate) {
      filterFromDate.queryValue = moment(filterFromDate.queryValue).format(DATE_DEFAULT);
    }
    if (filterToDate) {
      filterToDate.queryValue = moment(filterToDate.queryValue).format(DATE_DEFAULT);
    }

    delete this.totalPages;
    delete this.totalRecords;

    return {
      ...this,
      pageIndex: this.pageIndex - 1,
      filter: JSON.stringify(filters),
      sorts: JSON.stringify(this.sorts),
    };
  }
}

export default RequestSurvey;
