import { Col, Row, Tabs } from 'antd';
import classNames from 'classnames';
import moment from 'moment';

import CustomButton from 'components/CustomButton';
import { WHITE } from 'constants/button';
import { DATE_FORMAT_MONTH_DAY } from 'constants/date';
import { CONTACT_TYPE_EMAIL } from 'constants/response';
import History from 'models/History';
import Todo from 'models/Todo';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;

interface IHistoryItem {
  history: History;
  onViewEmail: (todo: Todo) => void;
}

const HistoryItem = (props: IHistoryItem) => {
  const { history, onViewEmail } = props;
  const { t } = useTranslation();
  return (
    <div className="history">
      <Row
        className={`history-item history-item--${history
          .getStatusByPoint()
          .toLocaleLowerCase()}`}
      >
        <Col span={3}>
          {!history.isSent() ? (
            <div className="history-item__point">
              <span className="history-item__point-text">{t('TS.POINTS')}</span>
              <span className="history-item__point-number">
                {history.getPoint()}
              </span>
            </div>
          ) : (
            <div className="history-item__send">
              <span className="history-item__send-text">
                {t('TS.PAGE.SURVEY')}
              </span>
              <span className="history-item__send-label">{t('TS.SENT')}</span>
            </div>
          )}
        </Col>
        <Col span={8}>
          <div className="history-item__survey">
            <div className="history-item__survey-header">
              <span className="history-item__survey-name">
                {history.clubName}
              </span>
              <span className="history-item__survey-label">
                {history.surveyName}
              </span>
            </div>
            {!history.isSent() && (
              <div className="history-item__survey-description">
                <span>{history.feedbackContent}</span>
              </div>
            )}
          </div>
        </Col>
        <Col span={13}>
          <div className="history-item__info">
            <span className="history-item__date">
              {moment
                .utc(history.getFeedbackDate())
                .format(DATE_FORMAT_MONTH_DAY)}
            </span>
            {history.hasTodo() && (
              <strong className="history-item__title">
                {t('TS.INTERACTION')}
              </strong>
            )}
            {!history.isSent() && history.hasTodo() && (
              <Tabs
                className="history-item__tab"
                defaultActiveKey="0"
                type="card"
                size="large"
              >
                {history.todo.map((surveyToDo, index) => (
                  <TabPane
                    tab={(
                      <div className="history-item__tab-item">
                        <div className={classNames('history-item__tab-name', {
                          'history-item__tab-name--fail': history.hasStatusFail() || (history.hasStatusSuccess() && index < history.todo.length - 1),
                          'history-item__tab-name--success': history.hasStatusSuccess() && index === history.todo.length - 1,
                        })}
                        >
                          {surveyToDo.contactType}
                        </div>
                        {history.todo.length > index + 1 && (
                          <span className="history-item__tab-bar" />
                        )}
                      </div>
                    )}
                    key={surveyToDo.latestActionDate + index.toString()}
                  >
                    {surveyToDo.contactType === CONTACT_TYPE_EMAIL ? (
                      <CustomButton
                        backgroundColor={WHITE}
                        className="history-item__view-email"
                        label={`${t(
                          'TS.VIEW.EMAIL',
                        )} (${surveyToDo.getTotalEmail()})`}
                        onClick={() => onViewEmail(surveyToDo)}
                      />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: surveyToDo.getComment(),
                        }}
                      />
                    )}
                  </TabPane>
                ))}
              </Tabs>
            )}
          </div>
        </Col>
      </Row>
      <div className="history__tree" />
    </div>
  );
};

export default HistoryItem;
