import { useTranslation } from 'react-i18next';
import CustomInputGroup from 'components/CustomInputGroup';
import CustomModal from 'components/CustomModal';
import { TagGroupIcon } from 'components/Icons';

interface ICreateEditTagGroupModal {
  title: string,
  onSubmitModal: () => void,
  onCloseModal: () => void,
  onChangeTagGroup: (value: string) => void,
  value: string
  placeholder?: string,
  buttonText?: string,
}

const CreateEditTagGroupModal = (props: ICreateEditTagGroupModal) => {
  const { t } = useTranslation();
  const {
    title,
    onSubmitModal,
    onCloseModal,
    onChangeTagGroup,
    value,
    placeholder = '',
    buttonText = t('TS.ADD'),
  } = props;
  return (
    <CustomModal
      className="tag-group-modal"
      title={title}
      onCancel={onCloseModal}
      visible
      width={400}
    >
      <CustomInputGroup
        onChangeInput={onChangeTagGroup}
        onSubmitButton={onSubmitModal}
        value={value}
        placeholder={placeholder}
        buttonText={buttonText}
        iconBefore={<TagGroupIcon />}
        autoFocus
      />
    </CustomModal>
  );
};

export default CreateEditTagGroupModal;
