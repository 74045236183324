import { ICreateEvent } from 'pages/CreateSurvey';

export const INIT_CREATE_EVENT: ICreateEvent = {
  surveyName: '',
  recurringCheckbox: false,
  sendVia: ['isSendViaEmail'],
  recurringInput: null,
  surveyDesign: '',
  surveyComment: '',
  surveyMember: [
    {
      criteria: null,
      operator: null,
      dataType: '',
      values: [],
    },
  ],
  surveySendOutConfigs: [],
  surveyType: 0,
};

export default {
  INIT_CREATE_EVENT,
};
