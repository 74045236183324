import {
  Button,
} from 'antd';
import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';

import { DownArrow, FilterIcon } from 'components/Icons';

interface IDropDownButton {
  onClick: MouseEventHandler<HTMLElement>;
  prefixIcon?: React.ReactNode;
  label: string;
  currentState?: boolean;
}
const DropDownButton: React.FC<IDropDownButton> = ({
  onClick,
  prefixIcon = <FilterIcon />,
  label,
  currentState,
}) => (
  <Button
    className={classNames({
      'dropdown-btn--rotate': currentState,
      'dropdown-btn': true,
    })}
    type="primary"
    onClick={onClick}
  >
    <div className="btn-icon">
      {prefixIcon}
      <span>{label}</span>
    </div>
    <DownArrow />
  </Button>
);

export default DropDownButton;
