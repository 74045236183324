import moment from 'moment';
import React, {
  SetStateAction, useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import LinkNavigating from '../../components/LinkNavigating';
import ResponseFilter from '../../components/ResponseFilter';

import NoResponse from './components/NoResponse';
import ResponseList from './components/ResponseList';
import { defaultFilter, defaultPaging } from './defaultValue';

import CustomPagination from 'components/CustomPagination';
import HistoryModal from 'components/HistoryModal';
import { AllResponsesIcon, ToDoIcon } from 'components/Icons';
import { DATE_FORMAT } from 'constants/date';
import { FILER_TYPE_SE, FILTER_TYPE_ALL } from 'constants/filter';
import { ALL_RESPONSE_ROUTE } from 'constants/routes';
import withLoading from 'hoc/withLoading';
import { IResponseResponse } from 'models/IResponseResponse';
import Response, { IResponse } from 'models/Response';
import { getResponses } from 'services/response';
import { useAppSelector } from 'store/hooks';
import { getOperatorsAsync } from 'store/operator';
import { NOTIFICATION_TYPE } from 'utils/constants';
import { replaceToHTMLElement, showNotification } from 'utils/helpers';
import { AppDispatch } from 'store';

interface IAllResponseContent {
  responses: any;
  paging: {
    pageIndex: number;
    pageSize: number;
    totalPages: number;
    totalRecords: number;
  };
  setPaging: (
    value: SetStateAction<{
      pageIndex: number;
      pageSize: number;
    }>
  ) => void;
  fetchDataResponse: () => void;
}

const AllResponseContent: React.FC<IAllResponseContent> = ({
  responses,
  paging,
  setPaging,
  fetchDataResponse,
}) => {
  const { t } = useTranslation();
  const responseCount = (responses?.paging && Number(responses?.paging.totalRecords)) ?? 0;
  const [shouldShowHistoryModal, setShouldShowHistoryModal] = useState<boolean>(false);
  const [response, setResponse] = useState<IResponse | null>();
  const { operators } = useAppSelector((state) => state.operator);

  const handleClickHistory = useCallback((responseSelected: IResponse, event?: Event) => {
    if (event) {
      event.stopPropagation();
    }
    if (responseSelected) {
      setResponse(responseSelected);
      setShouldShowHistoryModal(true);
    }
  }, []);

  const handleCloseHistoryModal = useCallback(() => {
    setResponse(null);
    setShouldShowHistoryModal(false);
  }, []);

  return (
    <>
      <div
        className="all-responses__count"
        dangerouslySetInnerHTML={{
          __html: replaceToHTMLElement(
            t(
              `ALL_RESPONSES_ALL_RESPONSES_COUNT${responseCount > 1 ? '' : '_1'
              }`,
              {
                number: responseCount,
              },
            ),
          ),
        }}
      />
      {responses?.responses?.length > 0 ? (
        <div className="all-responses__all">
          <ResponseList
            responses={responses?.responses}
            operators={operators}
            onClickHistory={handleClickHistory}
            fetchDataResponse={fetchDataResponse}
          />
          <CustomPagination paging={paging} setPaging={setPaging} />
        </div>
      ) : (
        <NoResponse />
      )}
      {shouldShowHistoryModal && (
        <HistoryModal
          onCloseModal={handleCloseHistoryModal}
          response={response}
        />
      )}
    </>
  );
};

interface IFilterParams {
  [key: string]: string | string[] | number[] | [];
}

interface IFilterPayload {
  name: string;
  type: string;
  value1: string | number[] | string[];
  value2?: string | number[] | string[];
}

const AllResponses: React.FC = () => {
  const { t } = useTranslation();
  const [loadingResponse, setLoadingResponse] = useState<boolean>(false);
  const [responses, setResponses] = useState<IResponseResponse>();
  const [saveFilter, setSaveFilter] = useState<IFilterParams>({
    ...defaultFilter,
    datePicker: [
      moment().subtract(1, 'months').format(DATE_FORMAT),
      moment().format(DATE_FORMAT),
    ],
  });
  const [paging, setPaging] = useState(defaultPaging);
  const location = useLocation();

  const handleSaveFilter = useCallback((values: any) => {
    setSaveFilter(values);
    setPaging(defaultPaging);
  }, []);

  const switchType = (text: string | number) => (text === 'all' ? FILTER_TYPE_ALL : FILER_TYPE_SE);

  const fetchDataResponse = useCallback(async () => {
    setLoadingResponse(true);
    try {
      const filterParams: IFilterPayload[] = [
        {
          name: 'responses',
          type: switchType(saveFilter.responses[0]),
          value1: saveFilter.responses,
        },
        {
          name: 'club',
          type: switchType(saveFilter.club[0]),
          value1: saveFilter.club,
        },
        {
          name: 'membershipMonth',
          type: switchType(saveFilter.membershipMonth[0]),
          value1: saveFilter.membershipMonth,
        },
        {
          name: 'assignedOperators',
          type: switchType(saveFilter.assignedOperators[0]),
          value1: saveFilter.assignedOperators,
        },
        {
          name: 'surveyName',
          type: switchType(saveFilter.surveyName[0]),
          value1: saveFilter.surveyName,
        },
        {
          name: 'datePicker',
          type: 'RA',
          value1: `${saveFilter.datePicker[0]}`,
          value2: `${saveFilter.datePicker[1]}`,
        },
      ];
      const data = await getResponses({
        filters: filterParams.filter(
          (item) => saveFilter[item.name].length > 0,
        ),
        paging: {
          pageIndex: paging.pageIndex,
          pageSize: paging.pageSize,
        },
      });
      if (data.responses) {
        const responses = data.responses.map((res: IResponse) => Response.formatFromAPI(res));
        setResponses({ ...data, responses });
      }
    } catch (err) {
      showNotification(
        t('TS.ERROR'),
        t('TS_NOTIFICATION_SERVICES_NOTIFICATION'),
        NOTIFICATION_TYPE.ERROR,
      );
    } finally {
      setLoadingResponse(false);
    }
  }, [saveFilter, paging, t]);

  useEffect(() => {
    fetchDataResponse();
  }, [fetchDataResponse]);

  const dispatch = useDispatch<AppDispatch>();
  const { operators, loading } = useAppSelector((state) => state.operator);

  useEffect(() => {
    if (operators.length === 0) {
      dispatch(getOperatorsAsync());
    }
  }, [dispatch, operators]);

  const AllResponseWithLoading = withLoading(() => (
    <AllResponseContent
      responses={responses}
      paging={{
        ...paging,
        totalPages:
      (responses?.paging && Number(responses?.paging.totalPages)) ?? 0,
        totalRecords:
      (responses?.paging && Number(responses?.paging.totalRecords))
      ?? 0,
      }}
      setPaging={setPaging}
      fetchDataResponse={fetchDataResponse}
    />
  ));

  return (
    <div className="all-responses">
      <div className="all-responses__wrapper">
        <ResponseFilter
          saveFilter={saveFilter}
          setSaveFilter={handleSaveFilter}
          isAllResponseFilter
        />
        <LinkNavigating links={[
          {
            key: '1',
            label: t('ALL_RESPONSES_ALL_RESPONSES'),
            icon: <AllResponsesIcon />,
            isActive: location.pathname === ALL_RESPONSE_ROUTE,
          },
          {
            key: '2',
            label: t('ALL_RESPONSES_TO_DO_LIST'),
            icon: <ToDoIcon />,
            isActive: false,
          },
        ]}
        />
        <AllResponseWithLoading isLoading={loading || loadingResponse} />
      </div>
    </div>
  );
};

export default AllResponses;
