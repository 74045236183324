import moment from 'moment';

import { IClub } from 'models/Club';

interface IClubItem {
  club: IClub,
  order: number
}

const ClubItem = (props: IClubItem) => {
  const { club, order } = props;
  return (
    <div className="report-item">
      <span className="report-item__number">
        <span className="report-item__number-text">
          { moment.localeData().ordinal(order) }
        </span>
      </span>
      <span className="report-item__text">
        {club.name}
      </span>
      <span className="report-item__total">
        { club.point }
      </span>
    </div>
  );
};

export default ClubItem;
