import {
  Checkbox, Col, Form, Row,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { SURVEY_ROUTE } from '../../constants/routes';

import MemberContent from './MemberContent';

import CustomButton from 'components/CustomButton';
import CustomRadio from 'components/CustomRadio';
import CustomSelect from 'components/CustomSelect';
import CustomSwitch from 'components/CustomSwitch';
import InputCustom from 'components/InputCustom';
import Loading from 'components/Loading';
import SendOutPercentage from 'components/SendOutPercentage';
import { BLACK, ORANGE } from 'constants/button';
import { REGEX_POSITIVE_NUMBER_FROM_1_TO_999 } from 'constants/regex';
import { CREATE_EDIT_SURVEY } from 'constants/roles';
import Club from 'models/Club';
import { IClubGroupMasterData } from 'models/IClubMasterData';
import { IOption } from 'models/IOption';
import { ISurveyForm, MemberStatus, SurveyType } from 'models/ISurveyMasterData';
import { IValue } from 'models/IValue';
import { SurveyDetail } from 'models/SurveyDetail';
import { IMember } from 'pages/CreateSurvey';
import SurveyTitle from 'pages/CreateSurvey/SurveyTitle';
import { getOperatorId } from 'services/auth';
import { editSurvey, getSurveyDetail } from 'services/survey';
import { useAppSelector } from 'store/hooks';
import { getMaster } from 'store/survey';
import {
  ALL_STATUS_CODE,
  API_STATUS,
  CRITERIA_GROUP,
  DATE_FORMAT_FOR_SUBMIT,
  NOTIFICATION_TYPE,
  OPERATOR_MORE_THAN_CODE,
  SEND_VIA,
  SEND_VIA_LIST,
  TYPE,
} from 'utils/constants';
import { showNotification } from 'utils/helpers';
import { formatSurveySendOutConfigsForApi, formatSurveySendOutConfigsFromApi } from 'utils/survey';
import { AppDispatch } from 'store';

const ViewEditSurvey: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [detail, setDetail] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const { status, data } = useAppSelector((state) => state.survey);
  const { clubPercentages } = data;
  const [surveys, setSurveys] = useState<IOption[]>([]);
   const dispatch = useDispatch<AppDispatch>();
  const [checked, setChecked] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [typeFormLink, setTypeFormLink] = useState('');
  const history = useNavigate();
  const operatorId = getOperatorId(useAuth().user);
  const { permissions } = useAppSelector((state) => state.role);
  const [isSaving, setSaving] = useState<boolean>(false);
  const [surveyTypes, setSurveyTypes] = useState<IOption[]>([]);

  const formSurveyList = useCallback(() => {
    const surveyList = data.surveyForms.map((item) => ({
      label: item.title,
      value: item.id,
    }));
    const surveyTypes = data.surveyTypes.map((type: SurveyType) => ({
      label: type.name,
      value: type.id,
    }));
    setSurveys(surveyList);
    setSurveyTypes(surveyTypes);
  }, [data.surveyForms.length > 0, surveys.length === 0]);

  const formInnitData = (
    res: SurveyDetail,
    clubPercentagesFromMasterData: Club[],
  ) => new Promise(() => {
    const sendVia: string[] = [];
    if (res.isSendViaEmail) {
      sendVia.push(SEND_VIA.EMAIL);
    }
    if (res.isSendViaSMS) {
      sendVia.push(SEND_VIA.SMS);
    }
    if (res.isSendViaFisikal) {
      sendVia.push(SEND_VIA.FISIKAL);
    }
    const surveyMember: IMember[] = res.rules.map((item) => {
      let value: any;
      if (
        item.criteria === CRITERIA_GROUP.MEMBER_STATUS
        && item.values.length === data.memberStatusDtos.length && data.memberStatusDtos.length > 0
      ) {
        value = [ALL_STATUS_CODE];
      } else if (
        item.dataType === TYPE.DATE
        && item.operator !== OPERATOR_MORE_THAN_CODE
        && item.values.length > 0
      ) {
        value = moment(item.values[0].value);
      } else {
        value = item.values.map((v: IValue) => v.value);
      }
      return {
        criteria: item.criteria,
        operator: item.operator,
        dataType: item.dataType,
        values: value,
      };
    });
    const innitData = {
      surveyName: res.surveyName,
      recurringCheckbox: res.isRecurrence,
      sendVia,
      surveyDesign: res.formCode,
      surveyComment: res.comment,
      surveyMember,
      recurringInput: res.recurrenceValue,
      isActive: res.isActive,
      totalMember: res.totalMemberSentTo.toString(),
      surveySendOutConfigs: formatSurveySendOutConfigsFromApi(
        res.surveySendOutConfigs,
        clubPercentagesFromMasterData,
      ),
      surveyType: res.surveyType,
      isSendOutPercentage: formatSurveySendOutConfigsFromApi(
        res.surveySendOutConfigs,
        clubPercentagesFromMasterData,
      ).length > 0,
    };
    setDetail(innitData);
    form.setFieldsValue(innitData);
    setChecked(res.isRecurrence);
    setActive(res.isActive);
  });

  useEffect(() => {
    const currentTypeForm = data.surveyForms.find(
      (typeForm: ISurveyForm) => typeForm.id === detail.surveyDesign,
    );
    setTypeFormLink(currentTypeForm?.formUrl ?? '');
  }, [data, detail]);

  const getDetails = useCallback(async (clubPercentagesFromMasterData: Club[]) => {
    setLoading(true);
    getSurveyDetail(id!).then(async (response) => {
      formInnitData(response, clubPercentagesFromMasterData);
    }).finally(() => {
      setLoading(false);
    });
  }, [id]);

  useEffect(() => {
    dispatch(getMaster());
  }, [dispatch]);

  useEffect(() => {
    if (clubPercentages.length > 0) {
      getDetails(clubPercentages);
    }
  }, [getDetails, clubPercentages]);

  useEffect(() => {
    formSurveyList();
  }, [formSurveyList, data]);

  const onSubmit = async (): Promise<void> => {
    const values = await form.validateFields();
    setSaving(true);
    const {
      surveyName,
      isActive,
      recurringCheckbox,
      recurringInput,
      sendVia,
      surveyComment,
      surveyDesign,
      surveyMember,
      surveySendOutConfigs,
      isSendOutPercentage,
      surveyType,
    } = values;
    const isSendViaEmail = sendVia.includes(SEND_VIA.EMAIL);
    const isSendViaSMS = sendVia.includes(SEND_VIA.SMS);
    const isSendViaFisikal = sendVia.includes(SEND_VIA.FISIKAL);
    const chosenForm = data.surveyForms.find(
      (item: ISurveyForm) => item.id === surveyDesign,
    );
    const formDescription = chosenForm ? chosenForm.title : '';
    const members = surveyMember.map((item: any) => {
      if (item.dataType === TYPE.INT && item.criteria === CRITERIA_GROUP.CLUB) {
        const convertValues = item.values.map((value: string) => {
          const chosenClub = data.clubGroups.find(
            (clubGroup: IClubGroupMasterData) => clubGroup.clubGroupId.toString() === value,
          );
          return { label: chosenClub?.clubGroupName ?? '', value };
        });

        return { ...item, values: convertValues };
      }
      if (
        item.criteria === CRITERIA_GROUP.JOIN_DATE
        && item.operator !== OPERATOR_MORE_THAN_CODE
      ) {
        const convertValues = item.values.format(DATE_FORMAT_FOR_SUBMIT);
        return { ...item, values: [{ value: convertValues }], dataType: TYPE.DATE };
      }
      if (item.dataType === TYPE.STRING) {
        // add all member status to payload when status = all statuses
        const allStatusValues = data.memberStatusDtos.filter(
          (memberStatus: MemberStatus) => memberStatus.statusCode !== ALL_STATUS_CODE,
        ).map((itemStatus: MemberStatus) => ({ value: itemStatus.statusCode }));
        // mapping value when choose over 1 status for member status
        const convertStatuses = item.values.map((statusValue: string) => ({ value: statusValue }));
        return {
          ...item,
          values: item.values === ALL_STATUS_CODE ? allStatusValues : convertStatuses,
        };
      }
      if (typeof item.values !== 'string') {
        return {
          ...item,
          values: [{ value: item.values[0] }],
        };
      }
      return {
        ...item,
        values: [{ value: item.values }],
        dataType: 'int',
      };
    });
    const dataSubmit = {
      isActive,
      surveyName,
      isSendViaEmail,
      isSendViaSMS,
      isSendViaFisikal,
      isRecurrence: recurringCheckbox,
      recurrenceValue: recurringInput || null,
      operatorId,
      formCode: surveyDesign,
      formDescription,
      comment: surveyComment,
      rules: members,
      surveyType,
      surveySendOutConfigs: formatSurveySendOutConfigsForApi(
        isSendOutPercentage ? surveySendOutConfigs : [],
        clubPercentages,
      ),
    };
    try {
      const res = await editSurvey(id!, dataSubmit);
      if (res.isSuccess) {
        showNotification(
          t('TS.SUCCESS'),
          t('TS.EDIT_SURVEY_SUCCESSFULLY_MESSAGE'),
          NOTIFICATION_TYPE.SUCCESS,
        );
        history(SURVEY_ROUTE);
      } else {
        showNotification(
          t('TS.ERROR'),
          t('TS_NOTIFICATION_SERVICES_NOTIFICATION'),
          NOTIFICATION_TYPE.ERROR,
        );
      }
      setSaving(false);
    } catch (error) {
      showNotification(
        t('TS.ERROR'),
        t('TS_NOTIFICATION_SERVICES_NOTIFICATION'),
        NOTIFICATION_TYPE.ERROR,
      );
      setSaving(false);
    }
  };

  const onCancel = () => {
    history(SURVEY_ROUTE);
  };

  if (loading || status === API_STATUS.LOADING) {
    return <Loading loadingMode="component" />;
  }

  const handleStatus = (value: boolean) => {
    setActive(value);
  };

  const handleCheckBox = (event: any) => {
    const value = event.target.checked;
    if (value === false) {
      form.setFieldsValue({ recurringInput: '' });
    }
    setChecked(value);
  };

  return (
    <div className="create-survey">
      <h3 className="create-survey__header">{t('TS.SURVEY.SURVEY_DETAIL')}</h3>
      <div className="create-survey__container">
        <Form
          name="basic"
          autoComplete="off"
          form={form}
          initialValues={detail}
          hideRequiredMark
          layout="vertical"
        >
          <div className="create-survey__section">
            <Row gutter={24}>
              <Col className="d-flex" md={{ span: 15 }} xs={{ span: 15 }}>
                <Form.Item
                  name="surveyName"
                  rules={[
                    {
                      required: true,
                      message: t('TS.CREATE_SURVEY.REQUIRED_MESSAGE', {
                        key: t('ALL_RESPONSES_FILTER_SURVEY_NAME'),
                      }),
                    },
                    {
                      min: 2,
                      message: t('TS.CREATE_SURVEY.LENGTH_VALIDATION', {
                        key: t('TS.CREATE_SURVEY.SURVEY_NAME'),
                        num1: 2,
                        num2: 100,
                      }),
                    },
                    {
                      max: 100,
                      message: t('TS.CREATE_SURVEY.LENGTH_VALIDATION', {
                        key: t('TS.CREATE_SURVEY.SURVEY_NAME'),
                        num1: 2,
                        num2: 100,
                      }),
                    },
                  ]}
                  label={t('ALL_RESPONSES_FILTER_SURVEY_NAME')}
                >
                  <InputCustom
                    placeholder={t('TS.CREATE_SURVEY.PLACEHOLDER_SURVEY_NAME')}
                  />
                </Form.Item>
              </Col>
              <Col className="d-flex mt-30" md={{ span: 5 }} xs={{ span: 5 }}>
                <div className="label-props">
                  {t('TS.SURVEY.SEND_TO', {
                    key: detail ? detail.totalMember : 0,
                  })}
                </div>
              </Col>
              <Col className="d-flex mt-30" md={{ span: 4 }} xs={{ span: 4 }}>
                <Form.Item name="isActive">
                  <CustomSwitch checked={active} onChange={handleStatus} />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="create-survey__section">
            <SurveyTitle index={1} content={t('TS.CREATE_SURVEY.STRATEGY')} />
            <Row gutter={24}>
              <Col className="d-flex" md={{ span: 8 }} xs={{ span: 7 }}>
                <Form.Item
                  name="sendVia"
                  rules={[
                    {
                      required: true,
                      message: t('TS.CREATE_SURVEY.REQUIRED_MESSAGE', {
                        key: t('TS.CREATE_SURVEY.SEND_SURVEY_VIA'),
                      }),
                    },
                  ]}
                  label={t('TS.CREATE_SURVEY.SEND_VIA')}
                >
                  <CustomSelect
                    options={SEND_VIA_LIST.map((option) => ({
                      ...option,
                      label: t(option.label),
                    }))}
                    mode="multiple"
                  />
                </Form.Item>
              </Col>
              <Col className="d-flex" md={{ span: 8 }} xs={{ span: 8 }}>
                <Form.Item
                  name="recurringInput"
                  rules={[
                    {
                      pattern: REGEX_POSITIVE_NUMBER_FROM_1_TO_999,
                      message: t('TS.CREATE_SURVEY.VALIDATE_INTEGER_MESSAGE'),
                    },
                    {
                      required: checked,
                      message: t('TS.CREATE_SURVEY.VALIDATE_INTEGER_MESSAGE'),
                    },
                  ]}
                  label={(
                    <>
                      <Form.Item
                        name="recurringCheckbox"
                        valuePropName="checked"
                        className="create-survey__checkbox"
                      >
                        <Checkbox onChange={handleCheckBox} />
                      </Form.Item>
                      <span>{t('TS.CREATE_SURVEY.RECURRING_EVERY')}</span>
                    </>
                  )}
                >
                  <InputCustom
                    suffix={t('TS.CREATE_SURVEY.PLACEHOLDER_DAYS')}
                    type="number"
                    disabled={!checked}
                  />
                </Form.Item>
              </Col>
            </Row>
            <SendOutPercentage form={form} />
          </div>
          <div className="create-survey__section">
            <SurveyTitle index={2} content={t('TS.CREATE_SURVEY.DESIGN')} />
            <Row gutter={24}>
              <Col className="d-flex" span={8}>
                <Form.Item
                  name="surveyType"
                  rules={[
                    {
                      required: true,
                      message: t('TS.CREATE_SURVEY.REQUIRED_MESSAGE', {
                        key: t('TS.CREATE_SURVEY.SURVEY_DESIGN'),
                      }),
                    },
                  ]}
                  label={t('TS.CREATE_SURVEY.SURVEY_TYPE')}
                >
                  <CustomRadio options={surveyTypes} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col className="d-flex" md={{ span: 8 }} xs={{ span: 7 }}>
                <Form.Item
                  name="surveyDesign"
                  rules={[
                    {
                      required: true,
                      message: t('TS.CREATE_SURVEY.REQUIRED_MESSAGE', {
                        key: t('TS.CREATE_SURVEY.SURVEY_DESIGN'),
                      }),
                    },
                  ]}
                  label={t('TS.CREATE_SURVEY.CHOOSE_DESIGN')}
                >
                  <CustomSelect options={surveys} />
                </Form.Item>
              </Col>
            </Row>
            <Row className="create-survey__link">
              <Col className="d-flex" md={{ span: 24 }} xs={{ span: 24 }}>
                <a href={typeFormLink} target="_blank" rel="noreferrer">
                  {t('TS.CREATE_SURVEY.LINK')}
                </a>
              </Col>
            </Row>
          </div>
          <div className="create-survey__section">
            <SurveyTitle index={3} content={t('TS.CREATE_SURVEY.COMMENT')} />
            <Row>
              <Col className="d-flex" md={{ span: 24 }} xs={{ span: 24 }}>
                <p>{t('TS.CREATE_SURVEY.INPUT_COMMENT')}</p>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="d-flex" md={{ span: 24 }} xs={{ span: 24 }}>
              <Form.Item
                name="surveyComment"
                rules={[
                  {
                    max: 500,
                    message: t('TS.CREATE_SURVEY.COMMENT_VALIDATE'),
                  },
                ]}
              >
                <TextArea
                  placeholder={t('TS.CREATE_SURVEY.COMMENTS')}
                  rows={4}
                  bordered
                  className="content__area"
                />
              </Form.Item>
            </Col>
          </Row>

          <div className="create-survey__section">
            <SurveyTitle index={4} content={t('TS.CREATE_SURVEY.MEMBER')} />
            <MemberContent form={form} />
          </div>
          {permissions.includes(CREATE_EDIT_SURVEY) && (
            <Row className="create-survey__button">
              <CustomButton
                label={t('TS.CREATE_SURVEY.CANCEL_BUTTON')}
                backgroundColor={BLACK}
                onClick={onCancel}
              />
              <CustomButton
                label={t('TS.SURVEY.SAVE_BUTTON')}
                backgroundColor={ORANGE}
                onClick={onSubmit}
                loading={isSaving}
              />
            </Row>
          )}
        </Form>
      </div>
    </div>
  );
};

export default ViewEditSurvey;
