import {
  GoogleOutlined, TagsOutlined, UserAddOutlined,
} from '@ant-design/icons';
import React from 'react';

import CustomButton from 'components/CustomButton';
import { AddIcon } from 'components/Icons';
import TagItem from 'components/TagItem';
import { ADDED_OR_UPDATED_BY_USER, AI_ANALYZED, WORD_MATCHING } from 'constants/tag';
import { ITag } from 'models/Tag';
import { useTranslation } from 'react-i18next';

interface ITagList {
  onEditTag: (tagId: number, feedbackId: number) => void;
  onClickAddTag: (feedbackId: number) => void;
  feedbackId: number;
  tags: ITag[] | [];
}

const TagList: React.FC<ITagList> = ({
  tags,
  onEditTag,
  feedbackId,
  onClickAddTag,
}) => {
  const { t } = useTranslation();
  const renderPreDefinedIcon = (tag: ITag) => {
    if (typeof tag.sourceType === 'undefined') {
      return null;
    }

    if (tag.sourceType === AI_ANALYZED) {
      return <GoogleOutlined />;
    }

    if (tag.sourceType === WORD_MATCHING) {
      return <TagsOutlined />;
    }

    if (tag.sourceType === ADDED_OR_UPDATED_BY_USER) {
      return <UserAddOutlined />;
    }
  };

  return (
    <div className="tag-list__wrapper">
      <ul className="tag-list__list">
        {tags
        && tags.map((tag: ITag) => (
          <li key={tag.tagId}>
            <TagItem
              icon={renderPreDefinedIcon(tag)}
              onClick={() => onEditTag(tag.surveyAnswerTagId ?? 0, feedbackId)}
              tag={tag}
              showEdit
              showDelete={false}
            />
          </li>
        ))}
        <li>
          <CustomButton
            icon={<AddIcon />}
            label="Add tag"
            className="tag-list__item tag-list__item--add"
            onClick={() => onClickAddTag(feedbackId)}
          />
        </li>
      </ul>
      {
      tags.length > 0
      && (
      <div className="tag-list__note">
        <div className="tag-list__note-item">
          <GoogleOutlined />
          <span>{t('TS.ANALYZED.GOOGLE')}</span>
        </div>
        <div className="tag-list__note-item">
          <TagsOutlined />
          <span>{t('TS.ANALYZED.WORD')}</span>
        </div>
        <div className="tag-list__note-item">
          <UserAddOutlined />
          <span>{t('TS.ANALYZED.USER')}</span>
        </div>
      </div>
      )
    }
    </div>
  );
};

export default TagList;
