export default {
  negative: {
    value: 1,
    color: 'negative',
  },
  neutral: {
    value: 2,
    color: 'neutral',
  },
  positive: {
    value: 3,
    color: 'positive',
  },
};

export const AI_ANALYZED = 0;
export const WORD_MATCHING = 1;
export const ADDED_OR_UPDATED_BY_USER = 2;
