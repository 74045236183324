import moment, { Moment } from 'moment';
import { TFunction } from 'react-i18next';

import { DATE_FORMAT_DD_MM_YYYY } from 'constants/date';

export const updateLocaleMoment = (t: TFunction<'translation', undefined>) => {
  moment.updateLocale('en', {
    relativeTime: {
      past: t('SS_MOMENT_RELATIVE_TIME_PAST'),
      s: t('SS_MOMENT_RELATIVE_TIME_S'),
      ss: t('SS_MOMENT_RELATIVE_TIME_SS'),
      m: t('SS_MOMENT_RELATIVE_TIME_M'),
      mm: t('SS_MOMENT_RELATIVE_TIME_MM'),
      h: t('SS_MOMENT_RELATIVE_TIME_H'),
      hh: t('SS_MOMENT_RELATIVE_TIME_HH'),
      d: t('SS_MOMENT_RELATIVE_TIME_D'),
    },
  });
};
export const customMomentFrom = (
  currentDate: Moment | string,
  createdDate: Moment | string,
  t: TFunction<'translation', undefined>,
) => {
  const diffHours = moment(currentDate).diff(createdDate, 'hour');
  const diffMinutes = moment(currentDate).diff(createdDate, 'minute');
  const diffSeconds = moment(currentDate).diff(createdDate, 'second');
  const diffDays = moment(currentDate).diff(createdDate, 'day');

  switch (true) {
    case diffSeconds <= 1:
      return `${diffSeconds} second ago`;
    case diffSeconds < 60:
      return `${diffSeconds} seconds ago`;
    case diffMinutes <= 1 || diffSeconds === 60:
      return `${diffMinutes} minute ago`;
    case diffMinutes < 60:
      return `${diffMinutes} minutes ago`;
    case diffHours <= 1 || diffMinutes === 60:
      return `${diffHours} hour ago`;
    case diffHours < 24:
      return `${diffHours} hours ago`;
    case diffDays <= 1 || diffHours === 24:
      return `${diffDays} day ago`;
    case diffDays < 30:
      return `${diffDays} days ago`;
    default:
      return `${moment(createdDate).format(DATE_FORMAT_DD_MM_YYYY)}`;
  }
};
