
import moment from 'moment';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useAuth } from 'react-oidc-context';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { submitLogAttempt } from '../../services/todo';

import TodoList from './components/TodoList';

import CustomPagination from 'components/CustomPagination';
import HistoryModal from 'components/HistoryModal';
import { AllResponsesIcon, ToDoIcon } from 'components/Icons';
import LinkNavigating from 'components/LinkNavigating';
import Loading from 'components/Loading';
import ResponseFilter from 'components/ResponseFilter';
import { DATE_FORMAT } from 'constants/date';
import { FILER_TYPE_SE, FILTER_TYPE_ALL } from 'constants/filter';
import { DEFAULT_PAGING } from 'constants/pagination';
import { MAX_TIME_LOG } from 'constants/response';
import { TO_DO_ROUTE } from 'constants/routes';
import Response, { IResponse } from 'models/Response';
import { IPagination } from 'models/utility/Pagination';
import { defaultFilter } from 'pages/AllResponses/defaultValue';
import { getOperatorId } from 'services/auth';
import { assignOperatorToFeedback } from 'services/response';
import { getAllTodo } from 'services/todo';
import { useAppSelector } from 'store/hooks';
import { getOperatorsAsync } from 'store/operator';
import { NOTIFICATION_TYPE } from 'utils/constants';
import { showNotification } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { AppDispatch } from 'store';

interface IFilterParams {
  [key: string]: string[] | number[] | [];
}

const ToDoPage: React.FC = () => {
  const { t } = useTranslation()
  const [loadingTodo, setLoadingTodo] = useState<boolean>(false);
  const [todos, setTodos] = useState<Response[]>([]);
  const [saveFilter, setSaveFilter] = useState<IFilterParams>(defaultFilter);
  const [paging, setPaging] = useState<IPagination>(DEFAULT_PAGING);
  const [shouldShowHistoryModal, setShouldShowHistoryModal] = useState<boolean>(false);
  const [todoSelected, setTodoSelected] = useState<IResponse | null>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const location = useLocation();
  const operatorId = getOperatorId(useAuth().user);

  const links = [
    {
      key: '1',
      label: t('ALL_RESPONSES_ALL_RESPONSES'),
      icon: <AllResponsesIcon />,
      isActive: false,
    },
    {
      key: '2',
      label: t('ALL_RESPONSES_TO_DO_LIST'),
      icon: <ToDoIcon />,
      badge: paging.totalRecords ? paging.totalRecords : 0,
      isActive: location.pathname === TO_DO_ROUTE,
    },
  ];

  const switchType = (text: string | number) => (text === 'all' ? FILTER_TYPE_ALL : FILER_TYPE_SE);

  const formatFilterTodo = (filter: IFilterParams) => [
    {
      name: 'responses',
      type: switchType(filter.responses[0]),
      value1: filter.responses,
    },
    {
      name: 'club',
      type: switchType(filter.club[0]),
      value1: filter.club,
    },
    {
      name: 'membershipMonth',
      type: switchType(filter.membershipMonth[0]),
      value1: filter.membershipMonth,
    },
    {
      name: 'assignedOperators',
      type: switchType(filter.assignedOperators[0]),
      value1: filter.assignedOperators,
    },
    {
      name: 'surveyName',
      type: switchType(filter.surveyName[0]),
      value1: filter.surveyName,
    },
    {
      name: 'datePicker',
      type: 'RA',
      value1: moment(filter.datePicker[0]).format(DATE_FORMAT),
      value2: moment(filter.datePicker[1]).format(DATE_FORMAT),
    },
  ].filter((item) => filter[item.name].length > 0);

  const fetchTodoData = async (
    filters: IFilterParams,
    pagingFilter: {
      pageIndex: number;
      pageSize: number;
    },
  ) => {
    try {
      setLoadingTodo(true);
      const data = await getAllTodo({
        filters: formatFilterTodo(filters),
        paging: {
          pageIndex: pagingFilter.pageIndex,
          pageSize: pagingFilter.pageSize,
        },
        operatorId,
      });
      if (data.responses) {
        setTodos(data.responses.map((response) => Response.formatFromAPI(response)));
        setPaging({
          pageIndex: data.paging.pageIndex,
          pageSize: data.paging.pageSize,
          totalPages: data.paging.totalPages,
          totalRecords: (data.paging && Number(data.paging.totalRecords)) ?? 0,
        });
      } else {
        setTodos([]);
        setPaging(DEFAULT_PAGING);
      }
    } catch (err) {
      showNotification(
        t('TS.ERROR'),
        t('TS_NOTIFICATION_SERVICES_NOTIFICATION'),
        NOTIFICATION_TYPE.ERROR,
      );
    } finally {
      setLoadingTodo(false);
    }
  };

  useEffect(() => {
    fetchTodoData(saveFilter, paging);
  }, []);

 const dispatch = useDispatch<AppDispatch>();
  const { loading, operators } = useAppSelector((state) => state.operator);

  useEffect(() => {
    if (operators.length === 0) {
      dispatch(getOperatorsAsync());
    }
  }, [dispatch, operators]);

  const assignOperator = useCallback((assigneeId: string, feedbackId: string) => {
    (async () => {
      try {
        await assignOperatorToFeedback({
          assigneeId,
          assignerId: operatorId,
          feedbackId,
        });
      } catch (err) {
        showNotification(
          t('TS.ERROR'),
          t('TS_NOTIFICATION_SERVICES_NOTIFICATION'),
          NOTIFICATION_TYPE.ERROR,
        );
      }
    })();
  }, []);

  const updateTodoList = (todo: Response) => {
    setTodos([
      ...todos.map((todoItem) => (todoItem.responseId === todo.responseId ? todo : todoItem)),
    ]);
  };

  const handleLogAttempt = async (
    logType: boolean,
    todo: Response,
    contactType: string,
  ) => {
    try {
      setIsSubmitting(true);
      const dataAfterSubmit = await submitLogAttempt(
        todo.responseId.toString(),
        {
          ...todo.formatApiForLogAttempt(logType, contactType),
          operatorId: Number(operatorId),
        },
      );
      if (dataAfterSubmit.isSuccess) {
        const newTodo = new Response(todo);
        if (typeof newTodo.attemptedTime === 'undefined') {
          newTodo.attemptedTime = 1;
        }
        newTodo.attemptedTime += 1;
        newTodo.comment = '';
        if (!logType && newTodo.attemptedTime < MAX_TIME_LOG) {
          updateTodoList(new Response(newTodo));
        } else {
          fetchTodoData(saveFilter, { ...paging, pageIndex: 1 });
        }
        showNotification(
          t('TS.SUCCESS'),
          t('TS.TIME.UPDATE_SUCCESSFULLY'),
          NOTIFICATION_TYPE.SUCCESS,
        );
      }
    } catch (error) {
      showNotification(
        t('TS.ERROR'),
        t('TS_NOTIFICATION_SERVICES_NOTIFICATION'),
        NOTIFICATION_TYPE.ERROR,
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChangeComment = (todo: Response, value: string) => {
    const newTodo = new Response(todo);
    newTodo.comment = value;
    updateTodoList(newTodo);
  };

  const handleChangePaging = useCallback((page: {
    pageIndex: number;
    pageSize: number;
  }) => {
    const newPaging = {
      ...paging,
      pageIndex: page.pageIndex,
      pageSize: page.pageSize,
    };
    setPaging(newPaging);
    fetchTodoData(saveFilter, newPaging);
  }, [saveFilter]);

  const handleClickHistory = useCallback((todo: IResponse, event?: Event) => {
    if (event) {
      event.stopPropagation();
    }
    if (todo) {
      setTodoSelected(todo);
      setShouldShowHistoryModal(true);
    }
  }, []);

  const handleCloseHistoryModal = useCallback(() => {
    setTodoSelected(null);
    setShouldShowHistoryModal(false);
  }, []);

  const handleSaveFilter = useCallback((values: any) => {
    setSaveFilter(values);
    setPaging(DEFAULT_PAGING);
    fetchTodoData(values, { ...paging, pageIndex: 1 });
  }, []);

  const submitEmail = useCallback((todo: Response) => {
    window.open(
      `mailto:${todo.email}?bcc=${todo.surveySystemEmail}&body=%0D%0A%0D%0A%0D%0AReference ID:${todo.responseId}`,
    );
  }, []);

  const handleChangeTab = (todo: Response, tab: string) => {
    const newTodo = new Response(todo);
    newTodo.contactType = tab;
    updateTodoList(newTodo);
  };

  const fetchDataTodoList = useCallback(() => fetchTodoData(saveFilter, paging), []);

  return (
    <>
      <div className="todo-list">
        <div className="todo-list__wrapper">
          <ResponseFilter
            saveFilter={saveFilter}
            setSaveFilter={handleSaveFilter}
          />
          <LinkNavigating links={links} />
          {loading || loadingTodo ? (
            <Loading loadingMode="component" />
          ) : (
            <>
              <TodoList
                todos={todos}
                operators={operators}
                onChangeComment={handleChangeComment}
                onChangeTab={handleChangeTab}
                onLogAttempt={handleLogAttempt}
                assignOperator={assignOperator}
                onClickHistory={handleClickHistory}
                submitEmail={submitEmail}
                fetchDataTodoList={fetchDataTodoList}
                isSubmitting={isSubmitting}

              />
              <CustomPagination
                paging={{
                  ...paging,
                  totalPages: (paging && Number(paging.totalPages)) ?? 0,
                  totalRecords: (paging && Number(paging.totalRecords)) ?? 0,
                }}
                setPaging={handleChangePaging}
              />
            </>
          )}
        </div>
      </div>
      {shouldShowHistoryModal && (
        <HistoryModal
          onCloseModal={handleCloseHistoryModal}
          response={todoSelected}
        />
      )}
    </>
  );
};

export default ToDoPage;
