import {
  DashboardIcon,
  SurveyIcon,
  ToDoIcon,
  AllResponsesMenuIcon,
  TagGroupIcon,
} from '../components/Icons/index';
import Menu from '../models/utility/Menu';

import {
  VIEW_ALL_RESPONSE,
  VIEW_DASHBOARD,
  VIEW_MY_TODO,
  VIEW_SURVEY,
  VIEW_TAG_TAG_GROUP,
  VIEW_TO_DO_LIST,
} from './roles';
import { TAG_GROUP_ROUTE } from './routes';

import {
  DASHBOARD_ROUTE,
  SURVEY_ROUTE,
  TO_DO_ROUTE,
  ALL_RESPONSE_ROUTE,
} from 'constants/routes';

const MENU_SLIDE_BAR: Menu[] = [
  new Menu(
    DASHBOARD_ROUTE,
    'TS.PAGE.DASHBOARD',
    DashboardIcon,
    [VIEW_DASHBOARD],
  ),
  new Menu(
    SURVEY_ROUTE,
    'TS.PAGE.SURVEY',
    SurveyIcon,
    [VIEW_SURVEY],
  ),
  new Menu(
    TO_DO_ROUTE,
    'TS.PAGE.TODO',
    ToDoIcon,
    [VIEW_TO_DO_LIST, VIEW_MY_TODO],
  ),
  new Menu(
    ALL_RESPONSE_ROUTE,
    'TS.PAGE.ALL_RESPONSES',
    AllResponsesMenuIcon,
    [VIEW_ALL_RESPONSE],
  ),
  new Menu(
    TAG_GROUP_ROUTE,
    'TS.PAGE.TAG_GROUP',
    TagGroupIcon,
    [VIEW_TAG_TAG_GROUP],
  ),
];

export default MENU_SLIDE_BAR;
