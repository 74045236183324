import { Button } from 'antd';
import classNames from 'classnames';
import React, { ReactElement } from 'react';

import { BLACK } from 'constants/button';

interface ICustomButton {
  label?: string;
  backgroundColor?: 'black' | 'orange' | 'white' | 'neutral';
  loading?: boolean;
  disabled?: boolean;
  icon?: ReactElement;
  onClick?: () => void;
  className?: string;
  children?: React.ReactNode;
  htmlType?: 'button' | 'submit',
  tooltip?: string,
}

const CustomButton = (props: ICustomButton) => {
  const {
    label = '',
    loading = false,
    disabled = false,
    backgroundColor = BLACK,
    icon,
    onClick = () => null,
    className = '',
    children = null,
    htmlType = 'button',
    tooltip = '',
  } = props;

  return (
    <Button
      loading={loading}
      disabled={disabled}
      title={tooltip}
      className={classNames(`button-custom button-custom--${backgroundColor} ${className}`, {
        'button-custom--with-icon': icon,
      })}
      icon={icon && <i>{icon}</i>}
      onClick={onClick}
      htmlType={htmlType}
    >
      { label }
      {children}
    </Button>
  );
};

export default CustomButton;
