import ClubItem from './ClubItem';

import CustomButton from 'components/CustomButton';
import Loading from 'components/Loading';
import { WHITE } from 'constants/button';
import { IClub } from 'models/Club';
import { useTranslation } from 'react-i18next';

interface ITableClub {
  clubPoint: IClub[],
  loading: boolean,
  onShowAllClub: () => void
}

const TableClub = (props: ITableClub) => {
  
  const { t } = useTranslation();
  const {
    clubPoint,
    loading,
    onShowAllClub,
  } = props;

  return (
    <div className="table-report">
      <div className="table-report__header">
        <CustomButton
          label={t('TS.SHOW.FULL.TABLE')}
          backgroundColor={WHITE}
          onClick={onShowAllClub}
        />
      </div>
      <div className="table-report__body">
        {
          loading
            ? <Loading loadingMode="component" />
            : clubPoint.map(
              (club, index) => <ClubItem key={club.id} order={index + 1} club={club} />,
            )
        }
      </div>
    </div>
  );
};

export default TableClub;
