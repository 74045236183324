import { User } from 'oidc-client-ts';

import config from 'config';

const getOidcKey = (): string => (`oidc.user:${config.oidc.authority}:${config.oidc.client_id}`);

export function getUser() {
  const oidcStorage = localStorage.getItem(getOidcKey());
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

export const getOperatorId = (user: any) => user?.profile.operatorId ?? '0';

export async function signOutAsync() {
  localStorage.removeItem(getOidcKey());
}
