import { Button, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import CustomTextArea from 'components/CustomTextArea';
import { SendEmail } from 'components/Icons';
import {
  CONTACT_TYPE_CALL,
  CONTACT_TYPE_TALK,
  CONTACT_TYPE_EMAIL,
} from 'constants/response';
import Response from 'models/Response';

const { TabPane } = Tabs;

interface IResponseMethodTab {
  todo: Response,
  onChangeComment: (todo: Response, comment: string) => void,
  onChangeTab: (todo: Response, tab: string) => void,
  onSubmitEmail: (todo: Response) => void
}

const ResponseMethodTab = (props: IResponseMethodTab) => {
  const { t } = useTranslation();
  const {
    todo,
    onChangeComment,
    onChangeTab,
    onSubmitEmail,
  } = props;

  return (
    <>
      <div className="card-container">
        <Tabs type="card" onChange={(tab) => onChangeTab(todo, tab)}>
          <TabPane tab={t('TO_DO_LOG_A_CALL')} key={CONTACT_TYPE_CALL}>
            <p>
              {todo.phoneNumber}
            </p>
          </TabPane>
          <TabPane className="ant-tabs-tabpane--no-content" tab={t('TO_DO_TALK')} key={CONTACT_TYPE_TALK} />
          <TabPane tab={t('TO_DO_EMAIL')} key={CONTACT_TYPE_EMAIL}>
            <p>
              {todo.email}
            </p>
            <Button type="primary" icon={<SendEmail />} onClick={() => onSubmitEmail(todo)}>
              {t('TO_DO_EMAIL_SEND_EMAIL')}
            </Button>
          </TabPane>
        </Tabs>
      </div>
      {todo.contactType === CONTACT_TYPE_EMAIL
        ? (
          <span className="email-tracking">
            <p>{t('TS.EMAIL.TRACK.DESCRIPTION')}</p>
            <strong>{todo.surveySystemEmail ?? ''}</strong>
          </span>
        ) : (
          <CustomTextArea
            placeHolder={t('TO_DO_COMMENTS')}
            onChange={(value) => onChangeComment(todo, value)}
            value={todo.comment}
            maxLength={1000}
            className="textarea-comment"
          />
        )}
    </>
  );
};

export default ResponseMethodTab;
