import { http } from './common';
import {
  ASSGIN_TAG_TO_TAG_GROUP,
  CREATE_TAG_GROUP,
  DELETE_TAG_GROUP,
  EDIT_TAG_GROUP,
  GET_ALL_TAGS_BY_TAG_GROUP,
  GET_ALL_TAG_GROUP, GET_TAG_GROUPS_CHART, SEARCH_TAGS,
  SURVEY_TAGS_PROCESS,
  UNASSIGN_TAG_TO_TAG_GROUP,
} from './common/apis';

import { IAssignTagToTagGroupRequest } from 'models/IAssignTagToTagGroupRequest';
import { IAssignTagToTagGroupResponse } from 'models/IAssignTagToTagGroupResponse';
import { IGetAllTagGroupsRequest } from 'models/IGetAllTagGroupsRequest';
import { IGetAllTagGroupsResponse } from 'models/IGetAllTagGroupsResponse';
import { IGetAllTagsByTagGroupRequest } from 'models/IGetAllTagsByTagGroupRequest';
import { IGetAllTagsByTagGroupResponse } from 'models/IGetAllTagsByTagGroupResponse';
import { ISearchSurveyTagRequest } from 'models/ISearchSurveyTagRequest';
import { ISearchSurveyTagResponse } from 'models/ISearchSurveyTagResponse';
import { ISurveyTagProcessRequest } from 'models/ISurveyTagProcessRequest';
import { ISurveyTagProcessResponse } from 'models/ISurveyTagProcessResponse';
import { IUnAssignTagToTagGroupRequest } from 'models/IUnAssignTagToTagGroupRequest';
import { IUnAssignTagToTagGroupResponse } from 'models/IUnAssignTagToTagGroupResponse';

export const processSurveyTag = async (
  data: ISurveyTagProcessRequest,
): Promise<ISurveyTagProcessResponse> => {
  const res = await http.post(SURVEY_TAGS_PROCESS, data);
  return res.data;
};

export const searchSurveyTags = async (
  body: ISearchSurveyTagRequest,
): Promise<ISearchSurveyTagResponse[]> => {
  const res = await http.post(SEARCH_TAGS, body);
  return res.data;
};

export const getAllTagGroups = async (
  body: IGetAllTagGroupsRequest,
): Promise<IGetAllTagGroupsResponse> => {
  const res = await http.post(GET_ALL_TAG_GROUP, body);
  return res.data;
};

export const createTagGroup = async (
  tagGroupName: string,
  operatorId: string,
): Promise<any> => {
  const res = await http.post(CREATE_TAG_GROUP, {
    operatorId,
    tagGroupName,
  });
  return res.data;
};

export const editTagGroup = async (
  id: number,
  operatorId: string,
  tagGroupName: string,
): Promise<any> => {
  const res = await http.post(EDIT_TAG_GROUP, {
    id,
    operatorId,
    tagGroupName,
  });
  return res.data;
};

export const deleteTagGroup = async (
  operatorId: string,
  tagGroupId: number,
) => {
  const res = await http.delete(
    DELETE_TAG_GROUP.replace('{operatorId}', operatorId).replace(
      '{tagGroupId}',
      tagGroupId.toString(),
    ),
  );
  return res.data;
};
export const getAllTagsByTagGroup = async (
  body: IGetAllTagsByTagGroupRequest,
): Promise<IGetAllTagsByTagGroupResponse> => {
  const res = await http.post(GET_ALL_TAGS_BY_TAG_GROUP, body);
  return res.data;
};

export const assignTagToTagGroup = async (
  body: IAssignTagToTagGroupRequest,
): Promise<IAssignTagToTagGroupResponse> => {
  const res = await http.post(ASSGIN_TAG_TO_TAG_GROUP, body);
  return res.data;
};

export const unAssignTagToTagGroup = async (
  body: IUnAssignTagToTagGroupRequest,
): Promise<IUnAssignTagToTagGroupResponse> => {
  const res = await http.post(UNASSIGN_TAG_TO_TAG_GROUP, body);
  return res.data;
};

export const getTagGroupChartData = async (
  dateFrom: string,
  dateTo: string,
  clubId: number | null,
): Promise<any> => {
  const res = await http.post(
    GET_TAG_GROUPS_CHART,
    {
      fromDate: dateFrom,
      toDate: dateTo,
      clubIds: clubId ? [clubId] : null,
    },
  );
  return res.data;
};

export default {
  processSurveyTag,
  searchSurveyTags,
  getAllTagGroups,
  editTagGroup,
  createTagGroup,
  deleteTagGroup,
  getAllTagsByTagGroup,
  assignTagToTagGroup,
  unAssignTagToTagGroup,
  getTagGroupChartData,
};
