import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Checkbox,
  Col,
  Form,
  FormInstance,
  Row,
  Tooltip,
} from 'antd';
import _ from 'lodash';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import ClubsPercentage from './ClubPercentage';

import CustomButton from 'components/CustomButton';
import Club from 'models/Club';
import { useAppSelector } from 'store/hooks';
import { formatSendOutConfigListUsedFromForm } from 'utils/survey';

import './send-out-percentage.scss';

interface SendOutPercentageProps {
  form: FormInstance
}

const SendOutPercentage = (props: SendOutPercentageProps) => {
  const { form } = props;
  const { data } = useAppSelector((state) => state.survey);
  const [isSendOutPercentage, setSendOutPercentage] = useState<boolean>(
    Boolean(form.getFieldValue('surveySendOutConfigs')
    && form.getFieldValue('surveySendOutConfigs').length > 0),
  );
  const [clubListUsed, setClubListUsed] = useState<string[]>([]);
  const { clubPercentages } = data;
  const { t } = useTranslation();

  const initClubListUsed = useCallback(() => {
    const surveySendOutConfigs = form.getFieldValue('surveySendOutConfigs');
    if (surveySendOutConfigs
    && surveySendOutConfigs.length > 0) {
      setClubListUsed(formatSendOutConfigListUsedFromForm(surveySendOutConfigs));
    }
  }, [form]);

  useEffect(() => {
    initClubListUsed();
  }, [initClubListUsed]);

  const handleChangeSendOutPercentage = async () => {
    setSendOutPercentage(!isSendOutPercentage);
    form.setFieldsValue({ isSendOutPercentage: !isSendOutPercentage });
    if (form.getFieldValue('surveySendOutConfigs').length === 0) {
      form.setFieldsValue({
        surveySendOutConfigs: [
          {
            clubs: [],
            percentage: 0,
          },
        ],
      });
    }
  };

  const onUpdateClubListUsed = useCallback((value: string) => {
    setClubListUsed([...clubListUsed, value]);
  }, [clubListUsed]);

  const onRemoveClubListUsed = useCallback((value: string) => {
    setClubListUsed(clubListUsed.filter((clubItemUsed) => clubItemUsed !== value));
  }, [clubListUsed]);

  const handleRemoveClubPercentage = useCallback((
    removeCallBack: () => void,
    dataClub: {club: string[]},
  ) => {
    if (dataClub.club) {
      setClubListUsed(
        clubListUsed.filter((clubItemUsed) => !dataClub.club.includes(clubItemUsed)),
      );
    }
    removeCallBack();
  }, [clubListUsed]);

  const clubListCanChoose = useMemo(
    () => _.sortBy(clubPercentages
      .filter(
        (club: Club) => !clubListUsed.some((clubItem) => clubItem === club.name),
      )
      .map((clubItem: Club) => ({
        label: clubItem.name,
        value: clubItem.name,
      })), (club) => club.label.toLocaleLowerCase(), ['desc']),
    [clubPercentages, clubListUsed],
  );

  return (
    <Row className="send-out-percentage" gutter={24}>
      <Col md={24}>
        <div className="send-out-percentage__head">
          <Form.Item
            name="isSendOutPercentage"
            fieldKey="isSendOutPercentage"
            initialValue={isSendOutPercentage}
          >
            <Checkbox
              checked={isSendOutPercentage}
              onChange={handleChangeSendOutPercentage}
            >
              { t('TS.SEND.OUT.PERCENTAGE')}
            </Checkbox>
            <Tooltip placement="right" title={t('TS.SEND.OUT.PERCENTAGE.DESCRIPTION')}>
              <InfoCircleOutlined />
            </Tooltip>
          </Form.Item>
        </div>
      </Col>
      {
        isSendOutPercentage && (
        <Col md={16} span={8}>
          <div className="send-out-percentage__list">
            <Form.List name="surveySendOutConfigs">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <ClubsPercentage
                      key={field.key}
                      field={field}
                      form={form}
                      clubList={clubListCanChoose}
                      onRemove={
                        () => handleRemoveClubPercentage(
                          () => remove(field.name),
                          form.getFieldValue('surveySendOutConfigs')[field.name],
                        )
                      }
                      isEnableRemove={Boolean(fields.length > 1)}
                      onUpdateClubList={onUpdateClubListUsed}
                      onRemoveClubList={onRemoveClubListUsed}
                    />
                  ))}
                  <CustomButton
                    className="send-out-percentage__add"
                    onClick={add}
                    icon={<PlusOutlined />}
                    disabled={clubListUsed.length === clubPercentages.length}
                  >
                    {t('TS.CREATE_SURVEY.ADD_BUTTON')}
                  </CustomButton>
                </>
              )}
            </Form.List>
          </div>
        </Col>
        )
      }
    </Row>
  );
};

export default SendOutPercentage;
