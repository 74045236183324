import { Form } from 'antd';
import MaskedInput from 'antd-mask-input'; 
import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  getMinimumTime,
  getTimeConfig,
  updateMinimumTime,
  updateTimeConfig,
  getSystemConfigs,
} from '../../../services/setting';

import ConfirmModal from 'components/ConfirmModal';
import CustomButton from 'components/CustomButton';
import CustomSelect from 'components/CustomSelect';
import Loading from 'components/Loading';
import { REGEX_HH_MM_SS } from 'constants/regex';
import { NOT_CONFIG } from 'constants/setting';
import SystemSetting from 'models/SystemSetting';
import { NOTIFICATION_TYPE } from 'utils/constants';
import { showNotification } from 'utils/helpers';
import { useTranslation } from 'react-i18next';

const SurveySettings = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [shouldShowConfirm, setShouldShowConfirm] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [minimumTimeList, setMinimumTimeList] = useState<SystemSetting[]>([]);

  const getTimeConfigFromSetting = useCallback(async () => {
    setIsLoadingData(true);
    Promise.all([
      getTimeConfig(),
      getMinimumTime(),
      getSystemConfigs(),
    ]).then((responses: any) => {
      const time = responses.find(
        (response: any) => response.key && response.key === 'MemberSurveySendAt',
      );

      if (time) {
        form.setFieldsValue(
          { time: time.value },
        );
      }

      const minimumTimeSetting = responses.find(
        (setting: any) => Array.isArray(setting),
      ).find((setting: SystemSetting) => setting.key === 'Survey.MinimumTimeFrequency');

      form.setFieldsValue({
        minimumTime: minimumTimeSetting ? minimumTimeSetting.value : NOT_CONFIG,
      });

      const minimumTime = responses.find(
        (response: any) => response.result,
      );
      if (minimumTime.result) {
        setMinimumTimeList(minimumTime.result);
      }
    }).catch(() => {
      showNotification(
        t('TS.ERROR'),
        t('TS_NOTIFICATION_SERVICES_NOTIFICATION'),
        NOTIFICATION_TYPE.ERROR,
      );
      setIsLoadingData(false);
    }).finally(() => {
      setIsLoadingData(false);
    });
  }, [form]);

  useEffect(() => {
    getTimeConfigFromSetting();
  }, [getTimeConfigFromSetting]);

  const onSubmitSetting = async () => {
    const values = await form.validateFields();
    const { time } = values;
    if (REGEX_HH_MM_SS.test(time)) {
      setShouldShowConfirm(true);
    }
  };

  const handleShouldShowConfirm = () => {
    setShouldShowConfirm(!shouldShowConfirm);
  };

  const handleConfirmUpdateTime = async () => {
    const values = await form.validateFields();
    const { time, minimumTime } = values;
    setIsLoadingSubmit(true);
    Promise.all([
      updateTimeConfig(time),
      updateMinimumTime(minimumTime),
    ]).then((responses: any) => {
      const dataStatus = responses.find((response: any) => response.status);
      if (dataStatus && dataStatus.status === 200) {
        showNotification(
          t('TS.SUCCESS'),
          t('TS.TIME.UPDATE_SUCCESSFULLY'),
          NOTIFICATION_TYPE.SUCCESS,
        );
      }
    }).catch(() => {
      showNotification(
        t('TS.ERROR'),
        t('TS_NOTIFICATION_SERVICES_NOTIFICATION'),
        NOTIFICATION_TYPE.ERROR,
      );
    }).finally(() => {
      setIsLoadingSubmit(false);
      handleShouldShowConfirm();
    });
  };

  return (
    <>
      <div className="survey-settings">
        <div className="survey-settings__content">
          <div className="survey-settings__input">
            {
              !isLoadingData
                ? (
                  <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSubmitSetting}
                  >
                    <Form.Item
                      name="time"
                      label={t('TS.SENDING.SURVEY')}
                      rules={[
                        {
                          pattern: REGEX_HH_MM_SS,
                          message: t('TS.INVALID_FORMAT', { key: t('TS.TIME') }),
                        },
                      ]}
                    >
                      <MaskedInput
                        mask="00:00:00"
                      />
                    </Form.Item>
                    <Form.Item name="minimumTime">
                      <CustomSelect
                        label={t('TS.MINIMUM.SEND.TIME.SURVEY')}
                        options={minimumTimeList.map((minimumTime) => ({
                          label: minimumTime.value,
                          value: minimumTime.key,
                        }))}
                      />
                    </Form.Item>
                    <CustomButton
                      label={t('TS.SAVE')}
                      htmlType="submit"
                    />
                  </Form>
                )
                : <Loading loadingMode="component" />
            }
          </div>
        </div>
      </div>

      {
      shouldShowConfirm
      && (
      <ConfirmModal
        className="confirm-setting__modal"
        onCloseModal={handleShouldShowConfirm}
        title={t('TS.ARE_YOU_SURE_UPDATE')}
        content=""
        onConfirmModal={handleConfirmUpdateTime}
        isSubmitting={isLoadingSubmit}
      />
      )
    }
    </>
  );
};

export default SurveySettings;
