import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IClub } from 'models/Club';
import { getClubs } from 'services/club';

interface ClubState {
  loading: boolean;
  clubs: IClub[];
}

const initialState: ClubState = {
  loading: false,
  clubs: [],
};

export const getClubsAsync = createAsyncThunk(
  'clubState',
  async () => {
    const clubs = await getClubs();
    return clubs;
  },
);

export const clubSlice = createSlice({
  name: 'club',
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(getClubsAsync.pending, ($state: any, action: any) => {
      $state.loading = true;
    });
    builder.addCase(getClubsAsync.fulfilled, ($state: any, action: any) => {
      $state.loading = false;
      $state.clubs = action.payload.clubs.map(
        (club: IClub) => ({ ...club }),
      );
    });
  },
});

export default clubSlice.reducer;
