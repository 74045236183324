export interface IRequestSurveySort {
  queryKey: string,
  operation: string
}

class RequestSurveySort implements IRequestSurveySort {
  queryKey: string;
  operation: string;

  constructor(queryKey: string, operation: string) {
    this.queryKey = queryKey;
    this.operation = operation;
  }
}

export default RequestSurveySort;
