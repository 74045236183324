export const PAGE_DEFAULT_INDEX = 1;
export const PAGE_DEFAULT_SIZE = 10;
export const TOTAL_PAGE = 10;
export const TOTAL_RECORDS = 10;

const PER_ITEM1 = 10;
const PER_ITEM2 = 20;
const PER_ITEM3 = 50;
export const TAG_GROUPS_PER_PAGE = 5;

export const PER_PAGES_DROPDOWN = [
  { label: PER_ITEM1.toString(), value: PER_ITEM1 },
  { label: PER_ITEM2.toString(), value: PER_ITEM2 },
  { label: PER_ITEM3.toString(), value: PER_ITEM3 },
];

export const DEFAULT_PAGING = {
  pageIndex: 1,
  pageSize: 10,
  totalPages: 0,
  totalRecords: 0,
};
