import { Column, newColumn } from 'models/utility/Column';

export const SURVEY_COLUMN: Column[] = [
  newColumn({
    title: 'No', dataIndex: 'no', sorter: false, width: 100,
  }),
  newColumn({
    title: 'Survey Name', dataIndex: 'surveyName', sorter: true, width: 300, ellipsis: true,
  }),
  newColumn({
    title: 'Creator', dataIndex: 'creator', sorter: true, width: 170,
  }),
  newColumn({
    title: 'Create Date', dataIndex: 'createdDate', sorter: true, width: 170,
  }),
  newColumn({
    title: 'Status', dataIndex: 'status', sorter: true, width: 170,
  }),
  newColumn({
    title: '', dataIndex: 'id', sorter: false, width: 170,
  }),
];

export const ACTIVE = 'Active';
export const INACTIVE = 'Inactive';
export const ALL = 'All';
export const SURVEY_STATUS: string[] = [
  ALL,
  ACTIVE,
  INACTIVE,
];

export const SORT_DESC = 'desc';
export const SORT_AC = 'asc';

export default {
  SURVEY_COLUMN,
  SURVEY_STATUS,
};
