import { SURVEY_COLUMN } from '../../../constants/survey';
import Survey from '../../../models/Survey/Survey';

import CustomTable from 'components/CustomTable';
import { IResponsePaging } from 'models/IResponsePaging';

interface ISurveyList {
  dataSurvey: Survey[],
  paging: IResponsePaging,
  onChangePagination: (page: { pageIndex: number, pageSize: number }) => void
  onChangeTable:(pagination: any, filters: any, sorter: any) => void
  isLoadingTable: boolean
  viewSurveyDetail: (id: string) => void;
}

const SurveyList = (props: ISurveyList) => {
  const {
    dataSurvey,
    paging,
    onChangePagination,
    onChangeTable,
    isLoadingTable = false,
    viewSurveyDetail,
  } = props;

  return (
    <div className="survey-list">
      <CustomTable
        dataList={dataSurvey}
        columns={SURVEY_COLUMN}
        paging={paging}
        onChangePagination={onChangePagination}
        onChangeTable={onChangeTable}
        loading={isLoadingTable}
        viewDetail={viewSurveyDetail}
      />
    </div>
  );
};

export default SurveyList;
