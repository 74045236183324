import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import NoteItem from '../NoteItem';

import Loading from 'components/Loading';
import NoteResponse from 'models/NoteResponse';

interface NoteListProps {
  noteList: NoteResponse[];
  isLoading: boolean;
}

const NoteList = (props: NoteListProps) => {
  const { noteList, isLoading } = props;
  const { t } = useTranslation();

  const enableScrollList = useMemo(
    () => (noteList.length >= 4
        && noteList.some((note) => note.note.length > 200))
      || noteList.length >= 5,
    [noteList],
  );

  const contentRendered = useMemo(() => {
    if (isLoading) {
      return <Loading loadingMode="component" />;
    }
    if (noteList.length === 0) {
      return <span>{t('TS.NO.NOTE')}</span>;
    }

    return (
      <>
        {noteList.map((note: NoteResponse) => (
          <NoteItem key={note.noteId} note={note} />
        ))}
      </>
    );
  }, [noteList, isLoading]);

  return (
    <div
      className={classNames('note-modal__list', {
        'note-modal__list--empty': noteList.length === 0,
        'note-modal__list--scroll': enableScrollList,
      })}
    >
      {contentRendered}
    </div>
  );
};

export default NoteList;
