import classNames from 'classnames';
import moment from 'moment';

import NoteResponse from 'models/NoteResponse';

import './note-item.scss';

interface NoteItemProps {
   note: NoteResponse
}

const NoteItem = (props: NoteItemProps) => {
  const { note } = props;
  return (
    <div className="note-item">
      <div className="note-item__head">
        <strong>
          { note.operatorName}
        </strong>
        <span> - </span>
        <span className="note-item__time">
          { moment.utc(note.createdDate).format('DD/MM/YYYY HH:mm')}
        </span>
      </div>
      <div className={classNames('note-item__content', {
        'note-item__content--scroll': note.note.length > 200,
      })}
      >
        <span
          dangerouslySetInnerHTML={{ __html: note.note }}
        />
      </div>
      <hr />
    </div>
  );
};

export default NoteItem;
