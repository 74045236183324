import { useTranslation } from 'react-i18next';

import {
  EditTagIcon,
  RemoveIcon,
  TagGroupIcon,
} from '../../../components/Icons';

import CustomButton from 'components/CustomButton';
import TagItem from 'components/TagItem';
import { CREATE_EDIT_TAG_TAG_GROUP } from 'constants/roles';
import { MODE_ADD_TAG, MODE_EDIT } from 'constants/tagGroup';
import Tag from 'models/Tag';
import TagGroup from 'models/TagGroup';

interface ITagGroupItem {
  onChangeClickTagGroup: (mode: string, tagGroup?: TagGroup) => void,
  onRemoveTagGroup: (tagGroup: TagGroup) => void,
  tagGroup: TagGroup,
  permissions: string[],
  setTagIdDeleted: (tagId: number) => void,
  setTagGroup: (tagGroup: TagGroup) => void,
  onOpenDeleteTagItemModal: () => void
}

const TagGroupItem = (props: ITagGroupItem) => {
  const {
    onChangeClickTagGroup,
    onRemoveTagGroup,
    tagGroup,
    permissions,
    setTagIdDeleted,
    setTagGroup,
    onOpenDeleteTagItemModal,
  } = props;

  const { t } = useTranslation();

  const handleClickTagItem = (tag: Tag, tagG: TagGroup) => {
    setTagIdDeleted(tag?.tagId ?? 0);
    setTagGroup(tagG);
    onOpenDeleteTagItemModal();
  };

  return (
    <div className="group-item">
      <div className="group-item__header">
        <div className="group-item__header-title">
          <span className="group-item__header-icon">
            <TagGroupIcon />
          </span>
          <div className="group-item__header-label">
            {t('TS.TAG_GROUP')}
            <span>
              { tagGroup.tagGroupName }
            </span>
          </div>
        </div>
        <div className="group-item__header-action">
          {
            permissions.includes(CREATE_EDIT_TAG_TAG_GROUP) && (
            <>
              <CustomButton
                className="group-action"
                icon={<TagGroupIcon />}
                onClick={() => onChangeClickTagGroup(MODE_ADD_TAG, tagGroup)}
                label="Add tag"
              />
              <CustomButton
                className="group-action"
                icon={<EditTagIcon />}
                label="Edit"
                onClick={() => onChangeClickTagGroup(MODE_EDIT, tagGroup)}
              />
              <CustomButton
                className="group-action"
                icon={<RemoveIcon />}
                label="Remove"
                onClick={() => onRemoveTagGroup(tagGroup)}
              />
            </>
            )
          }
        </div>
      </div>
      {
        tagGroup.tags
        && tagGroup.tags.length > 0
        && (
        <div className="group-item__list">
          { tagGroup.tags.map((tag) => (
            <TagItem
              key={tag.tagSentiment + tag.tagName}
              tag={tag}
              onClick={() => handleClickTagItem(tag, tagGroup)}
              showEdit={false}
            />
          )) }
        </div>
        )
      }
    </div>
  );
};

export default TagGroupItem;
