import { DatePicker } from 'antd';
import moment from 'moment';

import { DATE } from '../../constants/datePicker';

import { DATE_FORMAT } from 'constants/date';

interface ICustomDatePicker {
  date?: string,
  onChangeDatePicker?: (dateValue: moment.Moment) => void,
  picker?: 'date' | 'week' | 'month' | 'quarter' | 'year',
  className?: string,
  placeholder?: string,
  label?: string,
  format?: string,
  allowClear?:boolean
  disabledDate?: ((currentDate: moment.Moment) => boolean) | undefined
}

const CustomDatePicker = (props: ICustomDatePicker) => {
  const {
    onChangeDatePicker,
    picker = DATE,
    className = '',
    placeholder = '',
    label,
    format = DATE_FORMAT,
    allowClear = false,
    date,
    disabledDate,
    ...restProps
  } = props;

  const onChangeDate = (dateValue: moment.Moment | null) => {
    if (dateValue) {
      return (onChangeDatePicker
        ? onChangeDatePicker(dateValue)
        : null);
    }
    return null;
  };

  return (
    <div className="custom-date-picker">
      { label && (
      <span className="custom-date-picker-label">
        { label }
      </span>
      )}
      <DatePicker
        className={`custom-date-picker-input  ${className}`}
        onChange={(dateValue) => onChangeDate(dateValue)}
        picker={picker}
        placeholder={placeholder}
        bordered={false}
        allowClear={allowClear}
        value={moment(moment(date).format(format), format)}
        format={format}
        disabledDate={disabledDate}
        {...restProps}
      />
    </div>
  );
};
export default CustomDatePicker;
