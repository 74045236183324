export const formatUrlApiWithId = (url: string, id: string) => url.replace('{id}', id);

export const formatPoint = (point: number) => (point < 10 ? point.toString().padStart(2, '0') : point).toString();

export const capitalizeFirstLetter = (string: string) => string[0].toUpperCase() + string.slice(1);

export default {
  formatUrlApiWithId,
  formatPoint,
};
