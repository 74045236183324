import { DEFAULT_PAGING } from 'constants/pagination';
import NoteResponse from 'models/NoteResponse';
import { IPagination } from 'models/utility/Pagination';

export const SET_NOTE_LIST = 'SET_NOTE_LIST';
export const SET_LOADING = 'SET_LOADING';
export const SET_NEW_NOTE = 'SET_NEW_NOTE';
export const SET_PAGING = 'SET_PAGING';

interface StateInterface {
  noteList: NoteResponse[],
  newNote: {
    note: string
  },
  isLoading: boolean,
  paging: IPagination
}

export const initialState: StateInterface = {
  noteList: [],
  newNote: {
    note: '',
  },
  isLoading: false,
  paging: { ...DEFAULT_PAGING },
};

export function reducer(state: StateInterface, action: any) {
  switch (action.type) {
    case SET_NOTE_LIST: {
      return {
        ...state,
        noteList: action.noteList,
        paging: {
          ...action.paging,
          totalRecords: action.paging.totalItemsCount,
        },
      };
    }
    case SET_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }
    case SET_NEW_NOTE: {
      return {
        ...state,
        newNote: action.newNote,
      };
    }
    case SET_PAGING: {
      return {
        ...state,
        paging: action.paging,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}

export default {
  initialState,
  reducer,
};
