import { FILTER_DASHBOARD_DEFAULT, FILTER_SURVEY_DEFAULT } from './filter';
import { PAGE_DEFAULT_INDEX, PAGE_DEFAULT_SIZE } from './pagination';

import RequestDashboard from 'models/Dashboard/RequestDashboard';
import RequestSurvey from 'models/Survey/RequestSurvey';
import RequestSurveySort from 'models/Survey/RequestSurveySort';

export const REQUEST_SURVEY_DEFAULT: RequestSurvey = new RequestSurvey(
  PAGE_DEFAULT_INDEX,
  PAGE_DEFAULT_SIZE,
  FILTER_SURVEY_DEFAULT,
  new RequestSurveySort(
    'createdDate',
    'desc',
  ),
);

export const REQUEST_DASHBOARD_DEFAULT: RequestDashboard = new RequestDashboard({
  filters: FILTER_DASHBOARD_DEFAULT,
});

export default {
  REQUEST_SURVEY_DEFAULT,
  REQUEST_DASHBOARD_DEFAULT,
};
