interface Column {
  title: string;
  dataIndex: string;
  sorter: boolean;
  width?: number;
  ellipsis?: boolean
}

export function newColumn(column: Column): Column {
  return {
    title: column.title,
    dataIndex: column.dataIndex,
    sorter: column.sorter,
    width: column.width,
    ellipsis: column.ellipsis,
  };
}

export type { Column };
