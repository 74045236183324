import { Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ISurveyTitle {
  index: number;
  content: string;
}

const SurveyTitle: React.FC<ISurveyTitle> = ({ index, content }) => {
  const { t } = useTranslation();
  return (
    <Row className="create-survey__title">
      <div>{t('TS.CREATE_SURVEY_NUMBER', { key: index })}</div>
      <p>{content}</p>
    </Row>
  );
};

export default SurveyTitle;
