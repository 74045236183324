import { List, Avatar } from 'antd'; 
import moment from 'moment';

import ViewMore from 'components/ViewMore';
import { DATE_FORMAT_WITH_TIME } from 'constants/date';
import Survey from 'models/Survey/Survey';
import { formatPoint } from 'utils/stringHelper';
import { useTranslation } from 'react-i18next';

interface IMentionItem {
  survey: Survey,
  status: string
}

const MentionItem = (props: IMentionItem) => {
  const { status, survey } = props;
  const { t } = useTranslation();

  let clubName = survey.club?.name;
  if (survey.responseEdited?.club) {
    clubName = survey.responseEdited.club.name;
  }

  return (
    <div className={`mention-item--${status} mention-item`}>
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar size={50} src={survey.imageUrl ? survey.imageUrl : 'https://joeschmoe.io/api/v1/random'} />}
          title={survey.fullName}
          description={(
            <div className="mention-item__label">
              <span className="mention-item__label-time">
                {moment.utc(survey.createdDate).format(DATE_FORMAT_WITH_TIME)}
              </span>
              <span className="mention-item__label-response">
                {t('TS.DASHBOARD.RESPONSE')}
                <span className="mention-item__label-from">
                  {clubName}
                </span>
              </span>
            </div>
        )}
        />
      </List.Item>
      <div className="mention-item__description">
        <div className="mention-item__description-content">
          <div className="mention-item__description-text">
            <ViewMore
              text={survey.feedbackContent ?? ''}
              numberCharacters={200}
            />
          </div>
        </div>
        <div className="mention-item__description-point">
          <div className="mention-item__description-point-container">
            <span className="mention-item__description-point-label">
              {t('TS.POINTS')}
            </span>
            <span className="mention-item__description-point-number">
              { formatPoint(survey.point ?? 0)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentionItem;
