import 'App.scss';

import React, { useEffect, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthProvider, useAuth } from 'react-oidc-context';
import { signOutRedirect } from 'store/profile';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
} from 'react-router-dom';

import Loading from 'components/Loading';
import RoutePermission from 'components/RoutePermission';
import config from 'config';
import { store } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { signInRedirect } from 'store/profile';
import { ACCESS_TOKEN, SUBTENANT_ID, TENANT_ID } from 'utils/constants';
import { updateLocaleMoment } from 'utils/timeHelper';

const App: React.FC = () => {
  const auth = useAuth();
  const { isSigninRedirecting, isSignoutRedirecting } = useAppSelector(
    (state) => state.profile,
  );

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  updateLocaleMoment(t);

  useEffect(() => {
    if (!auth.isLoading && !auth.isAuthenticated && !isSigninRedirecting) {
      dispatch(signInRedirect());
    }
  }, [auth.isLoading, auth.isAuthenticated, isSigninRedirecting, dispatch]);

  useEffect(() => {
    if (auth.user) {
      localStorage.setItem(ACCESS_TOKEN, auth.user.access_token);
      localStorage.setItem(TENANT_ID, auth.user.profile.tenantId as string);
      localStorage.setItem(SUBTENANT_ID, auth.user.profile.subTenantId as string);
    }
  }, [auth.user]);
  
  useEffect(() => {
    if(auth.error) {
      dispatch(signOutRedirect())
    }
  }, [auth.error])

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <div>Signing you in...</div>;
    case 'signoutRedirect':
      return <div>Signing you out...</div>;
    default:
  }

  if (auth.isLoading || isSigninRedirecting || isSignoutRedirecting) {
    return <Loading />;
  }

  if (auth.error) {
    return (
      <>
        Oops...
        {auth.error.message}
      </>
    );
  }

  return (
    <RoutePermission />
  );
};

const AppWrapper: React.FC = () => (
  <AuthProvider {...config.oidc}>
    <Provider store={store}>
      <Suspense fallback={<Loading />}>
        <Router>
          <App />
        </Router>
      </Suspense>
    </Provider>
  </AuthProvider>
);

export default AppWrapper;
