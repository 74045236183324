import {
  VIEW_DASHBOARD,
  VIEW_SURVEY,
  VIEW_TO_DO_LIST,
  VIEW_ALL_RESPONSE,
  VIEW_TAG_TAG_GROUP,
  CREATE_EDIT_SURVEY,
  VIEW_MY_TODO,
} from './roles';

import Route from 'models/Route';
import AllResponsesPage from 'pages/AllResponses';
import CreateSurveyPage from 'pages/CreateSurvey';
import DashboardPage from 'pages/Dashboard';
import SurveyPage from 'pages/Survey';
import TagGroupPage from 'pages/TagGroup';
import ToDoPage from 'pages/ToDo';
import ViewEditSurvey from 'pages/ViewEditSurvey';

export const DASHBOARD_ROUTE = '/dashboard';
export const SURVEY_ROUTE = '/survey';
export const TO_DO_ROUTE = '/to-do';
export const ALL_RESPONSE_ROUTE = '/all-responses';
export const TAG_GROUP_ROUTE = '/tag-group';
export const CREATE_SURVEY_ROUTE = '/create-survey';
export const EDIT_SURVEY_ROUTE = '/view-detail/:id';

export const ROUTES_APP: Route[] = [
  new Route({
    url: DASHBOARD_ROUTE,
    permissionAccess: [VIEW_DASHBOARD],
    component: DashboardPage,
  }),
  new Route({
    url: SURVEY_ROUTE,
    permissionAccess: [VIEW_SURVEY],
    component: SurveyPage,
  }),
  new Route({
    url: TO_DO_ROUTE,
    permissionAccess: [VIEW_TO_DO_LIST, VIEW_MY_TODO],
    component: ToDoPage,
  }),
  new Route({
    url: ALL_RESPONSE_ROUTE,
    permissionAccess: [VIEW_ALL_RESPONSE],
    component: AllResponsesPage,
  }),
  new Route({
    url: TAG_GROUP_ROUTE,
    permissionAccess: [VIEW_TAG_TAG_GROUP],
    component: TagGroupPage,
  }),
  new Route({
    url: CREATE_SURVEY_ROUTE,
    permissionAccess: [CREATE_EDIT_SURVEY],
    component: CreateSurveyPage,
  }),
  new Route({
    url: EDIT_SURVEY_ROUTE,
    permissionAccess: [CREATE_EDIT_SURVEY, VIEW_SURVEY],
    component: ViewEditSurvey,
  }),
];
