import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  SURVEY_STATUS,
} from '../../../constants/survey';

import CustomButton from 'components/CustomButton';
import CustomDatePicker from 'components/CustomDatePicker';
import CustomSelect from 'components/CustomSelect';
import InputCustom from 'components/InputCustom';
import { ORANGE } from 'constants/button';
import { DATE_FORMAT_DD_MM_YYYY } from 'constants/date';
import {
  FILTER_DATE_FROM, FILTER_DATE_TO, FILTER_STATUS, FILTER_SURVEY_NAME,
} from 'constants/filter';
import { CREATE_EDIT_SURVEY } from 'constants/roles';
import { IRequestSurveyFilter } from 'models/Survey/RequestSurveyFilter';

interface ISurveyFilter {
  onChangeFilter: (nameFilter:string, value:string | moment.Moment) => void;
  onSubmitFilter: () => void;
  filters: IRequestSurveyFilter [];
  goToCreateFilter: () => void;
  permissions: string[]
}

const SurveyFilter = (props: ISurveyFilter) => {
  const {
    onChangeFilter,
    onSubmitFilter,
    filters = [],
    goToCreateFilter,
    permissions,
  } = props;

  const { t } = useTranslation();

  const dateFromFilter = filters.find((filter) => filter.queryKey === FILTER_DATE_FROM);
  const dateToFilter = filters.find((filter) => filter.queryKey === FILTER_DATE_TO);
  const statusFilter = filters.find((filter) => filter.queryKey === FILTER_STATUS);
  const surveyName = filters.find((filter) => filter.queryKey === FILTER_SURVEY_NAME);

  const isDateToBeforeDateFrom = (dateTo: moment.Moment): boolean => {
    if (dateFromFilter?.queryValue) {
      return dateTo && dateTo.isBefore(moment(dateFromFilter.queryValue));
    } return false;
  };

  const isDateFromAfterDateTo = (dateFrom: moment.Moment): boolean => {
    if (dateToFilter?.queryValue) {
      return dateFrom && dateFrom.isAfter(moment(dateToFilter.queryValue));
    } return false;
  };

  return (
    <div className="survey-filter">
      <CustomSelect
        label={t('TS.STATUS')}
        onChange={(value) => onChangeFilter(
          FILTER_STATUS,
          value,
        )}
        defaultValue={SURVEY_STATUS[0].substring(0, 2).toUpperCase()}
        value={statusFilter?.queryValue}
        options={SURVEY_STATUS.map((status) => ({
          label: status,
          value: status.substring(0, 2).toUpperCase(),
        }))}
      />
      <CustomDatePicker
        onChangeDatePicker={(date) => onChangeFilter(
          FILTER_DATE_FROM,
          date,
        )}
        label={t('TS.FROM')}
        date={dateFromFilter?.queryValue ?? ''}
        format={DATE_FORMAT_DD_MM_YYYY}
        disabledDate={(currentDate: moment.Moment) => isDateFromAfterDateTo(currentDate)}
      />
      <CustomDatePicker
        onChangeDatePicker={(date) => {
          onChangeFilter(
            FILTER_DATE_TO,
            date,
          );
        }}
        date={dateToFilter?.queryValue ?? ''}
        label={t('TS.TO')}
        format={DATE_FORMAT_DD_MM_YYYY}
        disabledDate={(currentDate: moment.Moment) => isDateToBeforeDateFrom(currentDate)}
      />
      <InputCustom
        value={surveyName?.queryValue ?? ''}
        onSubmitButton={onSubmitFilter}
        label={t('TS.SEARCH')}
        maxLength={100}
        onChangeInput={(value:string) => onChangeFilter(FILTER_SURVEY_NAME, value)}
        autoFocus
      />
      <CustomButton
        onClick={onSubmitFilter}
        label={t('TS.SEARCH')}
      />
      {permissions.includes(CREATE_EDIT_SURVEY) && (
      <CustomButton
        label={t('TS.CREATE_SURVEY')}
        onClick={goToCreateFilter}
        backgroundColor={ORANGE}
      />
      )}
    </div>
  );
};

export default SurveyFilter;
