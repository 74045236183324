import { Input } from 'antd';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface ICustomInput {
  label?: string,
  placeholder?: string;
  suffix?: ReactNode | string;
  onChangeInput?: (value: string) => void,
  value?: string,
  defaultValue?: string,
  disabled?: boolean,
  onSubmitButton?: () => void,
  maxLength?: number,
  onPressEnter?: () => void,
  prefix?: ReactNode,
  min?: string,
  autoComplete?: string;
  type?: string;
  format?: string;
  autoFocus?: boolean;
  handleKeyDown?: (event: any) => void;
  className?: string,
  max?: string
}

const InputCustom:React.FC<ICustomInput> = (props) => {
  const {
    placeholder,
    suffix = null,
    onChangeInput = () => null,
    value,
    defaultValue,
    disabled = false,
    onSubmitButton = () => null,
    maxLength,
    prefix = null,
    min,
    autoComplete,
    format,
    type,
    label,
    handleKeyDown,
    className,
    max,
    ...restProps
  } = props;

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && typeof onSubmitButton !== 'undefined') {
      onSubmitButton();
    }
    if (handleKeyDown) {
      handleKeyDown(event);
    }
  };

  const handleOnFocus = (value: string) => {
    if (Number(value) === 0 && type === 'number') {
      onChangeInput('');
    }
  };

  const handleOnBlur = (value: string) => {
    if (value === '' && type === 'number') {
      onChangeInput(String(0));
    }
  };

  return (
    <div className={classNames({ 'input-custom': !suffix, 'input-custom-suffix': suffix })}>
      {
        label && (
        <span className="input-custom__label">
          {label}
        </span>
        )
      }
      <Input
        placeholder={placeholder}
        suffix={suffix}
        onChange={(e) => onChangeInput(e.target.value)}
        disabled={disabled}
        value={value}
        defaultValue={defaultValue}
        maxLength={maxLength}
        onKeyDown={onKeyDown}
        prefix={prefix}
        min={min}
        type={type}
        autoComplete={autoComplete}
        className={className}
        max={max}
        onFocus={(e) => handleOnFocus(e.target.value)}
        onBlur={(e) => handleOnBlur(e.target.value)}
        {...restProps}
      />
    </div>
  );
};

export default InputCustom;
