import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import clubReducer from './club';
import operatorReducer from './operator';
import profileReducer from './profile';
import roleReducer from './role';
import surveyReducer from './survey';

export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development',
  reducer: {
    profile: profileReducer,
    survey: surveyReducer,
    role: roleReducer,
    operator: operatorReducer,
    club: clubReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
