import { http } from './common';
import { GET_CLUBS, GET_LIST_CLUB_DASHBOARD } from './common/apis';

import { IClub } from 'models/Club';

export const getClubs = async (): Promise<{
  isSuccess: boolean;
  clubs: IClub[]
}> => {
  const res = await http.get(GET_CLUBS);
  return res.data;
};

export const getClubListDashBoard = async ():Promise<IClub[]> => {
  const res = await http.get(GET_LIST_CLUB_DASHBOARD);
  return res.data;
};

export default getClubs;
