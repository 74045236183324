import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useDispatch } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { ROUTES_APP } from '../../constants/routes';
import RouteModel from '../../models/Route';
import NotAllowed from '../NotAllowed';

import Loading from 'components/Loading';
import Main from 'layout/Main';
import { useAppSelector } from 'store/hooks';
import { getRolesAsync } from 'store/role';
import { useLocation } from 'react-router-dom';
import { AppDispatch } from 'store';

const RouteWithPermission = () => {
   const dispatch = useDispatch<AppDispatch>();
  const auth = useAuth();
  const history = useNavigate();
   const location = useLocation();

  const { permissions, loading } = useAppSelector((state) => state.role);
  useEffect(() => {
    if (auth.user && auth.user.profile.operatorId && permissions.length === 0) {
      dispatch(getRolesAsync(auth.user.profile.operatorId));
    }
  }, []);

  const routesAppPermission = ROUTES_APP.map((route) => route.permissionAccess);
  if (location.pathname === '/' || location.pathname === '/callback') {
    const routesWithPermission = ROUTES_APP.filter(
      (route: RouteModel) => route.permissionAccess.some(
        (permission) => permissions.includes(permission),
      ),
    );
    if (routesWithPermission.length > 0) {
      history(routesWithPermission[0].url);
    }
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Routes>
      {permissions.some((permission) => routesAppPermission
        .some((routeAppPermission) => routeAppPermission.includes(permission)))
        && ROUTES_APP.map((route: RouteModel) => {
          if (route.permissionAccess.some((permission) => permissions.includes(permission))) {
            return (
              <Route
                key={route.url}
                path={route.url}
                element={
                  <Main>
                    {React.createElement(route.component)}
                  </Main>
                }
              />
            );
          }
          return null;
        })}
      <Route path="*" element={<NotAllowed />} />
    </Routes>
  );
};

export default RouteWithPermission;
