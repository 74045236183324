import classNames from 'classnames';
import React from 'react';

interface ILink {
  key: string,
  label: string,
  icon: React.ReactNode,
  isActive: boolean,
  badge?: number
}

interface ILinkNavigating {
  links: ILink[] | [],
}

const LinkNavigating: React.FC<ILinkNavigating> = ({ links }) => (
  <div className="link-navigating">
    <ul className="link-navigating__list">
      {links.map((link) => (
        <li
          className={classNames('link-navigating__list-item', {
            'link-navigating__list-item--active': link.isActive,
          })}
          key={link.key}
        >
          {link.icon}
          {link.label}
          {typeof link.badge !== 'undefined' && <span className="link-navigating__badge">{link.badge}</span>}
        </li>
      ))}

    </ul>
  </div>
);

export default LinkNavigating;
