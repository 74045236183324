import { Radio } from 'antd';
import React from 'react';

import './custom-radio.scss';
import { IOption } from 'models/IOption';

interface CustomRadioProps {
  label?: string;
  options: IOption[];
  defaultValue?: number | string | [];
  value?: string;
  disabled?: boolean;
  onChange?: (e: any) => void;
  wrapperClassName?: string;
}

const CustomRadio: React.FC<CustomRadioProps> = ({
  label,
  options,
  wrapperClassName = '',
  defaultValue,
  value,
  onChange,
  disabled,
}) => {
  const optionMapping = (option: IOption) => (
    <Radio
      value={option.value}
      key={option.value}
      disabled={option.disabled}
    >
      {option.label}
    </Radio>
  );

  return (
    <div className={`custom-radio ${wrapperClassName}`}>
      {label && <span>{label}</span>}
      <Radio.Group
        disabled={disabled}
        defaultValue={defaultValue ? defaultValue.toString() : undefined}
        value={value}
        onChange={onChange}
      >
        {options.map(optionMapping)}
      </Radio.Group>
    </div>
  );
};

export default CustomRadio;
