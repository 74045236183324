import { Form, FormInstance } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CustomDatePicker from 'components/CustomDatePicker';
import CustomSelect from 'components/CustomSelect';
import InputCustom from 'components/InputCustom';
import { REGEX_POSITIVE_NUMBER_FROM_1_TO_999 } from 'constants/regex';
import { IClubGroupMasterData } from 'models/IClubMasterData';
import { useAppSelector } from 'store/hooks';
import {
  CRITERIA_GROUP,
  DATE_FORMAT,
  VALUE_TYPE,
  SUFFIX_TEXT,
  OPERATOR_MORE_THAN_CODE,
  ALL_STATUS_CODE,
  ALL_CLUB_LABEL,
} from 'utils/constants';
import { getOptionsSurveyMember } from 'utils/survey';

interface IValueInput {
  typeInput: string | undefined;
  form: FormInstance;
  groupField: FormListFieldData;
  parentIdx: number;
}

const NumberInput: React.FC<{
  groupField: FormListFieldData;
  form: FormInstance;
  parentIdx: number;
}> = ({ groupField, form, parentIdx }) => {
  const { t } = useTranslation();
  const { surveyMember } = form.getFieldsValue();
  const criteriaType = surveyMember[parentIdx].criteria;
  const operatorType = surveyMember[parentIdx].operator;
  const suffixText = criteriaType === CRITERIA_GROUP.LAST_ATTENDANCE
    || (criteriaType === CRITERIA_GROUP.JOIN_DATE
      && operatorType === OPERATOR_MORE_THAN_CODE)
    ? SUFFIX_TEXT.DAYS
    : '';
  let validateMessage = '';
  if (criteriaType === CRITERIA_GROUP.AGE) {
    validateMessage = t('TS.CREATE_SURVEY.AGE_VALIDATE');
  } else if (criteriaType === CRITERIA_GROUP.JOIN_DATE) {
    validateMessage = t('TS.JOIN_DATE_VALIDATE');
  } else {
    validateMessage = t('TS.CREATE_SURVEY.LAST_ATTENDANCE_VALIDATE');
  }

  return (
    <Form.Item
      name={[groupField.name, 'values']}
      fieldKey={[groupField.key, 'values']}
      rules={[
        {
          pattern: REGEX_POSITIVE_NUMBER_FROM_1_TO_999,
          message: validateMessage,
        },
      ]}
    >
      <InputCustom
        min="0"
        autoComplete="off"
        type="number"
        suffix={suffixText}
      />
    </Form.Item>
  );
};

const DateInput: React.FC<{ groupField: FormListFieldData }> = ({
  groupField,
}) => (
  <Form.Item
    name={[groupField.name, 'values']}
    fieldKey={[groupField.key, 'values']}
  >
    <CustomDatePicker format={DATE_FORMAT} allowClear={false} />
  </Form.Item>
);

const MultipleSelect: React.FC<{
  groupField: FormListFieldData;
  form: FormInstance;
  parentIdx: number;
}> = ({ groupField, form, parentIdx }) => {
  const { t } = useTranslation();
  const { data } = useAppSelector((state) => state.survey);
  const { surveyMember } = form.getFieldsValue();
  const criteriaType = surveyMember[parentIdx].criteria;
  const { clubGroups, memberStatusDtos } = data;
  const allClubGroups = clubGroups.find(
    (club: IClubGroupMasterData) => club.clubGroupName.toLowerCase() === ALL_CLUB_LABEL,
  );

  const options = getOptionsSurveyMember(
    criteriaType,
    clubGroups,
    memberStatusDtos,
    surveyMember,
    parentIdx,
    allClubGroups
  );

  const handleChange = (val: any) => {
    if (val.some((status: string) => status === ALL_STATUS_CODE)) {
      surveyMember[parentIdx] = {
        ...surveyMember[parentIdx],
        values: val.filter((status: string) => status === ALL_STATUS_CODE),
      };
      form.setFieldsValue({ surveyMember });
    }
    if (val.some((status: string) => status === allClubGroups?.clubGroupId.toString())) {
      surveyMember[parentIdx] = {
        ...surveyMember[parentIdx],
        values: val.filter((status: string) => status === allClubGroups?.clubGroupId.toString()),
      };
      form.setFieldsValue({ surveyMember });
    }
    form.setFieldsValue(surveyMember[parentIdx]);
  };
  return (
    <Form.Item
      {...groupField}
      name={[groupField.name, 'values']}
      fieldKey={[groupField.key, 'values']}
      rules={[
        {
          required: true,
          message: t('TS.CREATE_SURVEY.REQUIRED_MESSAGE', {
            key: t('TS.CREATE_SURVEY.VALUE'),
          }),
        },
      ]}
    >
      <CustomSelect mode="multiple" options={options} onChange={handleChange} />
    </Form.Item>
  );
};

const ValueInputType: React.FC<IValueInput> = ({
  typeInput,
  form,
  groupField,
  parentIdx,
}) => (
  <>
    {typeInput === VALUE_TYPE.INPUT && (
      <NumberInput groupField={groupField} form={form} parentIdx={parentIdx} />
    )}
    {typeInput === VALUE_TYPE.SELECT && (
      <MultipleSelect
        form={form}
        parentIdx={parentIdx}
        groupField={groupField}
      />
    )}
    {typeInput === VALUE_TYPE.DATE_TIME && (
      <DateInput groupField={groupField} />
    )}
  </>
);

export default ValueInputType;
