export interface IRequestSurveyFilter {
  operation?: string,
  queryType: string,
  queryKey: string,
  queryValue: string
}

class RequestSurveyFilter implements IRequestSurveyFilter {
  operation?: string;
  queryType: string;
  queryKey: string;
  queryValue: string;

  constructor(operation: string, queryType: string, queryKey: string, queryValue: string) {
    this.operation = operation;
    this.queryType = queryType;
    this.queryKey = queryKey;
    this.queryValue = queryValue;
  }
}

export default RequestSurveyFilter;
