import React from 'react';

import Header from 'components/Header';
import SideBar from 'components/Sidebar';

interface IMain {
  children: React.ReactNode
}

const Main = (props: IMain) => {
  const { children } = props;
  return (
    <div className="app">
      <SideBar />
      <div className="app-body">
        <Header />
        <div className="app-body__content">
          { children }
        </div>
      </div>
    </div>
  );
};

export default Main;
