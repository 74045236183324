import Club from 'models/Club';
import { IClubGroupMasterData } from 'models/IClubMasterData';
import { MemberStatus } from 'models/ISurveyMasterData';
import { SendOutConfig } from 'models/SurveyDetail';
import { findClubNameByClubId } from 'selectors/club';
import { ALL_CLUB_LABEL, ALL_STATUS_CODE, CRITERIA_GROUP, OPERATOR_MORE_THAN_CODE } from './constants';

export const formatSurveySendOutConfigsForApi = (
  configs: { club: string[], percentage: string }[],
  clubs: Club[],
) => {
  let clubIdWithPercentage: { clubId: number, percentage: number }[] = [];
  configs.forEach((config) => {
    config.club.forEach((clubNameConfig: string) => {
      const clubFound = clubs.find((club) => club.name === clubNameConfig);
      if (clubFound) {
        clubIdWithPercentage = [
          ...clubIdWithPercentage, {
            clubId: clubFound.id,
            percentage: Number(config.percentage),
          },
        ];
      }
    });
  });

  return clubIdWithPercentage;
};

export const formatSurveySendOutConfigsFromApi = (
  configs: SendOutConfig[],
  clubs: Club[],
) => {
  let groupConfigByPercentage: {
    club: string[],
    percentage: number
  }[] = [];

  if (configs.length > 0) {
    configs.forEach((config) => {
      const groupConfigByPercentageExist = groupConfigByPercentage
        .find((configGroup) => config.percentage === configGroup.percentage);
      if (groupConfigByPercentageExist) {
        groupConfigByPercentageExist.club = [
          ...groupConfigByPercentageExist.club,
          findClubNameByClubId(clubs, config.clubId),
        ];
        groupConfigByPercentage = groupConfigByPercentage
          .filter(
            (configItem) => configItem.percentage !== groupConfigByPercentageExist.percentage,
          ).concat([groupConfigByPercentageExist]);
      } else {
        groupConfigByPercentage = [
          ...groupConfigByPercentage,
          {
            club: [findClubNameByClubId(clubs, config.clubId)],
            percentage: config.percentage,
          },
        ];
      }
    });
  }
  return groupConfigByPercentage;
};

export const formatSendOutConfigListUsedFromForm = (
  surveySendOutConfigs: { club: string[], percentage: number }[],
) => {
  let initClubListUsed: string[] = [];
  surveySendOutConfigs.forEach(
    (config: { club: string[], percentage: number }) => {
      if (config.club) {
        config.club.forEach((clubItem) => {
          initClubListUsed = [
            ...initClubListUsed,
            clubItem,
          ];
        });
      }
    },
  );
  return initClubListUsed;
};


export const getOptionsSurveyMember = (
  criteriaType: string,
  clubGroups: IClubGroupMasterData[],
  memberStatusDtos: MemberStatus[],
  surveyMember: any,
  parentIdx: number,
  allClubGroups: IClubGroupMasterData | undefined
) => {
  if (
    criteriaType === CRITERIA_GROUP.CLUB ||
    criteriaType === CRITERIA_GROUP.LAST_ATTENDANCE_CLUB
  ) {
    return clubGroups.map((clubGroup: IClubGroupMasterData) => ({
      value: clubGroup.clubGroupId.toString(),
      label: clubGroup.clubGroupName,
      disable:
        clubGroup.clubGroupName.toLowerCase() === ALL_CLUB_LABEL
          ? false
          : surveyMember[parentIdx].values.includes(
            allClubGroups?.clubGroupId.toString()
          ),
    }));
  } else {
    return memberStatusDtos.map((memberStatus: MemberStatus) => ({
      value: memberStatus.statusCode,
      label: memberStatus.memberStatus,
      disable:
        memberStatus.statusCode === ALL_STATUS_CODE
          ? false
          : surveyMember[parentIdx].values.includes(ALL_STATUS_CODE),
    }));
  }
};


export const checkCriteriaType = (item: any, requiredCondition: boolean) => {
  const inputType =
    requiredCondition &&
    (item.criteria === CRITERIA_GROUP.AGE ||
      (item.criteria === CRITERIA_GROUP.JOIN_DATE &&
        item.operator === OPERATOR_MORE_THAN_CODE) ||
      item.criteria === CRITERIA_GROUP.LAST_ATTENDANCE);

  const selectType =
    requiredCondition &&
    [
      CRITERIA_GROUP.CLUB,
      CRITERIA_GROUP.MEMBER_STATUS,
      CRITERIA_GROUP.LAST_ATTENDANCE_CLUB,
    ].includes(item.criteria);

  const dateType =
    requiredCondition &&
    item.criteria === CRITERIA_GROUP.JOIN_DATE &&
    item.operator !== OPERATOR_MORE_THAN_CODE;

  return { inputType, selectType, dateType };
};