import { notification } from 'antd';

export const replaceToHTMLElement = (str = ''): string => str
  .replace(/\[\\n\]/g, '<br/>')
  .replace(/\[\n\]/g, '<br/>')
  .replace(/\[b\]/g, '<b>')
  .replace(/\[\/b\]/g, '</b>');

export default {
  replaceToHTMLElement,
};

export const showNotification = (
  message: string,
  description: string,
  type: string,
  duration = 3,
) => {
  notification.open({
    className: `ant-notification-${type}`,
    message,
    description,
    duration,
  });
};
