import { Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface ICustomSwitch {
  checked: boolean;
  onChange: (value: boolean) => void;
  className?: string;
  disabled?: boolean;
}
const CustomSwitch: React.FC<ICustomSwitch> = ({
  checked,
  onChange,
  className = '',
  disabled = false,
}) => {
  const { t } = useTranslation();
  return (
  <div className="custom-switch">
    <Switch
      checked={checked}
      onChange={onChange}
      className={className}
      disabled={disabled}
      size="default"
    />
    <span>{checked ? t('TS.SURVEY.ACTIVE') : t('TS.SURVEY.INACTIVE')}</span>
  </div>
)
}

export default CustomSwitch;
