import { http } from './common';
import {
  TIME_CONFIG, GET_MINIMUM_TIME, UPDATE_MINIMUM_TIME, SYSTEM_CONFIG,
} from './common/apis';

export const getTimeConfig = async (): Promise<{key: string, value: string}> => {
  const res = await http.get(TIME_CONFIG);
  return res.data;
};

export const updateTimeConfig = async (time: string) => {
  const response = await http.put(TIME_CONFIG, { value: time });
  return response;
};

export const getMinimumTime = async () => {
  const res = await http.get(GET_MINIMUM_TIME);
  return res.data;
};

export const updateMinimumTime = async (minimumTime: string) => {
  const response = await http.put(UPDATE_MINIMUM_TIME, { value: minimumTime });
  return response;
};

export const getSystemConfigs = async () => {
  const response = await http.get(SYSTEM_CONFIG);
  return response.data ? response.data.data : [];
};

export default {
  getTimeConfig,
  updateTimeConfig,
};
