import { MinusCircleOutlined } from '@ant-design/icons';
import { Form, FormInstance } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import CustomSelect from 'components/CustomSelect';
import InputCustom from 'components/InputCustom';
import { REGEX_1_TO_100 } from 'constants/regex';
import { IOption } from 'models/IOption';

import './club-percentage.scss';

interface ClubsPercentageProps {
  onRemove: () => void;
  clubList: IOption[];
  field: { key: number, name: number};
  isEnableRemove: boolean;
  form: FormInstance;
  onUpdateClubList: (value: any) => void;
  onRemoveClubList: (value: any) => void;
}

const ClubsPercentage = (props: ClubsPercentageProps) => {
  const {
    onRemove,
    clubList,
    field,
    isEnableRemove,
    form,
    onUpdateClubList,
    onRemoveClubList,
  } = props;

  const { t } = useTranslation();

  const validatePercentage = () : {
    pattern: RegExp,
    message: string,
  }| {
    required: boolean
  } => {
    const fieldValue = form.getFieldValue('surveySendOutConfigs')[field.name];
    const club = fieldValue ? fieldValue.club : [];

    if (club && club.length > 0) {
      return {
        required: true,
        pattern: REGEX_1_TO_100,
        message: t('TS.CREATE_SURVEY.VALIDATE_INTEGER_PERCENTAGE'),
      };
    }

    return {
      required: false,
    };
  };

  const handleOnchange = (value: string) => {
    const surveySendOutConfigs = form.getFieldValue('surveySendOutConfigs');
    surveySendOutConfigs[field.name] = {
      ...surveySendOutConfigs[field.name],
      percentage: value,
    };
    form.setFieldsValue({ surveySendOutConfigs });
  };

  return (
    <div className="club-percentage">
      <div className="club-percentage__content">
        <div className="club-percentage__content-club">
          <Form.Item
            name={[field.name, 'club']}
            fieldKey={[field.key, 'club']}
            initialValue={[]}
          >
            <CustomSelect
              options={clubList}
              onSelect={onUpdateClubList}
              onDeselect={onRemoveClubList}
              mode="multiple"
            />
          </Form.Item>
        </div>
        <div className={
          classNames(
            'club-percentage__content-percentage',
            {
              'club-percentage__content-has-remove-item': isEnableRemove,
            },
          )
          }
        >
          <Form.Item
            name={[field.name, 'percentage']}
            fieldKey={[field.key, 'percentage']}
            initialValue={0}
            rules={
              [
                { ...validatePercentage() },
              ]
            }
          >
            <InputCustom
              type="number"
              suffix="%"
              min="0"
              max="100"
              defaultValue="0"
              onChangeInput={handleOnchange}
            />
          </Form.Item>
          {isEnableRemove && (
          <MinusCircleOutlined
            onClick={onRemove}
            className="club-percentage__remove-item"
          />
          )}
        </div>
      </div>
    </div>
  );
};

export default ClubsPercentage;
