import { Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import CustomButton from 'components/CustomButton';
import { signOutRedirect } from 'store/profile';
import { AppDispatch } from 'store';

const NotAllowed = () => {
 const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  return (
    <Result
      status="404"
      title="404"
      subTitle={t('TS.NOT.ACCESS')}
      extra={(
        <CustomButton
          label={t('TS.LOGOUT.TITLE')}
          onClick={() => dispatch(signOutRedirect())}
        />
      )}
    />
  );
};

export default NotAllowed;
