import { ITag } from './Tag';

import { CONTACT_TYPE_CALL } from 'constants/response';
import { IClub } from 'models/Club';
import { hasOnlySpace } from 'utils/validate';

export interface IResponse {
  club: IClub;
  createdDate: string;
  currentDate: string;
  feedbackContent: string;
  fullName: string;
  memberSurveyEventId: number;
  membershipNumber: string;
  period: {
    key: string;
    value: number;
  };
  point?: number;
  responseId: number;
  tags: ITag[];
  attemptedTime: number;
  phoneNumber: string;
  email: string;
  assigneeId?: string;
  comment?: string;
  status?: string;
  surveySystemEmail?: string;
  contactType?: string;
  noteTotal: number;
  responseEdited?: {
    point: number;
    feedbackContent: string;
    club: {
      id: number;
      name: string;
    };
  };
  pointDisplay?: number;
  feedbackContentDisplay: string;
  clubDisplay: IClub;
}
class Response implements IResponse {
  club: IClub;
  createdDate: string;
  currentDate: string;
  feedbackContent: string;
  fullName: string;
  memberSurveyEventId: number;
  membershipNumber: string;
  period: { key: string; value: number };
  point?: number;
  responseId: number;
  tags: ITag[];
  phoneNumber: string;
  email: string;
  attemptedTime: number;
  assigneeId?: string;
  comment?: string;
  status?: string;
  surveySystemEmail?: string;
  contactType: string;
  noteTotal: number;
  responseEdited?: {
    point: number;
    feedbackContent: string;
    club: {
      id: number;
      name: string;
    };
  };
  pointDisplay: number | undefined;
  feedbackContentDisplay: string;
  clubDisplay: IClub;

  constructor(response: IResponse) {
    this.club = response.club;
    this.createdDate = response.createdDate;
    this.currentDate = response.currentDate;
    this.feedbackContent = response.feedbackContent;
    this.fullName = response.fullName;
    this.memberSurveyEventId = response.memberSurveyEventId;
    this.membershipNumber = response.membershipNumber;
    this.period = response.period;
    this.point = response.point ? response.point : 0;
    this.responseId = response.responseId;
    this.tags = response.tags;
    this.attemptedTime = response.attemptedTime ? response.attemptedTime : 0;
    this.phoneNumber = response.phoneNumber;
    this.email = response.email;
    this.assigneeId = response.assigneeId;
    this.comment = response.comment ?? '';
    this.status = response.status ?? '';
    this.surveySystemEmail = response.surveySystemEmail
      ? response.surveySystemEmail
      : '';
    this.contactType = response.contactType
      ? response.contactType
      : CONTACT_TYPE_CALL;
    this.noteTotal = response.noteTotal;
    this.responseEdited = response.responseEdited ?? undefined;
    this.pointDisplay = response.pointDisplay ? response.pointDisplay : 0;
    this.feedbackContentDisplay = response.feedbackContentDisplay;
    this.clubDisplay = response.clubDisplay;
  }

  formatApiForLogAttempt(
    isSuccess: boolean,
    contactType: string,
  ): {
    isSuccess: boolean;
    contactType: string;
    contactValue: string;
    content: string;
  } {
    let contactValue = '';
    if (contactType === CONTACT_TYPE_CALL) {
      contactValue = this.phoneNumber;
    }

    return {
      isSuccess,
      contactType,
      contactValue,
      content: this.comment ? this.comment : '',
    };
  }

  canSubmitLogAttempt = (): boolean => {
    if (!this.comment) {
      return false;
    }
    return !hasOnlySpace(this.comment);
  };

  static formatFromAPI = (data: IResponse) => {
    if (data.responseEdited) {
      const editedResponse = data.responseEdited;
      return new Response({
        ...data,
        pointDisplay: editedResponse.point,
        clubDisplay: editedResponse.club,
        feedbackContentDisplay: editedResponse.feedbackContent,
      });
    }
    return new Response({
      ...data,
      pointDisplay: data.point,
      clubDisplay: data.club,
      feedbackContentDisplay: data.feedbackContent,
    });
  };

  static getOriginalData = (data: IResponse) => new Response({
    ...data,
    clubDisplay: data.club,
    feedbackContentDisplay: data.feedbackContent,
    pointDisplay: data.point,
  });
}

export default Response;
