import {
  DeleteOutlined,
  EditOutlined,
  FileDoneOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import EditResponseModal from './EditResponseModal';
import NoteModal from './NoteModal';

import ConfirmModal from 'components/ConfirmModal';
import CustomButton from 'components/CustomButton';
import CustomSelect from 'components/CustomSelect';
import {
  AssignedIcon,
  CalendarIcon,
  DownArrow,
  HistoryIcon,
  LocationIcon,
} from 'components/Icons';
import Point from 'components/Point';
import ViewMore from 'components/ViewMore';
import { WHITE } from 'constants/button';
import { DELETE_RESPONSE, EDIT_RESPONSE } from 'constants/roles';
import Operator from 'models/Operator';
import Response, { IResponse } from 'models/Response';
import { deleteResponseData } from 'services/todo';
import { useAppSelector } from 'store/hooks';
import { NOTIFICATION_TYPE } from 'utils/constants';
import { showNotification } from 'utils/helpers';
import { formatPoint } from 'utils/stringHelper';
import { customMomentFrom } from 'utils/timeHelper';

interface ResponseHeaderProps {
  response: IResponse;
  operators: Operator[];
  isDisableAssignOperator?: boolean;
  onClickHistory: (response: IResponse, event?: Event) => void;
  assignOperator: (assigneeId: string, feedbackId: string) => void;
  responseIdList: string[];
  fetchToDoList: () => void;
}

const ResponseHeader: React.FC<ResponseHeaderProps> = (
  props: ResponseHeaderProps,
) => {
  const {
    response,
    operators,
    isDisableAssignOperator,
    onClickHistory,
    assignOperator,
    responseIdList,
    fetchToDoList,
  } = props;

  const [openNoteModal, setOpenNoteModal] = useState<boolean>(false);
  const [openEditResponseModal, setOpenEditResponseModal] = useState<boolean>(false);
  const [openConfirmResponse, setOpenOpenConfirmResponse] = useState<boolean>(false);
  const { permissions } = useAppSelector((state) => state.role);

  const [responseModel, setResponse] = useState<IResponse>(response);
  const [isShowingEdit, setIsShowingEdit] = useState<boolean>(true);
  const { t } = useTranslation();
  const FEEDBACK_STATUS = {
    NEW: 'NE',
  };

  const stopPropagation = (event: any) => {
    event.stopPropagation();
  };

  const handleAssignOperator = (value: string) => {
    assignOperator(value, responseModel.responseId.toString());
  };

  const getStatus = useMemo(() => {
    const { point } = response;
    if (!point) {
      return 'negative';
    }

    if (point >= 9 && point <= 10) {
      return 'positive';
    }
    if (point >= 7 && point <= 8) {
      return 'neutral';
    }
  }, [response]);

  const handleShowNotes = (event?: MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }
    setOpenNoteModal(!openNoteModal);
  };

  const onEditResponse = (event?: any) => {
    if (event) {
      event.stopPropagation();
    }
    onHandleOpenEditResponse();
  };

  const onDeleteResponse = (event?: any) => {
    if (event) {
      event.stopPropagation();
    }
    return onHandleConfirmResponse();
  };

  const rotate = useMemo(() => {
    if (
      responseIdList.some(
        (responseId) => Number(responseId) === response.responseId,
      )
    ) {
      return 90;
    }
    return 0;
  }, [responseIdList, response]);

  const handlePreventCollapse = (event: any) => {
    event.stopPropagation();
    return null;
  };

  const onHandleOpenEditResponse = () => {
    setOpenEditResponseModal(!openEditResponseModal);
  };

  const onHandleConfirmResponse = () => {
    setOpenOpenConfirmResponse(!openConfirmResponse);
  };

  const deleteResponse = async () => {
    try {
      const res = await deleteResponseData(response.responseId);
      if (res.isSuccess) {
        showNotification(
          t('TS.SUCCESS'),
          t(res.message),
          NOTIFICATION_TYPE.SUCCESS,
        );
      }
    } catch (error) {
      showNotification(
        t('TS.ERROR'),
        t('TS_NOTIFICATION_SERVICES_NOTIFICATION'),
        NOTIFICATION_TYPE.ERROR,
      );
    } finally {
      fetchToDoList();
    }
  };

  const handleShowData = (e: any) => {
    handlePreventCollapse(e);
    if (isShowingEdit) {
      setResponse(Response.getOriginalData(responseModel));
      setIsShowingEdit(false);
    } else {
      const editedResponse = responseModel.responseEdited;
      if (editedResponse) {
        setResponse(Response.formatFromAPI(responseModel));
      }
      setIsShowingEdit(true);
    }
  };

  return (
    <>
      <Row className="response-collapse__header">
        <Col span={1}>
          <div className="header__icon">
            <RightOutlined
              className="response-collapse__icon"
              frameBorder={0}
              rotate={rotate}
            />
            {permissions.includes(EDIT_RESPONSE) && (
              <EditOutlined
                onClick={onEditResponse}
                className="response-collapse__icon"
              />
            )}
            {permissions.includes(DELETE_RESPONSE) && (
              <DeleteOutlined
                onClick={onDeleteResponse}
                className="response-collapse__icon"
              />
            )}
          </div>
        </Col>
        <Col span={2}>
          <div className="header__point">
            <Point
              status={getStatus}
              number={formatPoint(responseModel.pointDisplay ?? 0)}
            />
          </div>
        </Col>
        <Col span={4}>
          <div className="header__info">
            <span className="header__info-name">{responseModel.fullName}</span>
            <span className="header__info-id">
              <span>{t('ALL_RESPONSES_HEADER_ID')}</span>
              {responseModel.membershipNumber}
            </span>
            <span className="header__info-address">
              <LocationIcon />
              {responseModel.clubDisplay.name}
            </span>
          </div>
        </Col>
        <Col span={11}>
          <div className="header__content">
            <ViewMore
              text={
                responseModel.feedbackContentDisplay
                || t('ALL_RESPONSES_HEADER_NO_FEEDBACK')
              }
              numberCharacters={400}
            />
          </div>
        </Col>
        <Col span={5}>
          <div className="header-others">
            <div className="header-others__item">
              <CalendarIcon />
              <span>
                {customMomentFrom(
                  responseModel.currentDate,
                  responseModel.createdDate,
                  t,
                )}
              </span>
            </div>
            <div className="header-others__item">
              <HistoryIcon />
              <CustomButton
                label={t('ALL_RESPONSES_HEADER_HISTORY')}
                backgroundColor={WHITE}
                onClick={(event?: Event) => onClickHistory(responseModel, event)}
              />
            </div>
            <div className="header-others__item">
              <AssignedIcon />
              <CustomSelect
                disabled={
                  Boolean(isDisableAssignOperator)
                  || Boolean(responseModel.status !== FEEDBACK_STATUS.NEW)
                }
                placeHolder={t('TS.ASSIGNED.NONE')}
                options={operators.map((operator) => ({
                  label: operator.fullName,
                  value: operator.operatorId.toString(),
                }))}
                defaultValue={responseModel.assigneeId}
                suffixIcon={<DownArrow />}
                onClick={stopPropagation}
                onChange={handleAssignOperator}
              />
            </div>
            <div className="header-others__item">
              <FileDoneOutlined color="black" />
              <CustomButton
                label={t('TS.NOTE', {
                  numberNote: responseModel.noteTotal,
                })}
                backgroundColor={WHITE}
                onClick={handleShowNotes}
              />
            </div>
          </div>
        </Col>
      </Row>
      {responseModel.responseEdited && (
      <Row>
        <Col span={24} className="response-collapse__header-toggle">
          <span>{t('TS.EDITED_RESPONSE')}</span>
          <span onClick={handleShowData}>
            {isShowingEdit
              ? t('TS.VIEW_ORIGINAL')
              : t('TS.VIEW_EDITED_RESPONSE')}
          </span>
        </Col>
      </Row>
      )}
      <div onClick={handlePreventCollapse}>
        {openNoteModal && (
          <NoteModal
            onCloseModal={handleShowNotes}
            response={responseModel}
            updateNoteTotal={setResponse}
          />
        )}
        {openEditResponseModal && (
          <EditResponseModal
            setResponseModel={setResponse}
            response={responseModel}
            onCloseModal={onHandleOpenEditResponse}
          />
        )}
        {openConfirmResponse && (
          <ConfirmModal
            title={t('TS.REMOVE.RESPONSE')}
            content={t('TS.REMOVE.RESPONSE.CONTENT')}
            okLabel={t('TS.OK')}
            onCloseModal={onHandleConfirmResponse}
            onConfirmModal={deleteResponse}
          />
        )}
      </div>
    </>
  );
};

ResponseHeader.defaultProps = {
  isDisableAssignOperator: false,
};

export default ResponseHeader;
