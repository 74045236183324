import React from 'react';

import Loading from 'components/Loading';

interface WithLoadingProps {
  isLoading: boolean,
  restProps?: any
}

const withLoading = (
  WrappedComponent: React.FC<{children: React.ReactNode}>,
) => (props: WithLoadingProps) => {
  const { isLoading, restProps } = props;

  return isLoading ? (
    <Loading loadingMode="component" />
  ) : (
    React.createElement(WrappedComponent, { ...restProps })
  );
};

export default withLoading;
