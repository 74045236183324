import { Select } from 'antd';
import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';

import { DropDownIcon } from 'components/Icons';

const { Option } = Select;

interface ICustomSelect {
  label?: string;
  options: {
    label: string;
    value: string | number;
  }[];
  placeHolder?: React.ReactNode;
  style?: React.CSSProperties | undefined;
  suffixIcon?: React.ReactNode;
  dropdownClassName?: string;
  defaultValue?: number | string | [];
  value?: string;
  showSearch?: boolean;
  mode?: 'multiple' | 'tags';
  disabled?: boolean;
  onSelect?: (value: string | number) => void;
  onChange?: (value: string) => void;
  onDeselect?: (value: string) => void;
  onClick?: MouseEventHandler<HTMLDivElement>;
  wrapperClassName?: string;
}

export interface ICustomSelectOption {
  label: string;
  value: string | number;
  disable?: boolean;
}

const CustomSelect: React.FC<ICustomSelect> = ({
  label,
  options,
  placeHolder = '',
  style,
  suffixIcon = <DropDownIcon />,
  dropdownClassName = 'custom-select__dropdown',
  wrapperClassName = '',
  mode,
  defaultValue,
  value,
  showSearch = true,
  onSelect,
  onChange,
  onDeselect,
  onClick,
  disabled,
}) => {
  const optionMapping = (option: ICustomSelectOption) => (
    <Option
      value={option.value}
      key={option.value}
      disabled={option.disable}
    >
      {option.label}
    </Option>
  );

  return (
    <div className={`custom-select ${wrapperClassName}`}>
      {label && <span>{label}</span>}
      <Select
        showArrow
        showSearch={showSearch}
        style={style}
        placeholder={placeHolder}
        optionFilterProp="children"
        suffixIcon={suffixIcon}
        dropdownClassName={dropdownClassName}
        defaultValue={defaultValue ? defaultValue.toString() : undefined}
        value={value}
        mode={mode}
        disabled={disabled}
        onSelect={onSelect}
        onChange={onChange}
        onClick={onClick}
        onDeselect={onDeselect}
        className={classNames({
          'custom-select__overflow--initial': !mode,
        })}
        options={options}
        filterOption={(input, option) => (option ? option
          .label.toLowerCase().includes(input.toLowerCase()) : false)}
      >
        {options.map(optionMapping)}
      </Select>
    </div>
  );
};

export default CustomSelect;
