import { Popover, Table } from 'antd';

import FieldStatus from './Fields/Status';

import CustomButton from 'components/CustomButton';
import CustomPagination from 'components/CustomPagination';
import {
  PAGE_DEFAULT_INDEX,
  PAGE_DEFAULT_SIZE,
  TOTAL_PAGE,
  TOTAL_RECORDS,
} from 'constants/pagination';
import { IResponsePaging } from 'models/IResponsePaging';
import { Column } from 'models/utility/Column';

interface ICustomTable <Model>{
  dataList: Model [],
  columns: Column[],
  paging: IResponsePaging,
  loading?: boolean,
  onChangePagination?: (page: { pageIndex: number, pageSize: number }) => void,
  onChangeTable: (pagination: any, filters: any, sorter: any) => void
  viewDetail?: (id: string) => void;
}

const CustomTable = (props: ICustomTable<object>) => {
  const {
    dataList,
    columns,
    paging,
    loading = false,
    onChangePagination = () => null,
    onChangeTable = () => null,
    viewDetail = () => null,
  } = props;

  const render = (value?: any, column?: Column, index?: number) => {
    if (column) {
      if (column.dataIndex === 'status') {
        return <FieldStatus status={value} />;
      } if (column.dataIndex === 'creator') {
        return <strong>{ value }</strong>;
      } if (column.dataIndex === 'id') {
        return (
          <CustomButton
            label="Details"
            onClick={() => viewDetail(value)}
            className="column-view"
          />
        );
      } if (column.dataIndex === 'surveyName') {
        return (
          <Popover
            placement="topLeft"
            content={<div className="popover-content">{value}</div>}
          >
            <span className="popover-table">
              {value}
            </span>
          </Popover>
        );
      }
    }

    return value;
  };

  const formatColumn = () => columns.map((column) => ({
    title: column.title,
    dataIndex: column.dataIndex,
    render: (value: any) => render(value, column),
    sorter: column.sorter,
    width: column.width,
    ellipsis: column.ellipsis,
  }));

  return (
    <div className="custom-table">
      <Table
        columns={formatColumn()}
        dataSource={dataList}
        pagination={false}
        loading={loading}
        onChange={onChangeTable}
      />
      <CustomPagination
        paging={{
          pageIndex: paging.pageIndex ? paging.pageIndex : PAGE_DEFAULT_INDEX,
          pageSize: paging.pageSize ? paging.pageSize : PAGE_DEFAULT_SIZE,
          totalPages: paging.totalPages ? paging.totalPages : TOTAL_PAGE,
          totalRecords: typeof paging.totalRecords !== 'undefined' ? paging.totalRecords : TOTAL_RECORDS,
        }}
        setPaging={onChangePagination}
      />
    </div>
  );
};

export default CustomTable;
