import {
  Input,
} from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import CustomButton from 'components/CustomButton';
import InputCustom from 'components/InputCustom';

interface ICustomInput {
  onChangeInput: (value: string) => void,
  value: string,
  defaultValue?: string,
  label?: string,
  iconAfter?: ReactNode
  disabled?: boolean,
  buttonText?: string,
  onSubmitButton?: () => void,
  maxLength?: number,
  placeholder?: string,
  iconBefore?: ReactNode,
  autoFocus?: boolean,
  onKeyDown?: (event: any) => void,
  textBoxClass?: string,
}

const CustomInputGroup = (props: ICustomInput) => {
  const {
    onChangeInput,
    value,
    defaultValue,
    disabled = false,
    label = '',
    iconAfter = null,
    buttonText = '',
    onSubmitButton,
    maxLength,
    placeholder = '',
    iconBefore = null,
    autoFocus = false,
    onKeyDown,
    textBoxClass,
  } = props;

  const { t } = useTranslation();

  return (
    <Input.Group compact className="custom-input-group">
      {label && (
        <span className="custom-input-group__label">
          {label}
        </span>
      )}
      <div className="custom-input-group__content">
        <InputCustom
          onChangeInput={onChangeInput}
          disabled={disabled}
          value={value}
          defaultValue={defaultValue}
          suffix={iconAfter}
          maxLength={maxLength}
          onPressEnter={onSubmitButton}
          placeholder={placeholder}
          prefix={iconBefore}
          autoFocus={autoFocus}
          handleKeyDown={onKeyDown}
          className={textBoxClass}
        />
        {
          buttonText
          && (
            <CustomButton
              label={buttonText}
              disabled={buttonText === t('TS.SEARCH') ? false : (!value || !value.trim() || value.length > 30)}
              onClick={onSubmitButton || (() => null)}
            />
          )
        }
      </div>
      {
        value.length > 30 && buttonText !== t('TS.SEARCH') && (
        <div className="custom-input-group__error">{t('TS.CREATE_EDIT_TAG_GROUP_ERROR')}</div>
        )
      }
    </Input.Group>
  );
};

export default CustomInputGroup;
