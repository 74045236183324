import { Button, DatePicker } from 'antd';
import moment from 'moment';
import React, {
  MouseEventHandler,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import CustomSelect, { ICustomSelectOption } from 'components/CustomSelect';
import DropDownButton from 'components/DropdownButton';
import { CalendarIcon, ResetIcon, SaveIcon } from 'components/Icons';
import { DATE_FORMAT } from 'constants/date';
import { IClub } from 'models/Club';
import { ISurvey } from 'models/ISurvey';
import { defaultFilter } from 'pages/AllResponses/defaultValue';
import { getSurveys } from 'services/response';
import { getClubsAsync } from 'store/club';
import { useAppSelector } from 'store/hooks';
import { AppDispatch } from 'store';
import Operator from 'models/Operator';

const { RangePicker } = DatePicker;

interface IFilterContent {
  onClick: () => void | MouseEventHandler<HTMLElement>;
  setSaveFilter: (value: SetStateAction<any>) => void;
  saveFilter: any;
  isAllResponseFilter: boolean;
}

const FilterContent: React.FC<IFilterContent> = ({
  onClick,
  setSaveFilter,
  saveFilter,
  isAllResponseFilter,
}) => {
  const { t } = useTranslation();
  const [surveys, setSurveys] = useState<ICustomSelectOption[]>([]);
  const { operators } = useAppSelector((state: any) => state.operator);
  const [filter, setFilter] = useState(saveFilter);
  const { clubs } = useAppSelector((state: any) => state.club);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (clubs.length === 0) {
      dispatch(getClubsAsync());
    }
  }, [dispatch, clubs]);

  useEffect(() => {
    (async () => {
      try {
        const data = await getSurveys();
        setSurveys([
          ...data.surveyEvents.map((item: ISurvey) => ({
            label: item.name,
            value: item.id,
          })),
        ]);
        return data;
      } catch (err) {
        return err;
      }
    })();
  }, []);

  const handleDeselectCommon = (field: string, value: string) => {
    setFilter({
      ...filter,
      [field]: filter[field].filter((item: string) => item !== value),
    });
  };

  const handleSelectCommon = (field: string, value: string) => {
    if (value === 'all') {
      setFilter({ ...filter, [field]: [value] });
    } else {
      setFilter({
        ...filter,
        [field]: [...filter[field], value].filter(
          (item: string) => item !== 'all',
        ),
      });
    }
  };

  const selectConfigs: { [key: string]: any } = useMemo(
    () => ({
      responses: {
        label: t('ALL_RESPONSES_FILTER_RESPONSES'),
        placeHolder: t('ALL_RESPONSES_FILTER_RESPONSES_ALL'),
        options: [
          { label: t('ALL_RESPONSES_FILTER_RESPONSES_POSITIVE'), value: '1' },
          { label: t('ALL_RESPONSES_FILTER_RESPONSES_NEUTRAL'), value: '2' },
          { label: t('ALL_RESPONSES_FILTER_RESPONSES_NEGATIVE'), value: '3' },
        ],
        defaultValue: filter.responses,
        onSelect: (value: string) => {
          handleSelectCommon('responses', value);
        },
        onDeselect: (value: string) => {
          handleDeselectCommon('responses', value);
        },
      },
      assignedOperators: {
        label: t('ALL_RESPONSES_FILTER_ASSIGNED_OPERATOR'),
        placeHolder: t('ALL_RESPONSES_FILTER_ASSIGNED_OPERATOR_ALL'),
        options: operators.map((operator: Operator) => ({
          label: operator.fullName,
          value: operator.operatorId,
        })),
        defaultValue: filter.assignedOperators,
        onSelect: (value: string) => {
          handleSelectCommon('assignedOperators', value);
        },
        onDeselect: (value: string) => {
          handleDeselectCommon('assignedOperators', value);
        },
      },
      membershipMonth: {
        label: t('ALL_RESPONSES_FILTER_MEMBERSHIP_MONTH'),
        placeHolder: t('ALL_RESPONSES_FILTER_MEMBERSHIP_MONTH_ALL'),
        options: [
          { label: t('ALL_RESPONSES_FILTER_MEMBERSHIP_MONTH_0_3'), value: '1' },
          { label: t('ALL_RESPONSES_FILTER_MEMBERSHIP_MONTH_3_6'), value: '2' },
          {
            label: t('ALL_RESPONSES_FILTER_MEMBERSHIP_MONTH_6_1Y'),
            value: '3',
          },
          {
            label: t('ALL_RESPONSES_FILTER_MEMBERSHIP_MONTH_1Y_3Y'),
            value: '4',
          },
          {
            label: t('ALL_RESPONSES_FILTER_MEMBERSHIP_MONTH_MORE_3Y'),
            value: '5',
          },
        ],
        defaultValue: filter.membershipMonth,
        onSelect: (value: string) => {
          handleSelectCommon('membershipMonth', value);
        },
        onDeselect: (value: string) => {
          handleDeselectCommon('membershipMonth', value);
        },
      },
      club: {
        label: t('ALL_RESPONSES_FILTER_CLUB'),
        placeHolder: t('ALL_RESPONSES_FILTER_CLUB_ALL'),
        options: clubs.map((item: IClub) => ({
          label: item.name,
          value: item.id,
        })),
        defaultValue: filter.club,
        onSelect: (value: string) => {
          handleSelectCommon('club', value);
        },
        onDeselect: (value: string) => {
          handleDeselectCommon('club', value);
        },
      },
      surveyName: {
        label: t('ALL_RESPONSES_FILTER_SURVEY_NAME'),
        placeHolder: t('ALL_RESPONSES_FILTER_SURVEY_NAME_ALL'),
        options: surveys,
        defaultValue: filter.surveyName,
        onSelect: (value: string) => {
          handleSelectCommon('surveyName', value);
        },
        onDeselect: (value: string) => {
          handleDeselectCommon('surveyName', value);
        },
      },
    }),
    [filter, clubs, surveys, operators],
  );

  const selectMaps = Object.keys(selectConfigs);

  const handleSave = () => {
    onClick();
    setSaveFilter(filter);
  };

  const handleReset = () => {
    if (isAllResponseFilter) {
      setFilter({
        ...defaultFilter,
        datePicker: [
          moment().subtract(1, 'months').format(DATE_FORMAT),
          moment().format(DATE_FORMAT),
        ],
      });
    } else {
      setFilter({
        ...defaultFilter,
      });
    }
  };

  const handleChangeRangeDate = (values: any, context?: string[]) => {
    setFilter({
      ...filter,
      datePicker: values ? context : [],
    });
  };
  return (
    <div className="filter-content">
      <div className="filter-content__wrapper">
        <div className="filter-content__actions">
          <Button type="link" icon={<ResetIcon />} onClick={handleReset}>
            {t('ALL_RESPONSES_FILTER_RESET')}
          </Button>
          <Button type="link" icon={<SaveIcon />} onClick={handleSave}>
            {t('ALL_RESPONSES_FILTER_SAVE')}
          </Button>
        </div>
        <div className="filter-content__filters">
          {filter
            && selectMaps.map((name: any) => (
              <div className="filter-content__filters-item" key={name}>
                <CustomSelect
                  label={selectConfigs[name].label}
                  options={selectConfigs[name].options}
                  placeHolder={selectConfigs[name].placeHolder}
                  onSelect={selectConfigs[name].onSelect}
                  value={selectConfigs[name].defaultValue}
                  onDeselect={selectConfigs[name].onDeselect}
                  mode="multiple"
                />
              </div>
            ))}

          <div className="filter-content__filters-item">
            <div className="date-range">
              <span>{t('ALL_RESPONSES_FILTER_SURVEY_DATE')}</span>
              <RangePicker
                onChange={handleChangeRangeDate}
                style={{ width: 290 }}
                suffixIcon={<CalendarIcon />}
                defaultValue={filter.datePicker.map((item: string) => moment(item))}
                value={filter.datePicker.map((item: string) => moment(item))}
                format={DATE_FORMAT}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface IResponseFilter {
  saveFilter: any;
  setSaveFilter: (value: SetStateAction<any>) => void;
  isAllResponseFilter?: boolean;
}

const ResponseFilter: React.FC<IResponseFilter> = ({
  saveFilter,
  setSaveFilter,
  isAllResponseFilter = false,
}) => {
  const { t } = useTranslation();

  const [showFilter, setShowFilter] = useState(false);
  const handleClick = () => {
    setShowFilter(!showFilter);
  };

  return (
    <div className="filter-wrapper">
      <DropDownButton
        onClick={handleClick}
        label={t('ALL_RESPONSES_FILTER_FILTER')}
        currentState={showFilter}
      />
      {showFilter && (
        <FilterContent
          onClick={handleClick}
          saveFilter={saveFilter}
          setSaveFilter={setSaveFilter}
          isAllResponseFilter={isAllResponseFilter}
        />
      )}
    </div>
  );
};

export default ResponseFilter;
