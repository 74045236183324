import { Button, Tooltip } from 'antd';
import classNames from 'classnames';
import React from 'react';

import { CloseIcon, EditIcon } from 'components/Icons';
import {
  TAG_SENTIMENT_NEGATIVE,
  TAG_SENTIMENT_NEUTRAL,
  TAG_SENTIMENT_POSITIVE,
} from 'constants/tagGroup';
import Tag from 'models/Tag';

interface ITag {
  tag: Tag;
  isSelected?: boolean;
  onClick: () => void;
  onSelect?: () => void;
  showDelete?: boolean;
  showEdit?: boolean;
  icon?: React.ReactNode;
}

const TagItem = (props: ITag) => {
  const {
    tag,
    isSelected,
    onClick,
    onSelect,
    showDelete = true,
    showEdit = true,
    icon,
  } = props;

  const handleSelect = () => {
    if (onSelect) return onSelect();
    return onClick();
  };

  return (
    <Tooltip title={tag.tagName}>
      <Button
        className={classNames('tag ', {
          'tag--selected': isSelected,
          'tag--negative': tag.tagSentiment === TAG_SENTIMENT_NEGATIVE,
          'tag--neutral': tag.tagSentiment === TAG_SENTIMENT_NEUTRAL,
          'tag--positive': tag.tagSentiment === TAG_SENTIMENT_POSITIVE,
        })}
        onClick={handleSelect}
      >

        {showDelete && (
          <Button
            className="tag__delete"
            icon={<CloseIcon />}
            onClick={onClick}
          />
        )}
        <div className="tag__name">
          {icon}
          {tag.tagName}
        </div>
        {showEdit && <EditIcon />}
      </Button>
    </Tooltip>
  );
};

export default TagItem;
