import { Radio, RadioChangeEvent } from 'antd';
import classNames from 'classnames';
import {
  useState, useEffect, useCallback,
} from 'react';

import CustomInputGroup from 'components/CustomInputGroup';
import CustomModal from 'components/CustomModal';
import { TagGroupIcon } from 'components/Icons';
import TagItem from 'components/TagItem';
import TAG_SENTIMENT from 'constants/tag';
import { TAG_SENTIMENT_NEGATIVE, TAG_SENTIMENT_NEUTRAL, TAG_SENTIMENT_POSITIVE } from 'constants/tagGroup';
import { ISearchSurveyTagResponse } from 'models/ISearchSurveyTagResponse';
import Tag from 'models/Tag';
import { processSurveyTag, searchSurveyTags } from 'services/tag';
import { NOTIFICATION_TYPE } from 'utils/constants';
import { debounce } from 'utils/debounce';
import { showNotification } from 'utils/helpers';
import { useTranslation } from 'react-i18next';

interface ITagModal {
  title: string,
  onCloseModal: () => void,
  placeholder?: string,
  buttonText?: string,
  feedbackId: number,
  editTagId?: number,
  focusedAddTag?: boolean,
  tags: Tag[]
}

const TagModal = (props: ITagModal) => {
  const { t } = useTranslation();
  const {
    title,
    onCloseModal,
    placeholder = 'Add a tag',
    buttonText = t('TS.ADD'),
    feedbackId,
    editTagId,
    focusedAddTag = true,
    tags = [],
  } = props;

  const [searchTag, setSearchTag] = useState<ISearchSurveyTagResponse>();
  const [selectedTag, setSelectedTag] = useState<Tag | null | undefined>(
    editTagId
      ? tags.find((tag) => tag.surveyAnswerTagId === editTagId)
      : null,
  );
  const [listSearchTags, setListSearchTags] = useState<ISearchSurveyTagResponse[]>([]);
  const [listTags, setListTags] = useState<Tag[]>(tags);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedSentiment, setSelectedSentiment] = useState<number>(
    selectedTag?.tagSentiment ?? TAG_SENTIMENT.positive.value,
  );
  const [active, setActive] = useState(0);

  const onSearchTag = useCallback(debounce((data: string) => {
    if (data.length > 3) {
      (async () => {
        const result = await searchSurveyTags({ tagName: data });
        setListSearchTags(result);
        if (!result || result.length === 0) {
          setSearchTag(undefined);
        }
      })();
    } else {
      setListSearchTags([]);
    }
  }, 300), []);

  const onChangeInput = (data: string) => {
    setSearchText(data);
    setSearchTag(undefined);
  };

  const keyDownHandler = (event: any) => {
    if (event.keyCode === 38 && active > 0) {
      setActive(active - 1);
      event.preventDefault();
    } else if (event.keyCode === 40 && active < listSearchTags.length - 1) {
      setActive(active + 1);
      event.preventDefault();
    }
  };

  const onAddTag = () => {
    const payload = {
      tagId: searchTag?.tagId ?? 0,
      tagName: searchTag?.tagName ?? searchText ?? '',
      currentTagSentiment: 0,
      tagSentiment: selectedSentiment,
      feedbackId,
      actionType: 1,
    };
    (async () => {
      const result = await processSurveyTag(payload);
      if (!result.isSuccess) {
        showNotification('Error', result.message, NOTIFICATION_TYPE.ERROR);
      } else {
        setSearchTag(undefined);
        setSearchText('');
        setListSearchTags([]);
        setListTags(result.data.map((tag) => new Tag(tag)));
      }
    })();
  };
  const onUpdateSentiment = async (event : RadioChangeEvent) => {
    setSelectedSentiment(event.target.value);
    if (selectedTag) {
      try {
        const payload = {
          tagId: selectedTag.surveyAnswerTagId ?? 0,
          tagName: selectedTag.tagName ?? '',
          currentTagSentiment: selectedTag.tagSentiment ?? 0,
          tagSentiment: event.target.value,
          feedbackId,
          actionType: 2,
        };
        const result = await processSurveyTag(payload);
        if (!result.isSuccess) {
          showNotification('Error', result.message, NOTIFICATION_TYPE.ERROR);
        } else {
          setSelectedTag(new Tag({ ...selectedTag, tagSentiment: event.target.value }));
          setListTags(result.data.map((tag) => new Tag(tag)));
        }
      } catch (err) {
        showNotification(
          t('TS.ERROR'),
          t('TS_NOTIFICATION_SERVICES_NOTIFICATION'),
          NOTIFICATION_TYPE.ERROR,
        );
      }
    }
  };
  const onRemoveTag = (tag: Tag) => {
    if (tag) {
      const payload = {
        tagId: tag?.surveyAnswerTagId ?? 0,
        tagName: tag?.tagName ?? '',
        currentTagSentiment: tag?.tagSentiment ?? TAG_SENTIMENT.positive.value,
        tagSentiment: 0,
        feedbackId,
        actionType: 3,
      };
      (async () => {
        const result = await processSurveyTag(payload);
        if (!result.isSuccess) {
          showNotification('Error', result.message, NOTIFICATION_TYPE.ERROR);
        } else {
          setSelectedTag(undefined);
          setListTags(result.data.map((tag) => new Tag(tag)));
        }
      })();
    }
  };

  const handleSelectTag = (tag?: Tag) => {
    setSelectedTag(tag);
    setSelectedSentiment(tag?.tagSentiment ?? 0);
  };

  useEffect(() => {
    setSearchText(searchTag?.tagName ?? '');
    setListSearchTags([]);
  }, [searchTag]);

  useEffect(() => {
    if (searchText
      && searchTag?.tagName !== searchText) {
      onSearchTag(searchText);
    }
  }, [searchText]);

  return (
    <CustomModal
      className="tag-modal"
      title={title}
      onCancel={() => onCloseModal()}
      visible
      width={400}
    >
      <CustomInputGroup
        onChangeInput={onChangeInput}
        onSubmitButton={onAddTag}
        onKeyDown={keyDownHandler}
        placeholder={placeholder}
        buttonText={buttonText}
        value={searchText}
        textBoxClass="txt-add-tag"
        autoFocus={focusedAddTag}
        iconBefore={<TagGroupIcon />}
      />
      <div className="tags-select__list">
        {
          (listSearchTags.length > 0 && listSearchTags.map((tag, index) => (
            <button
              key={tag.tagId}
              type="button"
              className={classNames('tags-select__item', {
                'tags-select__item--active': active === index,
              })}
              onClick={() => setSearchTag(tag)}
            >
              {tag.tagName}

            </button>
          ))) || (searchText.length >= 3 && !searchTag && <span>{t('TS.NEW_TAG')}</span>)
        }
      </div>
      <div className="tag-modal__tag-items">
        {
          listTags?.map((tag) => (
            <TagItem
              tag={tag}
              key={tag.surveyAnswerTagId}
              isSelected={selectedTag?.surveyAnswerTagId === tag.surveyAnswerTagId}
              onClick={() => onRemoveTag(tag)}
              onSelect={() => handleSelectTag(tag)}
              showEdit={false}
            />
          ))
        }
      </div>
      <div className="tag-modal__type">
        <Radio.Group onChange={onUpdateSentiment} value={selectedSentiment}>
          <Radio value={TAG_SENTIMENT_POSITIVE} className="radio--positive">Positive</Radio>
          <Radio value={TAG_SENTIMENT_NEUTRAL} className="radio--neutral">Neutral</Radio>
          <Radio value={TAG_SENTIMENT_NEGATIVE} className="radio--negative">Negative</Radio>
        </Radio.Group>

      </div>
    </CustomModal>
  );
};

export default TagModal;
