import moment from 'moment';

import Filter, { IFilter } from './Filter';

import {
  FILTER_CLUB,
  FILTER_DATE_PICKER,
  FILTER_TYPE_ALL,
} from 'constants/filter';

interface IRequestDashboard {
  filters: IFilter[]
}

class RequestDashboard implements IRequestDashboard {
  filters: IFilter[];

  constructor(request: IRequestDashboard) {
    this.filters = request.filters;
  }

  findFilterByName = (name: string) => {
    const filter = this.filters.find((filter) => filter.name === name);
    if (filter) {
      return new Filter(filter);
    }
    return null;
  };

  removeFilterByName = (name: string) => {
    this.filters = this.filters.filter((filter) => filter.name !== name);
  };

  formatDashboardPointForApi() {
    return {
      filters: [
        this.findFilterByName(FILTER_DATE_PICKER),
        this.formatFilterClubForApi(),
      ].filter((filter) => filter !== null),
    };
  }

  formatFilterClubForApi = () => {
    const filterClub = this.findFilterByName(FILTER_CLUB);
    if (filterClub && filterClub.type === FILTER_TYPE_ALL) {
      delete filterClub.value1;
      return filterClub;
    }

    if (filterClub && filterClub.value1) {
      filterClub.value1 = [Number(filterClub.value1)];
    }

    return filterClub;
  };

  formatDashBoardFeedbackForApi() {
    return {
      filters: [
        this.formatFilterClubForApi(),
      ].filter((filter) => filter),
    };
  }

  getDatePickerForMoment = (): [moment.Moment, moment.Moment] => {
    const datePickerFilter = this.findFilterByName(FILTER_DATE_PICKER);
    if (datePickerFilter && datePickerFilter.value1 && datePickerFilter.value2
      && typeof datePickerFilter.value1 === 'string') {
      return [
        moment(datePickerFilter.value1),
        moment(datePickerFilter.value2),
      ];
    }
    return [moment(), moment()];
  };
}

export default RequestDashboard;
