import React from 'react';
import { useTranslation } from 'react-i18next';

const NoResponse: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="no-response">
      {t('ALL_RESPONSES_ALL_RESPONSES_NO_RESPONSE')}
    </div>
  );
};

export default NoResponse;
