import { Typography } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getSurveys } from '../../services/survey';

import SurveyFilter from './SurveyFilter';
import SurveyList from './SurveyList';
import SurveySettings from './SurveySettings';

import CustomButton from 'components/CustomButton';
import { DATE_DEFAULT, DATE_FORMAT_DD_MM_YYYY } from 'constants/date';
import { FILTER_DATE_FROM, FILTER_DATE_TO, FILTER_SURVEY_DEFAULT } from 'constants/filter';
import { TOTAL_PAGE } from 'constants/pagination';
import { REQUEST_SURVEY_DEFAULT } from 'constants/request';
import { CREATE_SURVEY_ROUTE, EDIT_SURVEY_ROUTE } from 'constants/routes';
import {
  ACTIVE,
  SORT_AC,
  SORT_DESC,
} from 'constants/survey';
import RequestSurvey from 'models/Survey/RequestSurvey';
import Survey, { ISurvey } from 'models/Survey/Survey';
import { useAppSelector } from 'store/hooks';

const { Title } = Typography;

const SurveyPage = () => {
  const { t } = useTranslation();
  const [isShowSetting, setShowSetting] = useState<boolean>(false);
  const [dataSurvey, setDataSurvey] = useState< ISurvey[]>([]);
  const [request, setRequest] = useState<RequestSurvey>(REQUEST_SURVEY_DEFAULT);
  const history = useNavigate();
  const [isLoadingTable, setLoadingTable] = useState<boolean>(false);
  const { permissions } = useAppSelector((state) => state.role);

  const viewSurveyDetail = (id : string) => {
    history(EDIT_SURVEY_ROUTE.replace(':id', id));
  };

  const fetchDataSurvey = useCallback(async (queryRequest: RequestSurvey) => {
    try {
      setLoadingTable(true);
      const response = await getSurveys(queryRequest.formatForRequestApi());
      const { data, paging } = response;

      setDataSurvey(data.map((item, index) => new Survey({
        no: index + 1,
        id: item.id,
        surveyName: item.surveyName,
        creator: item.creator,
        createdDate: moment(item.createdDate).format(DATE_FORMAT_DD_MM_YYYY),
        status: item.status === ACTIVE,
      })));

      setRequest(new RequestSurvey(
        queryRequest.pageIndex,
        queryRequest.pageSize,
        queryRequest.filter,
        queryRequest.sorts,
        paging.totalPages,
        paging.totalItemsCount,
      ));

      return setLoadingTable(false);
    } catch (e) {
      return setLoadingTable(false);
    }
  }, []);

  useEffect(() => {
    fetchDataSurvey(REQUEST_SURVEY_DEFAULT);
  }, [fetchDataSurvey]);

  const handleShowSetting = () => {
    setShowSetting(!isShowSetting);
  };

  const handleChangeFilter = (
    filterName: string,
    filterValue: string | moment.Moment,
  ) => {
    const filter = request.findFilterByKey ? request.findFilterByKey(filterName) : null;

    if (!filter && typeof filterValue === 'string') {
      request.addFilterWithKey(filterName, filterValue);
    } else if (
      (filterName === FILTER_DATE_FROM || filterName === FILTER_DATE_TO)
      && typeof filterValue !== 'string' && filter) {
      filter.queryValue = filterValue.format(DATE_DEFAULT);
    } else if (filter && typeof filterValue === 'string') {
      filter.queryValue = filterValue;
    }

    setRequest(new RequestSurvey(
      request.pageIndex,
      request.pageSize,
      request.filter,
      request.sorts,
      request.totalPages,
      request.totalRecords,
    ));

    return false;
  };

  const goToCreateFilter = async () => {
    history(CREATE_SURVEY_ROUTE);
  };

  const handlePagination = async (page: { pageIndex: number, pageSize: number }) => {
    const newRequest = new RequestSurvey(
      page.pageIndex,
      page.pageSize,
      request.filter,
      request.sorts,
    );

    fetchDataSurvey(newRequest);
  };

  const handleSubmitFilter = () => {
    request.pageIndex = 1;
    fetchDataSurvey(request);
  };

  const handleSort = async (pagination: any, filters: any, sorter: any) => {
    const sort = request.sorts;
    sort.queryKey = sorter.field;
    sort.operation = sort.operation === SORT_DESC ? SORT_AC : SORT_DESC;
    return fetchDataSurvey(new RequestSurvey(
      request.pageIndex,
      request.pageSize,
      request.filter,
      sort,
    ));
  };
  return (
    <div className="survey">
      <div className="survey-wrapper">
        <div className="survey-header">
          <Title className="survey-header__text" level={5}>{t('TS.SURVEY')}</Title>
          <CustomButton
            label={t('TS.SETTINGS')}
            onClick={handleShowSetting}
            className={classNames('survey-header__button-setting', {
              'survey-header__button-setting--active': isShowSetting,
            })}
          />
        </div>
        { isShowSetting
          && (
          <SurveySettings />
          )}
        <div className="survey-content">
          <SurveyFilter
            onSubmitFilter={handleSubmitFilter}
            filters={Array.isArray(request.filter) ? request.filter : FILTER_SURVEY_DEFAULT}
            goToCreateFilter={goToCreateFilter}
            onChangeFilter={handleChangeFilter}
            permissions={permissions}
          />
          <SurveyList
            dataSurvey={dataSurvey}
            paging={
              {
                pageIndex: request.pageIndex,
                pageSize: request.pageSize,
                totalPages: request.totalPages ? request.totalPages : TOTAL_PAGE,
                totalRecords: request.totalRecords ? request.totalRecords : 0,
              }
            }
            onChangePagination={handlePagination}
            onChangeTable={handleSort}
            isLoadingTable={isLoadingTable}
            viewSurveyDetail={viewSurveyDetail}
          />
        </div>
      </div>
    </div>
  );
};

export default SurveyPage;
