export const CONNECT_TOKEN = '/connect/token';
export const GET_TOKEN_BY_TOKEN_URL = '/idp/accounts/sso?token={token}';

export const GET_CLUBS = '/cnm/survey/GetAllClubs';
export const GET_RESPONSES = '/cnm/survey/ViewAllResponses';
export const GET_SURVEYS = '/cnm/survey/GetAllMemberSurveyEvents';
export const GET_OPERATORS = '/cnm/survey/GetSurveyOperators';
export const ASSIGN_OPERATOR_TO_FEEDBACK = 'cnm/survey/AssignOperatorToFeedback';
export const GET_NOTES = 'cnm/survey/notes/GetNotesByFeedbackId';
export const CREATE_SURVEY_NOTE = '/cnm/survey/notes/CreateSurveyNote';
export const DELETE_RESPONSE_DATA = '/cnm/survey/DeleteResponse';
export const UPDATE_RESPONSE_DATA = '/cnm/survey/UpdateResponse';

// search survey
export const GET_MEMBER_SURVEY_EVENT = '/cnm/membersurveyevent/search';

// create survey
export const GET_SURVEY_MASTER_DATA = '/cnm/membersurveyevent/masterdata';
export const CREATE_SURVEY_URL = '/cnm/membersurveyevent';

// role
export const GET_ALL_ROLE_RIGHTS = '/acm/operators/{operatorId}/roles';

export const GET_SURVEY_DETAIL = '/cnm/membersurveyevent/{id}';
export const EDIT_SURVEY_DETAIL = '/cnm/membersurveyevent/{id}';

// time config
export const TIME_CONFIG = '/cnm/survey/configs';

export const GET_TODO = '/cnm/survey/ViewAllToDo';
export const LOG_ATTEMPT = '/cnm/survey/todo/{id}/logAttempt';

// tags
export const GET_ALL_TAG_GROUP = '/cnm/surveytaggroups/GetAllTagGroups';
export const SURVEY_TAGS_PROCESS = '/cnm/survey/tags/process';
export const GET_TAGS_BY_FEEDBACK_ID = '/cnm/survey/tags/getbyfeedbackids';
export const HISTORY_RESPONSE = '/mbs/MemberSurveys/GetHistoryFeedbacksByMemberId';
export const GET_ALL_TAG_GROUPS = '/cnm/surveytaggroups/GetAllTagGroups';
export const CREATE_TAG_GROUP = '/cnm/surveytaggroups/createtaggroup';
export const EDIT_TAG_GROUP = '/cnm/surveytaggroups/updatetaggroup';
export const DELETE_TAG_GROUP = '/cnm/surveytaggroups/DeleteTagGroupById/{operatorId}/{tagGroupId}';
export const SEARCH_TAGS = '/cnm/survey/tags/GetAllToAdd';
export const GET_ALL_TAGS_BY_TAG_GROUP = '/cnm/surveytags/GetAllTagsByTagGroup';
export const UNASSIGN_TAG_TO_TAG_GROUP = '/cnm/surveytags/UnAssignTagToTagGroup';
export const ASSGIN_TAG_TO_TAG_GROUP = '/cnm/surveytags/AssignTagToTagGroup';
export const GET_TAG_GROUPS_CHART = '/cnm/surveytaggroups/charts';

// dashboard
export const SURVEY_POINTS_DASHBOARD = '/cnm/survey/GetDashBoardPoints';
export const GET_MOST_MENTION_DASHBOARD_FEEDBACK = 'cnm/survey/Feedbacks/MostMentions';
export const GET_MOST_RECENT_DASHBOARD_FEEDBACK = 'cnm/survey/Feedbacks/MostRecent';
export const GET_LIST_CLUB_DASHBOARD = '/cnm/survey/GetListClubsInDashboard';

export const GET_MINIMUM_TIME = '/cnm/Survey/GetMinimumTimeFrequencyMasterData';
export const UPDATE_MINIMUM_TIME = '/cnm/Survey/UpdateMinimumTimeFrequencyConfig';
export const SYSTEM_CONFIG = '/cnm/system/configs';
