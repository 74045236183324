import { Button } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IViewMore {
  text: string;
  numberCharacters: number;
}

const ViewMore: React.FC<IViewMore> = ({ text, numberCharacters }) => {
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(false);

  const handleClick = (event: any) => {
    setIsShow(!isShow);
    event.stopPropagation();
  };

  const isDefaultTextLongEnough = text.length > numberCharacters;

  const handleShowText = (inputText: string) => {
    if (isDefaultTextLongEnough) {
      return isShow ? inputText : `${inputText.slice(0, numberCharacters)}...`;
    }
    return inputText;
  };

  return (
    <>
      <span className="nowrap-text">{handleShowText(text)}</span>
      {isDefaultTextLongEnough && <Button type="link" className="view-more" onClick={handleClick}>{t(isShow ? 'VIEW_LESS' : 'VIEW_MORE')}</Button>}
    </>
  );
};

export default ViewMore;
