import { MinusCircleOutlined } from '@ant-design/icons';
import { Col, Form, FormInstance, Row } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ValueInputType from './ValueInput';

import CustomSelect from 'components/CustomSelect';
import { ICriteria } from 'models/ICriteria';
import { IOperatorSurvey } from 'models/IOperatorSurvey';
import { IMember } from 'pages/CreateSurvey';
import { useAppSelector } from 'store/hooks';
import {
  CRITERIA_GROUP,
  OPERATOR_MORE_THAN_CODE,
  VALUE_TYPE,
} from 'utils/constants';
import { checkCriteriaType } from 'utils/survey';

interface ListItemProps {
  groupField: FormListFieldData;
  parentIdx: number;
  form: FormInstance;
  fieldsLength: number;
  remove: (name: number) => void;
}

const MemberItem: React.FC<ListItemProps> = ({
  groupField,
  parentIdx,
  remove,
  form,
  fieldsLength,
}) => {
  const { data } = useAppSelector(state => state.survey);
  const { t } = useTranslation();
  const [member, setMember] = useState<IMember>();

  useEffect(() => {
    setTimeout(() => {
      const { surveyMember } = form.getFieldsValue();
      if (surveyMember) {
        setMember(surveyMember[parentIdx] || null);
      }
    }, 200);
  }, [form, parentIdx]);

  const valueType = useMemo(() => {
    const { surveyMember } = form.getFieldsValue();
    let value = '';
    if (surveyMember) {
      const item = surveyMember[parentIdx];
      const requiredCondition = item && item.operator;
      const { inputType, selectType, dateType } = checkCriteriaType(
        item,
        requiredCondition
      );

      if (inputType) {
        value = VALUE_TYPE.INPUT;
      }
      if (dateType) {
        value = VALUE_TYPE.DATE_TIME;
      }
      if (selectType) {
        value = VALUE_TYPE.SELECT;
      }
    }
    return value;
  }, [form.getFieldValue('surveyMember'), member]);

  const handleChangeCriteria = (value: any) => {
    const surveyMember = form.getFieldValue('surveyMember') || [];
    const getCriteria = data.criterias.filter(
      (item: any) => item.criteriaName === value
    );
    surveyMember[parentIdx] = {
      ...surveyMember[parentIdx],
      operator: null,
      values: [],
      dataType: getCriteria[0].dataType,
    };
    form.setFieldsValue({ surveyMember });
    setMember(form.getFieldValue('surveyMember')[parentIdx]);
  };

  const handleChangeOperator = () => {
    const surveyMember = form.getFieldValue('surveyMember') || [];
    let values: any = [];
    if (
      surveyMember[parentIdx].criteria === CRITERIA_GROUP.JOIN_DATE &&
      surveyMember[parentIdx].operator !== OPERATOR_MORE_THAN_CODE
    ) {
      values = moment(new Date());
    }
    surveyMember[parentIdx] = { ...surveyMember[parentIdx], values };
    form.setFieldsValue({ surveyMember });
    setMember(form.getFieldValue('surveyMember')[parentIdx]);
  };

  const operatorOptions = useMemo(() => {
    let options: any[] = [];
    if (member) {
      const searchCriteria = data.criterias.find(
        (criteria: ICriteria) => criteria.criteriaName === member?.criteria
      );
      options =
        searchCriteria && searchCriteria.operators.length
          ? searchCriteria.operators.map((x: IOperatorSurvey) => ({
              value: x.operatorName,
              label: x.displayName,
            }))
          : [];
    }
    return options;
  }, [form.getFieldValue('surveyMember'), data, member]);

  const removeMember = () => {
    remove(groupField.name);
  };

  const usedCriterias = useMemo(() => {
    const { surveyMember } = form.getFieldsValue();
    return surveyMember ? surveyMember.map((item: any) => item.criteria) : [];
  }, [form.getFieldValue('surveyMember')]);

  return (
    <Row>
      <Col md={7}>
        <Form.Item
          {...groupField}
          name={[groupField.name, 'criteria']}
          fieldKey={[groupField.key, 'criteria']}
          rules={[
            {
              required: true,
              message: t('TS.CREATE_SURVEY.REQUIRED_MESSAGE', {
                key: t('TS.CREATE_SURVEY.CRITERIA'),
              }),
            },
          ]}
        >
          <CustomSelect
            options={data.criterias.map(item => ({
              value: item.criteriaName,
              label: item.displayName,
              disable: usedCriterias.includes(item.criteriaName),
            }))}
            onChange={handleChangeCriteria}
          />
        </Form.Item>
      </Col>
      <Col md={7} className="m-16">
        <Form.Item
          {...groupField}
          name={[groupField.name, 'operator']}
          fieldKey={[groupField.key, 'operator']}
          rules={[
            {
              required: true,
              message: t('TS.CREATE_SURVEY.REQUIRED_MESSAGE', {
                key: t('TS.CREATE_SURVEY.OPERATOR'),
              }),
            },
          ]}
        >
          <CustomSelect
            options={operatorOptions || []}
            onChange={handleChangeOperator}
          />
        </Form.Item>
      </Col>
      <Col md={7}>
        <ValueInputType
          typeInput={valueType}
          form={form}
          groupField={groupField}
          parentIdx={parentIdx}
        />
      </Col>

      {fieldsLength > 1 ? (
        <Col className="create-survey__remove">
          <MinusCircleOutlined onClick={removeMember} />
        </Col>
      ) : null}
    </Row>
  );
};

export default MemberItem;
