import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { AxisBottom } from '@visx/axis';
import { localPoint } from '@visx/event';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { BarGroup } from '@visx/shape';
import {
  Tooltip, useTooltip, defaultStyles,
} from '@visx/tooltip';
import { Typography } from 'antd';
import { useEffect, useState } from 'react';

import CustomButton from 'components/CustomButton';
import { TAG_GROUPS_PER_PAGE } from 'constants/pagination';
import { TAG_GROUPS_MENTION_COLORS } from 'constants/tagGroup';
import { IChartData } from 'models/IChartData';
import { NEGATIVE, POSITIVE } from 'utils/constants';
import { useTranslation } from 'react-i18next';

interface IChart {
  data: IChartData[];
  height?: number;
  width?: number;
  margin?: { top: number; right: number; bottom: number; left: number };
}

const { Title } = Typography;

const Chart = (props: IChart) => {
  const {
    data,
    width = 800,
    height = 400,
    margin = {
      top: 40,
      right: 0,
      bottom: 40,
      left: 0,
    },
  } = props;
  const { t } = useTranslation()
  let tooltipTimeout: number;
  const [showData, setShowData] = useState<IChartData[]>(data.slice(0, TAG_GROUPS_PER_PAGE));
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(0);
  const {
    tooltipLeft,
    hideTooltip,
    showTooltip,
    tooltipOpen,
    tooltipData,
    tooltipTop,
  } = useTooltip();

  useEffect(() => {
    if (currentIndex <= pageSize) {
      const dataShow = [];
      const showLength = (currentIndex + 1) * TAG_GROUPS_PER_PAGE < data.length
        ? (currentIndex + 1) * TAG_GROUPS_PER_PAGE
        : data.length;
      for (let i = TAG_GROUPS_PER_PAGE * currentIndex; i < showLength; i++) {
        dataShow.push(data[i]);
      }
      setShowData(dataShow);
    }
  }, [currentIndex, pageSize]);

  useEffect(() => {
    setShowData(data.slice(0, TAG_GROUPS_PER_PAGE));
    setPageSize(Math.ceil(data.length / TAG_GROUPS_PER_PAGE));
  }, [data]);

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  let groups: any[] = [];
  if (showData.length > 0) {
    groups = Object.keys(showData[0])?.filter(
      (d) => d !== 'group' && d !== 'positiveNum' && d !== 'neutralNum' && d !== 'negativeNum',
    );
  }

  const getGroups = (item: IChartData) => (item.group);

  const groupScale = scaleBand<string>({
    domain: showData.map(getGroups),
    padding: 0.2,
  });

  const barWidthScale = scaleBand<string>({
    domain: groups,
    padding: 0.1,
  });

  const barHeightScale = scaleLinear<number>({
    domain: [0, 100],
  });

  const colors = scaleOrdinal<string, string>({
    domain: groups,
    range: TAG_GROUPS_MENTION_COLORS,
  });

  const handleNextButton = () => {
    if (currentIndex < pageSize - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePreviousButton = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleMouseMove = (event: any, bar: any, index: number) => {
    if (tooltipTimeout) clearTimeout(tooltipTimeout);
    const coords = localPoint(event.target.ownerSVGElement, event);
    let tipData;
    if (bar.key === POSITIVE) {
      tipData = showData[index].positiveNum;
    } else if (bar.key === NEGATIVE) {
      tipData = showData[index].negativeNum;
    } else {
      tipData = showData[index].neutralNum;
    }
    showTooltip({
      tooltipData: tipData,
      tooltipLeft: coords?.x,
      tooltipTop: ((coords?.y ?? 0) + 400),
    });
  };

  const handleMouseLeave = () => {
    tooltipTimeout = window.setTimeout(() => {
      hideTooltip();
    }, 300);
  };

  groupScale.rangeRound([0, xMax]);
  barWidthScale.rangeRound([0, groupScale.bandwidth()]);
  barHeightScale.range([yMax, 0]);

  return (
    <div className="chart">
      <Title className="chart__title" level={5}>{t('TS.DASHBOARD.TRENDING')}</Title>
      <div className="chart__header">
        <div className="chart__legend">
          {groups.map((group) => (
            <div key={group} className="chart__legend-item">
              <span
                className={`chart__legend-label chart__legend--${group.toLocaleLowerCase()}`}
              />
              <span>{group}</span>
            </div>
          ))}
        </div>
        <div className="chart__next">
          <CustomButton
            icon={<ArrowLeftOutlined />}
            className="chart__next-button"
            onClick={handlePreviousButton}
            disabled={currentIndex <= 0}
          />
          <CustomButton
            icon={<ArrowRightOutlined />}
            className="chart__next-button"
            onClick={handleNextButton}
            disabled={currentIndex >= pageSize - 1}
          />
        </div>
      </div>
      <svg width={width} height={height}>
        <rect x={0} y={0} width={width} height={height} fill="transparent" />
        <Group top={margin.top} left={margin.left}>
          <GridRows
            tickValues={[0, 20, 40, 60, 80, 100]}
            scale={barHeightScale}
            width={width - margin.left - margin.right}
            height={yMax}
            stroke="#e0e0e0"
          />
          <BarGroup
            data={showData}
            keys={groups}
            height={yMax}
            x0={getGroups}
            x0Scale={groupScale}
            x1Scale={barWidthScale}
            yScale={barHeightScale}
            color={colors}
          >
            {(barGroups) => barGroups.map((barGroup, index) => (
              <Group
                key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                left={barGroup.x0}
              >
                {barGroup.bars.map((bar) => (
                  <rect
                    key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                    x={bar.x}
                    y={bar.y}
                    width={bar.width}
                    height={bar.height}
                    fill={bar.color}
                    rx={0}
                    onMouseLeave={handleMouseLeave}
                    onMouseMove={(event) => {
                      handleMouseMove(event, bar, index);
                    }}
                  />
                ))}
              </Group>
            ))}
          </BarGroup>

        </Group>
        <AxisBottom
          top={yMax + margin.top}
          scale={groupScale}
          tickStroke=""
          hideAxisLine
        />
      </svg>
      {tooltipOpen && tooltipData && (
      <Tooltip
        left={tooltipLeft}
        style={{
          ...defaultStyles,
          minWidth: 40,
          color: '#f9f9f9',
        }}
        top={tooltipTop}
      >
        <div className="chart__tooltip">
          {tooltipData as string}
        </div>
      </Tooltip>
      )}
    </div>
  );
};

export default Chart;
