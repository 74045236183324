import classNames from 'classnames';
import React from 'react';

interface ILoading {
  loadingMode?: 'page' | 'component';
}

const Loading: React.FC<ILoading> = ({ loadingMode = 'page' }) => (
  <div className={
    classNames({
      loading: loadingMode === 'page',
      loading__component: loadingMode === 'component',
    })
  }
  >
    <div className="loading__loader" />
  </div>
);

export default Loading;
