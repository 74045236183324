import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISurveyMasterData } from 'models/ISurveyMasterData';
import { getSurveyMasterData } from 'services/survey';
import { API_STATUS } from 'utils/constants';

export interface SurveyMasterData {
  data: ISurveyMasterData;
  status: string;
}

const initialState: SurveyMasterData = {
  data: {
    surveyForms: [],
    reasons: [],
    criterias: [],
    actions: [],
    clubGroups: [],
    plans: [],
    tiers: [],
    memberStatusDtos: [],
    clubPercentages: [],
    surveyTypes: [
      { id: 0, name: 'Relational' },
      { id: 1, name: 'Attendance' },
    ],
  },
  status: API_STATUS.LOADING,
};

export const getMaster = createAsyncThunk(
  'survey/getMasterDataSurvey ',
  async () => {
    const res = await getSurveyMasterData();
    return res;
  },
);

export const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(getMaster.pending, ($state: any) => {
        $state.status = API_STATUS.LOADING;
      })
      .addCase(getMaster.fulfilled, ($state: any, action: PayloadAction<ISurveyMasterData>) => {
        $state.status = API_STATUS.SUCCESS;
        $state.data = action.payload;
      })
      .addCase(getMaster.rejected, ($state: any) => {
        $state.status = API_STATUS.FAILED;
      });
  },
});

export default surveySlice.reducer;
