import { Collapse } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ResponseMethodTab from '../ResponseMethodTab';

import CustomButton from 'components/CustomButton';
import ResponseHeader from 'components/ResponseHeader';
import TagList from 'components/TagList';
import TagModal from 'components/TagModal';
import { CONTACT_TYPE_EMAIL } from 'constants/response';
import Operator from 'models/Operator';
import Response, { IResponse } from 'models/Response';
import NoResponse from 'pages/AllResponses/components/NoResponse';

const { Panel } = Collapse;
interface ITodoList {
  todos: Response[];
  operators: Operator[];
  onLogAttempt: (
    isSuccess: boolean,
    todo: Response,
    contactType: string
  ) => void;
  onChangeComment: (todo: Response, comment: string) => void;
  onChangeTab: (todo: Response, tab: string) => void;
  onClickHistory: (response: IResponse, event?: Event) => void;
  assignOperator: (assigneeId: string, feedbackId: string) => void;
  submitEmail: (response: Response) => void;
  fetchDataTodoList: () => void;
  isSubmitting: boolean;
}

const TodoList = (props: ITodoList) => {
  const {
    operators,
    todos,
    onLogAttempt,
    onChangeComment,
    onChangeTab,
    onClickHistory,
    assignOperator,
    submitEmail,
    fetchDataTodoList,
    isSubmitting,

  } = props;

  const [shouldShowAddTagModal, setShouldShowAddTagModal] = useState<boolean>(false);
  const [selectedFeedbackId, setSelectedFeedbackId] = useState<number>();
  const [editTagId, setEditTagId] = useState<number>();
  const [focusedAddTag, setFocusedAddTag] = useState<boolean>(false);
  const [responseIdList, setResponseIdList] = useState<string[]>([]);
  const { t } = useTranslation();

  const onClickAddTag = (feedbackId: number) => {
    setSelectedFeedbackId(feedbackId);
    setEditTagId(undefined);
    setShouldShowAddTagModal(true);
    setFocusedAddTag(true);
  };

  const onEditTag = (tagId: number, feedbackId: number) => {
    setEditTagId(tagId);
    setSelectedFeedbackId(feedbackId);
    setShouldShowAddTagModal(true);
  };

  const handleOnCloseAddTag = () => {
    setFocusedAddTag(false);
    setShouldShowAddTagModal(false);
    fetchDataTodoList();
  };

  const handleOnChangeCollapse = (items: string[] | string) => {
    if (Array.isArray(items)) {
      setResponseIdList(items);
    } else {
      setResponseIdList([items]);
    }
  };

  if (todos.length === 0) {
    return <NoResponse />;
  }

  return (
    <div className="response-collapse">
      <Collapse
        bordered={false}
        onChange={handleOnChangeCollapse}
      >
        {todos
          && todos.map((todo: Response, index: number) => (
            <Panel
              header={(
                <ResponseHeader
                  response={todo}
                  operators={operators}
                  responseIdList={responseIdList}
                  onClickHistory={onClickHistory}
                  assignOperator={assignOperator}
                  fetchToDoList={fetchDataTodoList}
                />
              )}
              className={classNames('response-collapse__panel', {
                'response-collapse__panel--even': index % 2 === 0,
                'response-collapse__panel--odd': index % 2 !== 0,
              })}
              key={todo.responseId}
            >
              <TagList
                feedbackId={todo.responseId}
                onClickAddTag={onClickAddTag}
                onEditTag={onEditTag}
                tags={todo.tags}
              />
              <ResponseMethodTab
                todo={todo}
                onChangeComment={onChangeComment}
                onChangeTab={onChangeTab}
                onSubmitEmail={submitEmail}
              />
              <div className="response-collapse__panel-actions">
                {todo.contactType !== CONTACT_TYPE_EMAIL && (
                  <>
                    <CustomButton
                      label={t('TO_DO_FAIL', {
                        number: `(${todo.attemptedTime + 1})`,
                      })}
                      backgroundColor="black"
                      onClick={() => onLogAttempt(false, todo, todo.contactType)}
                      disabled={!todo.canSubmitLogAttempt() || isSubmitting}
                    />
                    <CustomButton
                      label={t('TO_DO_SUCCESS')}
                      backgroundColor="orange"
                      onClick={() => onLogAttempt(true, todo, todo.contactType)}
                      disabled={!todo.canSubmitLogAttempt() || isSubmitting}
                    />
                  </>
                )}
              </div>
            </Panel>
          ))}
        {shouldShowAddTagModal && selectedFeedbackId && (
          <TagModal
            title="Tags"
            onCloseModal={handleOnCloseAddTag}
            feedbackId={selectedFeedbackId}
            editTagId={editTagId}
            focusedAddTag={focusedAddTag}
            tags={
              todos.find((todo) => todo.responseId === selectedFeedbackId)
                ?.tags ?? []
            }
          />
        )}
      </Collapse>
    </div>
  );
};

export default TodoList;
