import SurveyTodo from './SurveyTodo';

import { CONTACT_TYPE_EMAIL } from 'constants/response';

interface ITodo {
  contactType: string,
  surveyToDoDetails: SurveyTodo[],
  latestActionDate: string
}

class Todo implements ITodo {
  contactType: string;
  surveyToDoDetails: SurveyTodo[];
  latestActionDate: string;
  constructor(todo: ITodo) {
    this.surveyToDoDetails = todo.surveyToDoDetails;
    this.latestActionDate = todo.latestActionDate;
    this.contactType = todo.contactType;
  }

  hasSurveyTodo = () => this.surveyToDoDetails.length > 0;

  getComment = (): string => {
    if (this.hasSurveyTodo()) {
      return this.surveyToDoDetails[0].comment;
    }
    return '';
  };

  getTotalEmail = () => this.surveyToDoDetails
    .filter((surveyTodo) => surveyTodo.contactType === CONTACT_TYPE_EMAIL)
    .length;
}

export default Todo;
