import { formatUrlApiWithId } from '../utils/stringHelper';

import { http } from './common';
import {
  CREATE_SURVEY_URL,
  GET_SURVEY_MASTER_DATA,
  GET_MEMBER_SURVEY_EVENT,
  GET_SURVEY_DETAIL,
  EDIT_SURVEY_DETAIL,
  LOG_ATTEMPT,
  SURVEY_POINTS_DASHBOARD,
  GET_MOST_MENTION_DASHBOARD_FEEDBACK,
  GET_MOST_RECENT_DASHBOARD_FEEDBACK,
} from './common/apis';

import { IClub } from 'models/Club';
import { ICreateEventResponse } from 'models/ICreateEventResponse';
import { IEditResponse } from 'models/IEditResponse';
import { IResponsePaging } from 'models/IResponsePaging';
import { ISurveyMasterData } from 'models/ISurveyMasterData';
import { ImageSurveyFeedback } from 'models/Survey/ImageSurveyFeedback';
import { IRequestSurvey } from 'models/Survey/RequestSurvey';
import Survey from 'models/Survey/Survey';
import { SurveyDetail } from 'models/SurveyDetail';

export const getSurveyMasterData = async (): Promise<ISurveyMasterData> => {
  const res = await http.get(GET_SURVEY_MASTER_DATA);
  return res.data;
};

export const createSurvey = async (
  data: any,
): Promise<ICreateEventResponse> => {
  const res = await http.post(CREATE_SURVEY_URL, data);
  return res.data;
};

export const getSurveys = async (
  request: IRequestSurvey,
): Promise<{ data: Survey[]; paging: IResponsePaging }> => {
  const res = await http.post(GET_MEMBER_SURVEY_EVENT, request);
  return res.data;
};

export const getSurveyDetail = async (id: string): Promise<SurveyDetail> => {
  const res = await http.get(formatUrlApiWithId(GET_SURVEY_DETAIL, id));
  return res.data;
};

export const editSurvey = async (id: string, data: any): Promise<IEditResponse> => {
  const res = await http.put(formatUrlApiWithId(EDIT_SURVEY_DETAIL, id), data);
  return res.data;
};

export const logAttempt = async (id: string, data: {
    isSuccess: boolean,
    contactType: string,
    contactValue: string,
    content: string
    operatorId: number
}) => {
  const response = await http.post(formatUrlApiWithId(LOG_ATTEMPT, id), data);
  return response.data;
};

export const getDashBoardFeedbacks = async (body: any, isMostRecent: boolean): Promise<{
  tagGroupName: string,
  totalNegativePoint: number
  totalNeutralPoint: number
  totalPositivePoint: number,
  surveyNegativeFeedbacks: Survey[],
  surveyNeutralFeedbacks: Survey[],
  surveyPositiveFeedbacks: Survey[],
  imageFeedbacks: ImageSurveyFeedback[];
}> => {
  let url = GET_MOST_MENTION_DASHBOARD_FEEDBACK;
  if (isMostRecent) {
    url = GET_MOST_RECENT_DASHBOARD_FEEDBACK;
  }
  const res = await http.post(url, body);
  return res.data;
};

export const getDashBoardPoints = async (body: any): Promise<{
  averagePoint: number,
  clubPointDtos: IClub []
}> => {
  const res = await http.post(SURVEY_POINTS_DASHBOARD, body);
  return res.data;
};

export default {
  getSurveyMasterData,
  getSurveys,
  createSurvey,
  logAttempt,
  getDashBoardFeedbacks,
  getDashBoardPoints,
};
