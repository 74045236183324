import {
  useCallback, useEffect, useReducer,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

import NoteList from './NoteList';
import {
  SET_LOADING,
  SET_NEW_NOTE,
  SET_NOTE_LIST,
  SET_PAGING,
  initialState,
  reducer,
} from './reducer';

import CustomButton from 'components/CustomButton';
import CustomModal from 'components/CustomModal';
import CustomPagination from 'components/CustomPagination';
import CustomTextArea from 'components/CustomTextArea';
import { DEFAULT_PAGING } from 'constants/pagination';
import { IResponse } from 'models/Response';
import { getOperatorId } from 'services/auth';
import {
  getNoteList,
  createSurveyNote,
} from 'services/response';
import { NOTIFICATION_TYPE } from 'utils/constants';
import { showNotification } from 'utils/helpers';
import { hasOnlySpace } from 'utils/validate';

import './note-modal.scss';
import { IPagination } from 'models/utility/Pagination';

interface NoteModalProps {
  response: IResponse;
  onCloseModal: () => void;
  updateNoteTotal: (response: IResponse) => void
}
const NoteModal = (props: NoteModalProps) => {
  const { t } = useTranslation();
  const { onCloseModal, response, updateNoteTotal } = props;
  const operatorId = getOperatorId(useAuth().user);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    noteList,
    isLoading: isLoadingNote,
    paging,
    newNote,
  } = state;

  const loadAllNote = useCallback((paging: IPagination) => {
    dispatch({
      type: SET_LOADING,
      isLoading: true,
    });
    getNoteList({
      FeedbackId: response.responseId,
      pageIndex: paging.pageIndex,
      pageSize: paging.pageSize,
    })
      .then((responseData) => {
        if (responseData.data) {
          dispatch({
            type: SET_NOTE_LIST,
            noteList: responseData.data,
            paging: { ...responseData.paging },
          });
        }
      }).catch(() => {
        showNotification(
          t('TS.ERROR'),
          t('TS_NOTIFICATION_SERVICES_NOTIFICATION'),
          NOTIFICATION_TYPE.ERROR,
        );
      })
      .finally(() => dispatch({
        type: SET_LOADING,
        isLoading: false,
      }));
  }, [response.responseId, t]);

  useEffect(() => {
    loadAllNote({
      ...DEFAULT_PAGING,
    });
  }, [loadAllNote]);

  const onChangeNote = (value: string) => {
    dispatch({
      type: SET_NEW_NOTE,
      newNote: {
        ...newNote,
        note: value,
      },
    });
  };

  const submitNote = () => {
    createSurveyNote({
      ...newNote,
      feedbackId: response.responseId,
      operatorId: Number(operatorId),
    }).then((data) => {
      loadAllNote({
        ...DEFAULT_PAGING,
      });
      dispatch({
        type: SET_NEW_NOTE,
        newNote: {
          note: '',
        },
      });
      if (data.noteTotal) {
        updateNoteTotal({
          ...response,
          noteTotal: data.noteTotal,
        });
      }
      showNotification(
        t('TS.SUCCESS'),
        t('TS.ADD.NOTE.SUCCESSFULLY'),
        NOTIFICATION_TYPE.SUCCESS,
      );
    }).catch(() => {
      showNotification(
        t('TS.ERROR'),
        t('TS_NOTIFICATION_SERVICES_NOTIFICATION'),
        NOTIFICATION_TYPE.ERROR,
      );
    });
  };

  const handleOnChangePage = (paging: any) => {
    const newPaging = {
      ...paging,
      pageIndex: paging.pageIndex,
    };
    dispatch({
      type: SET_PAGING,
      paging: newPaging,
    });
    loadAllNote(newPaging);
  };

  return (
    <CustomModal
      title={t('TS.NOTE.TITLE')}
      onCancel={onCloseModal}
      visible
      width={700}
      className="note-modal"
    >
      <CustomTextArea
        className="note-modal__input"
        maxLength={500}
        value={newNote.note}
        onChange={onChangeNote}
        placeHolder={t('TS.ADD.NOTE')}
      />
      <div className="note-modal__save">
        <CustomButton
          backgroundColor="orange"
          label={t('TS.SAVE')}
          disabled={hasOnlySpace(newNote.note)}
          onClick={submitNote}
        />
      </div>
      <div className="note-modal__content">
        <NoteList isLoading={isLoadingNote} noteList={noteList} />
      </div>
      {
          noteList.length !== 0 && (
          <div className="note-modal__pagination">
            <CustomPagination
              paging={paging}
              setPaging={handleOnChangePage}
              hidePageRow
            />
          </div>
          )
}
    </CustomModal>
  );
};
export default NoteModal;
