import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useDispatch } from 'react-redux';

import CustomButton from 'components/CustomButton';
import CustomModal from 'components/CustomModal';
import CustomSelect from 'components/CustomSelect';
import CustomTextArea from 'components/CustomTextArea';
import { IUpdateResponse } from 'models/IUpdateResponse';
import Response, { IResponse } from 'models/Response';
import { sortClubByAlpha } from 'selectors/club';
import { getOperatorId } from 'services/auth';
import { updateResponse } from 'services/todo';
import { getClubsAsync } from 'store/club';
import { useAppSelector } from 'store/hooks';
import { NOTIFICATION_TYPE } from 'utils/constants';
import { showNotification } from 'utils/helpers';
import { capitalizeFirstLetter } from 'utils/stringHelper';

import './edit-response-modal.scss';
import { AppDispatch } from 'store';

interface EditResponseModalProps {
  onCloseModal: () => void;
  response: IResponse;
  setResponseModel: (response: IResponse) => void;
}
const EditResponseModal = (props: EditResponseModalProps) => {
  const { t } = useTranslation();
  const { onCloseModal, response, setResponseModel } = props;
  const { clubs } = useAppSelector((state) => state.club);
  const dispatch = useDispatch<AppDispatch>();
  const [responseModel, setResponse] = useState<IResponse>(response);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const operatorId = getOperatorId(useAuth().user);
  useEffect(() => {
    if (clubs.length === 0) {
      dispatch(getClubsAsync());
    }
    if (response.responseEdited) {
      setResponse(Response.formatFromAPI(response));
    }
  }, [dispatch, clubs, response]);

  const onChangeResponse = (fieldName: string, value: string | number) => {
    setIsDisabled(false);
    const clubsSelected = clubs.find((club) => club.id === value);
    if (fieldName === 'club' && clubsSelected) {
      setResponse({
        ...responseModel,
        clubDisplay: clubsSelected,
      });
    } else {
      setResponse({
        ...responseModel,
        [fieldName]: value,
      });
    }
  };

  const editResponse = async () => {
    const body: IUpdateResponse = {
      responseId: responseModel.responseId,
      feedbackContent: responseModel.feedbackContentDisplay,
      clubId: responseModel.clubDisplay.id,
      operatorId,
      point: responseModel.pointDisplay ?? 0,
    };
    try {
      const response = await updateResponse(body);
      setResponseModel(
        Response.formatFromAPI(response),
      );
      showNotification(
        t('TS.SUCCESS'),
        t('TS.EDIT.RESPONSE_TO_DO_SUCCESSFULLY'),
        NOTIFICATION_TYPE.SUCCESS,
      );
    } catch (error) {
      showNotification(
        t('TS.ERROR'),
        t('TS_NOTIFICATION_SERVICES_NOTIFICATION'),
        NOTIFICATION_TYPE.ERROR,
      );
    } finally {
      onCloseModal();
    }
  };

  return (
    <CustomModal
      className="edit-survey-modal"
      onCancel={onCloseModal}
      width={700}
      visible
      title={t('TS.EDITED.RESPONSE')}
    >
      <div className="edit-survey-modal__control">
        <div className="edit-survey-modal__control-select">
          <CustomSelect
            label={capitalizeFirstLetter(t('TS.POINT'))}
            value={String(responseModel.pointDisplay) ?? ''}
            options={[...Array(11).keys()].map((point) => ({
              label: String(point < 10 ? `0${point}` : point),
              value: String(point),
            }))}
            onChange={(value) => onChangeResponse('pointDisplay', value)}
          />
          <CustomSelect
            label={t('ALL_RESPONSES_FILTER_CLUB')}
            value={String(responseModel.clubDisplay.name)}
            options={
              sortClubByAlpha(clubs).map((club) => ({
                label: club.name,
                value: club.id,
              }))
            }
            onChange={(value) => onChangeResponse('club', value)}
          />
        </div>
        <div className="edit-survey-modal__control-text">
          <CustomTextArea
            value={responseModel.feedbackContentDisplay}
            maxLength={2000}
            onChange={(value) => onChangeResponse('feedbackContentDisplay', value)}
          />
        </div>
      </div>
      <div className="edit-survey-modal__bottom">
        <CustomButton label={t('TS.CANCEL')} onClick={onCloseModal} />
        <CustomButton
          label={t('TS.SAVE')}
          backgroundColor="orange"
          onClick={editResponse}
          disabled={isDisabled}
        />
      </div>
    </CustomModal>
  );
};

export default EditResponseModal;
