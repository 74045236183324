import React from 'react';

interface IRoute {
  url: string;
  permissionAccess: string[];
  component: React.ComponentType,
  exact?: boolean;
}

class Route implements IRoute {
  url: string;
  permissionAccess: string[];
  component: React.ComponentType;
  exact?: boolean = true;

  constructor(route: IRoute) {
    this.url = route.url;
    this.permissionAccess = route.permissionAccess;
    this.component = route.component;
    this.exact = route.exact;
  }
}

export default Route;
