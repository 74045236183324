export const MODE_ADD = 'add';
export const MODE_EDIT = 'edit';
export const MODE_ADD_TAG = 'add-tag';
export const POSITIVE = 'Positive';
export const NEUTRAL = 'Neutral';
export const NEGATIVE = 'Negative';
export const COLOR_POSITIVE = '#32B770';
export const COLOR_NEUTRAL = '#747474';
export const COLOR_NEGATIVE = '#EF6161';
export const TAG_GROUPS_MENTION_COLORS = [COLOR_POSITIVE, COLOR_NEUTRAL, COLOR_NEGATIVE];
export const TAG_SENTIMENT_POSITIVE = 3;
export const TAG_SENTIMENT_NEUTRAL = 2;
export const TAG_SENTIMENT_NEGATIVE = 1;

export default {
  MODE_ADD,
  MODE_EDIT,
  MODE_ADD_TAG,
};
