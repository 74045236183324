import Todo from './Todo';

import { FAIL, NEW, SUCCESS } from 'constants/history';
import { POSITIVE, NEUTRAL, NEGATIVE } from 'constants/tagGroup';
import { formatPoint } from 'utils/stringHelper';

export interface IHistory {
  type: number;
  isSentEmail: boolean;
  clubName: string;
  surveyName: string;
  surveySentDate: string;
  memberSurveyEventId?: number;
  feedbackId?: number;
  point?: number;
  feedbackContent?: string;
  feedbackStatus?: string;
  feedbackDate?: string;
  clubId?: string;
  currentDate?: string;
  surveyToDos?: Todo[];
}

class History implements IHistory {
  type: number;
  isSentEmail: boolean;
  clubName: string;
  surveyName: string;
  surveySentDate: string;
  memberSurveyEventId?: number;
  currentDate?: string;
  surveyToDos?: Todo[] | [];
  clubId?: string;
  feedbackStatus?: string;
  feedbackDate?: string;
  feedbackId?: number;
  point?: number;
  feedbackContent?: string;

  constructor(history: IHistory) {
    this.type = history.type;
    this.isSentEmail = history.isSentEmail;
    this.clubName = history.clubName;
    this.surveyName = history.surveyName;
    this.surveySentDate = history.surveySentDate;
    this.memberSurveyEventId = history.memberSurveyEventId;
    this.surveyToDos = history.surveyToDos
      ? history.surveyToDos?.map((todo) => new Todo(todo))
      : [];
    this.currentDate = history.currentDate;
    this.clubId = history.clubId;
    this.feedbackStatus = history.feedbackStatus;
    this.feedbackDate = history.feedbackDate;
    this.feedbackId = history.feedbackId;
    this.point = history.point;
    this.feedbackContent = history.feedbackContent;
  }

  isSent() {
    return this.type === 0;
  }

  getPoint() {
    if (this.isSent()) {
      return 0;
    }

    if (this.point) {
      return formatPoint(Number(this.point));
    }

    return 0;
  }

  hasTodo() {
    if (!this.surveyToDos) {
      return false;
    }
    return this.surveyToDos.length > 0;
  }

  getFeedbackDate() {
    if (this.isSent()) {
      return this.surveySentDate;
    }

    return this.feedbackDate;
  }

  getStatusByPoint() {
    const point = Number(this.getPoint());
    if (point >= 9 && point <= 10) {
      return POSITIVE;
    }
    if (point >= 7 && point <= 8) {
      return NEUTRAL;
    }

    return NEGATIVE;
  }

  get todo() {
    if (!this.surveyToDos) {
      return [];
    }

    return this.surveyToDos;
  }

  hasStatusFail() {
    return this.feedbackStatus === FAIL || this.feedbackStatus === NEW;
  }
  hasStatusSuccess() {
    return this.feedbackStatus === SUCCESS;
  }
}

export default History;
