import classNames from 'classnames';

interface IFieldStatus {
  status: boolean;
}

const FieldStatus = (props: IFieldStatus) => {
  const { status } = props;
  return (
    <div className="status">
      <span className={classNames('status__icon', {
        'status--active': status,
        'status--inactive': !status,
      })}
      />
      <span className="status__text">
        { status ? 'Active' : 'Inactive' }
      </span>
    </div>
  );
};
export default FieldStatus;
