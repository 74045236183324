import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

import EmailModal from './EmailModal';
import HistoryItem from './HistoryItem';

import CustomModal from 'components/CustomModal';
import CustomRangeDatePicker from 'components/CustomRangeDatePicker';
import Loading from 'components/Loading';
import { DATE_FORMAT_DD_MM_YYYY } from 'constants/date';
import History from 'models/History';
import { IResponse } from 'models/Response';
import Todo from 'models/Todo';
import { getAllHistoryOfMember } from 'services/history';
import { NOTIFICATION_TYPE, DATE_FORMAT_FOR_SUBMIT } from 'utils/constants';
import { showNotification } from 'utils/helpers';
import { useTranslation } from 'react-i18next';

interface IHistoryModal {
  onCloseModal: () => void,
  response?: IResponse | null
}

const HistoryModal = (props: IHistoryModal) => {
  const { onCloseModal, response } = props;
  const { t } = useTranslation();
  const [histories, setHistories] = useState<History[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchDate, setSearchDate] = useState<[moment.Moment, moment.Moment]>([
    moment().subtract('30', 'days'),
    moment(),
  ]);
  const [shouldShowEmailModal, setShouldShowEmailModal] = useState<boolean>(false);
  const [emailDetail, setEmailDetail] = useState<{
    content: string,
    contactDate: string
  }[]>([]);

  const fetchDataHistory = useCallback(async () => {
    if (response && response.membershipNumber) {
      try {
        setIsLoading(true);
        const historyResponse = await getAllHistoryOfMember({
          membershipNumber: response.membershipNumber,
          endDate: searchDate[1].format(DATE_FORMAT_FOR_SUBMIT),
          startDate: searchDate[0].format(DATE_FORMAT_FOR_SUBMIT),
        });
        if (historyResponse.isSuccess) {
          setHistories(historyResponse.histories.map((data) => new History(data)));
        } else {
          setHistories([]);
        }
        setIsLoading(false);
      } catch (e) {
        showNotification(
          t('TS.ERROR'),
          t('TS_NOTIFICATION_SERVICES_NOTIFICATION'),
          NOTIFICATION_TYPE.ERROR,
        );
        setIsLoading(false);
      }
    }
  }, [response, searchDate]);

  useEffect(() => {
    fetchDataHistory();
  }, [fetchDataHistory]);

  const handleChangeSearchDate = (dates: [moment.Moment, moment.Moment]) => {
    setSearchDate(dates);
  };

  const onViewEmail = (todo: Todo) => {
    setEmailDetail(todo.surveyToDoDetails.map((surveyTodo) => ({
      content: surveyTodo.comment,
      contactDate: surveyTodo.contactDate,
    })));
    setShouldShowEmailModal(true);
  };

  const closeEmailModal = () => {
    setShouldShowEmailModal(false);
  };

  const getHistoryItemContent = () => (histories.length > 0
    ? histories.map((history, index) => (
      <HistoryItem
        history={history}
        key={history.memberSurveyEventId + index.toString()}
        onViewEmail={onViewEmail}
      />
    ))
    : (
      <span>
        {t('TS.NO_ITEM_HISTORY_FOUND')}
      </span>
    ));

  if (!response) {
    return null;
  }

  if (shouldShowEmailModal) {
    return (
      <EmailModal
        onCloseEmailModal={closeEmailModal}
        emailDetail={emailDetail}
      />
    );
  }

  return (
    <CustomModal
      className="history-modal"
      title={(
        <div className="history-modal__header">
          <div className="history-modal__header-control">
            <span>{t('ALL_RESPONSES_HEADER_HISTORY')}</span>
            <CustomRangeDatePicker
              value={searchDate}
              separator={<span>{t('TS.TO').toLocaleLowerCase()}</span>}
              label={t('TS.DASHBOARD.RECEIVED_RESPONSES_DATE')}
              onChange={handleChangeSearchDate}
              format={DATE_FORMAT_DD_MM_YYYY}
            />
          </div>
        </div>
      )}
      visible
      width={800}
      footer={null}
      onCancel={onCloseModal}
    >
      {!isLoading ? getHistoryItemContent() : <Loading loadingMode="component" />}
    </CustomModal>
  );
};

export default HistoryModal;
