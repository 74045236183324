import { createSlice } from '@reduxjs/toolkit';
import { UserManager } from 'oidc-client-ts';

import config from 'config';

interface ProfileState {
  isSigninRedirecting: boolean;
  isSignoutRedirecting: boolean
}

const initialState: ProfileState = {
  isSigninRedirecting: false,
  isSignoutRedirecting: false,
};

const userManager = new UserManager({
  ...config.oidc,
});

export const profileSlice = createSlice({
  name: 'profileReducer',
  initialState,
  reducers: {
    signInRedirect($state) {
      $state.isSigninRedirecting = true;
      userManager.signinRedirect();
    },
    signOutRedirect($state) {
      $state.isSignoutRedirecting = true;
      userManager.signoutRedirect();
    },
  },
});

export const { signInRedirect, signOutRedirect } = profileSlice.actions;

export default profileSlice.reducer;
