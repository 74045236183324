import { http } from './common';
import { HISTORY_RESPONSE } from './common/apis';

import { IHistory } from 'models/History';

export const getAllHistoryOfMember = async (body: {
  membershipNumber: string,
  startDate: string,
  endDate: string
}): Promise<{ isSuccess: boolean, histories: IHistory[]}> => {
  const res = await http.post(HISTORY_RESPONSE, body);
  return res.data;
};

export default {
  getAllHistoryOfMember,
};
