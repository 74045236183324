import { Input } from 'antd';
import React from 'react';

const { TextArea } = Input;

interface ICustomTextArea {
  placeHolder?: string;
  maxLength?: number;
  onChange?: (value: string) => void;
  value?: string;
  rows?: number;
  className?: string;
}

const CustomTextArea: React.FC <ICustomTextArea> = ({
  placeHolder,
  maxLength,
  value,
  rows = 4,
  onChange,
  className = '',
}) => {
  const handleOnChange = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
    }
  };

  return (
    <div className={`custom-textarea ${className}`} onKeyPress={handleKeyPress}>
      <TextArea
        rows={rows}
        placeholder={placeHolder}
        value={value}
        onChange={handleOnChange}
        maxLength={maxLength}
      />
    </div>
  );
};

export default CustomTextArea;
