export interface IFilter {
  name: string,
  type: string,
  value1?: string | number[] | number,
  value2?: string
}
class Filter implements IFilter {
  name: string;
  type: string;
  value1?: string | number[] | number;
  value2?: string;

  constructor(request: IFilter) {
    this.name = request.name;
    this.value2 = request.value2;
    this.value1 = request.value1;
    this.type = request.type;
  }
}

export default Filter;
