import { DatePicker } from 'antd';
import moment from 'moment';
import React from 'react';

import { CalendarIcon } from 'components/Icons';
import { DATE_FORMAT } from 'constants/date';

const { RangePicker } = DatePicker;

interface ICustomRangeDatePicker {
  label?: string,
  value: [moment.Moment, moment.Moment],
  onChange?: (dates:[moment.Moment, moment.Moment]) => void,
  separator?: React.ReactNode,
  format?: string,
}

const CustomRangeDatePicker = (props: ICustomRangeDatePicker) => {
  const {
    label = '',
    value,
    onChange = () => null,
    separator,
    format = DATE_FORMAT,
  } = props;

  const handleChangeDateRange = (dates: any) => {
    onChange(dates);
  };

  return (
    <div className="custom-range-date-picker">
      {label && (
      <span className="custom-range-date-picker__label">
        {label}
      </span>
      )}
      <RangePicker
        className="custom-range-date-picker__input"
        format={format}
        onChange={handleChangeDateRange}
        value={value}
        defaultValue={value}
        separator={separator}
        suffixIcon={<CalendarIcon />}
        allowClear={false}
      />
    </div>
  );
};

export default CustomRangeDatePicker;
