import React from 'react';

import SurveyTitle from '../SurveyTitle';

interface SectionProps {
  order?: number;
  title?: string;
  children: React.ReactNode;
}

const Section = (props: SectionProps) => {
  const { title, order, children } = props;
  return (
    <div className="create-survey__section">
      { (title && order) && <SurveyTitle index={order} content={title} />}
      {children}
    </div>
  );
};

export default Section;
