import classNames from 'classnames';
import React from 'react';

interface IPoint {
  status: 'positive' | 'neutral' | 'negative' | undefined,
  text?: string;
  number: string
}
const Point: React.FC<IPoint> = ({ status, text = 'point', number }) => (
  <div className="point">
    <div className="point__status">
      <i className={classNames({
        'point__status--positive': status === 'positive',
        'point__status--neutral': status === 'neutral',
        'point__status--negative': status === 'negative',
      })}
      />
      <span>{text}</span>
    </div>
    <span>{number}</span>
  </div>
);

export default Point;
