import moment from 'moment';

import { DATE_DEFAULT, DATE_FORMAT_YYYY_MM_DD } from './date';

import Filter from 'models/Dashboard/Filter';
import RequestSurveyFilter from 'models/Survey/RequestSurveyFilter';

export const DATE_FROM_DEFAULT = moment().subtract(30, 'days').format(DATE_DEFAULT);
export const DATE_TO_DEFAULT = moment().add(30, 'days').format(DATE_DEFAULT);

export const FILTER_STATUS = 'status';
export const FILTER_DATE_FROM = 'dateFrom';
export const FILTER_DATE_TO = 'dateTo';
export const FILTER_SURVEY_NAME = 'surveyName';
export const FILTER_DATE_PICKER = 'datePicker';
export const FILTER_CLUB = 'club';
export const FILTER_TAG_GROUP = 'tagGroup';
export const FILTER_TYPE_ALL = 'AL';
export const FILER_TYPE_SE = 'SE';
export const FILTER_MOST_MENTION = 0;
export const FILTER_MOST_RECENT = 1;

export const FILTER_SURVEY_DEFAULT = [
  new RequestSurveyFilter(
    '=',
    'string',
    FILTER_DATE_FROM,
    DATE_FROM_DEFAULT,
  ),
  new RequestSurveyFilter(
    '=',
    'string',
    FILTER_DATE_TO,
    DATE_TO_DEFAULT,
  ),
];

export const FILTER_DASHBOARD_DEFAULT = [
  new Filter({
    name: FILTER_DATE_PICKER,
    type: 'RA',
    value1: moment().subtract('30', 'days').format(DATE_FORMAT_YYYY_MM_DD),
    value2: moment().format(DATE_FORMAT_YYYY_MM_DD),
  }),
];
