export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const DATE_FORMAT_FOR_SUBMIT = 'YYYY-MM-DDTHH:mm:ss';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const SUBTENANT_ID = 'SUBTENANT_ID';
export const TENANT_ID = 'TENANT_ID';
export const NEGATIVE = 'negative';
export const POSITIVE = 'positive';
export const OPERATOR_MORE_THAN_CODE = 'MoreThan';
export const ALL_STATUS_CODE = 'AL';

export const SEND_VIA_LIST = [
  {
    label: 'TO_DO_EMAIL',
    value: 'isSendViaEmail',
  },
  {
    label: 'TS.SMS',
    value: 'isSendViaSMS',
  },
  {
    label: 'TS.FISIKAL_SURVEY',
    value: 'isSendViaFisikal',
  },
];
export const API_STATUS = {
  SUCCESS: 'success',
  FAILED: 'failed',
  LOADING: 'loading',
};
export const TYPE = {
  INT: 'int',
  DATE: 'datetime',
  STRING: 'string',
};

export const CRITERIA_GROUP = {
  AGE: 'Age',
  CLUB: 'Club',
  JOIN_DATE: 'JoinDate',
  LAST_ATTENDANCE: 'LastAttendance',
  MEMBER_STATUS: 'MemberStatus',
  LAST_ATTENDANCE_CLUB: "LastAttendedClub"
};

export const VALUE_TYPE = {
  SELECT: 'select',
  INPUT: 'input',
  DATE_TIME: 'dateTime',
};
export const SEND_VIA = {
  EMAIL: 'isSendViaEmail',
  SMS: 'isSendViaSMS',
  FISIKAL: 'isSendViaFisikal',
};
export const BOOLEAN_VALUE = {
  TRUE: 'true',
  FALSE: 'false',
};
export const NOTIFICATION_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
};
export const SUFFIX_TEXT = {
  DAYS: 'day(s)',
};

export const ALL_CLUB_LABEL = 'all clubs';
