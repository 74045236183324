import { Badge, Tabs } from 'antd'; 

import MentionItem from './MentionItem';

import Survey from 'models/Survey/Survey';
import { useTranslation } from 'react-i18next';

interface IMentionList {
  surveyPositiveFeedbacks: Survey[];
  surveyNeutralFeedbacks: Survey[];
  surveyNegativeFeedbacks: Survey[];
  totalPositivePoint: number,
  totalNeutralPoint: number,
  totalNegativePoint: number,
}

const { TabPane } = Tabs;

const MentionList = (props: IMentionList) => {
  const { t } = useTranslation();
  const {
    surveyPositiveFeedbacks,
    surveyNeutralFeedbacks,
    surveyNegativeFeedbacks,
    totalPositivePoint,
    totalNeutralPoint,
    totalNegativePoint,

  } = props;
  return (
    <div className="mention">
      <Tabs
        className="mention-tabs"
        defaultActiveKey="1"
      >
        <TabPane
          key="1"
          tab={(
            <div className="mention-tabs__item">
              <span className="mention-tabs__item-image  mention-tabs__item--positive" />
              <span className="mention-tabs__item-name">
                {t('ALL_RESPONSES_FILTER_RESPONSES_POSITIVE')}
              </span>
              <Badge className="mention-tabs__item-badge mention-tabs__item-badge--positive" count={totalPositivePoint} overflowCount={999} />
            </div>
          )}
        >
          {surveyPositiveFeedbacks.map((survey) => <MentionItem key={survey.responseId} survey={survey} status="positive" />)}
        </TabPane>
        <TabPane
          key="2"
          tab={(
            <div className="mention-tabs__item">
              <span className="mention-tabs__item-image  mention-tabs__item--neutral" />
              <span className="mention-tabs__item-name">
                {t('ALL_RESPONSES_FILTER_RESPONSES_NEUTRAL')}
              </span>
              <Badge className="mention-tabs__item-badge mention-tabs__item-badge--neutral" count={totalNeutralPoint} overflowCount={999} />
            </div>
          )}
        >
          {surveyNeutralFeedbacks.map((survey) => <MentionItem key={survey.responseId} survey={survey} status="neutral" />)}
        </TabPane>
        <TabPane
          key="3"
          tab={(
            <div className="mention-tabs__item">
              <span className="mention-tabs__item-image  mention-tabs__item--negative" />
              <span className="mention-tabs__item-name">
                {t('ALL_RESPONSES_FILTER_RESPONSES_NEGATIVE')}
              </span>
              <Badge className="mention-tabs__item-badge mention-tabs__item-badge--negative" count={totalNegativePoint} overflowCount={99} />
            </div>
          )}
        >
          {surveyNegativeFeedbacks.map((survey) => <MentionItem key={survey.responseId} survey={survey} status="negative" />)}
        </TabPane>
      </Tabs>
    </div>
  );
};
export default MentionList;
