export interface ITag {
  tagName: string,
  tagGroupId?: number,
  feedbackId?: number,
  tagId?: number,
  tagSentiment?: number,
  surveyAnswerTagId? : number,
  sourceType?: number;
}

class Tag implements ITag {
  tagName: string;
  tagGroupId?: number;
  tagId?: number;
  tagSentiment?: number;
  feedbackId?: number;
  surveyAnswerTagId?: number;
  sourceType?: number;

  constructor(tag: ITag) {
    this.tagName = tag.tagName;
    this.tagGroupId = tag.tagGroupId;
    this.tagId = tag.tagId ?? 0;
    this.tagSentiment = tag.tagSentiment;
    this.feedbackId = tag.feedbackId;
    this.surveyAnswerTagId = tag.surveyAnswerTagId ?? 0;
    this.sourceType = tag.sourceType;
  }
}

export default Tag;
