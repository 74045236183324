import { formatUrlApiWithId } from '../utils/stringHelper';

import { http } from './common';
import {
  DELETE_RESPONSE_DATA, GET_TODO, LOG_ATTEMPT, UPDATE_RESPONSE_DATA,
} from './common/apis';

import { IResponseRequest } from 'models/IResponseRequest';
import { IResponseResponse } from 'models/IResponseResponse';
import { IUpdateResponse } from 'models/IUpdateResponse';

export const getAllTodo = async (body: IResponseRequest): Promise<IResponseResponse> => {
  const res = await http.post(GET_TODO, body);
  return res.data;
};

export const submitLogAttempt = async (id: string, data: {
  isSuccess: boolean,
  contactType: string,
  contactValue: string,
  content: string
  operatorId: number
}) => {
  const response = await http.post(formatUrlApiWithId(LOG_ATTEMPT, id), data);
  return response.data;
};

export const deleteResponseData = async (responseId: number) => {
  const response = await http.delete(DELETE_RESPONSE_DATA, {
    data: {
      responseId,
    },
  });
  return response.data;
};

export const updateResponse = async (body: IUpdateResponse) => {
  const response = await http.put(UPDATE_RESPONSE_DATA, body);
  return response.data;
};

export default {
  getAllTodo,
  submitLogAttempt,
  deleteResponseData,
  updateResponse,
};
