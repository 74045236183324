import { Carousel } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import moment from 'moment';
import { useRef, useState } from 'react';

import CustomButton from 'components/CustomButton';
import CustomModal from 'components/CustomModal';
import { ORANGE } from 'constants/button';
import { DATE_FORMAT_WITH_TIME } from 'constants/date';
import { useTranslation } from 'react-i18next';

interface IEmailModal {
  onCloseEmailModal: () => void;
  emailDetail: {content: string, contactDate: string}[]
}

const EmailModal = (props: IEmailModal) => {
  const { t } = useTranslation();
  const { onCloseEmailModal, emailDetail } = props;
  const carousel = useRef<CarouselRef>(null);
  const [timeSent, setTimeSent] = useState<string>(emailDetail[0].contactDate ?? '');

  const onNextEmail = () => {
    if (carousel.current) {
      carousel.current.next();
    }
  };

  const onPreviousEmail = () => {
    if (carousel.current) {
      carousel.current.prev();
    }
  };

  const onchangeTitle = (current: number) => {
    setTimeSent(emailDetail[current].contactDate ?? '');
  };

  return (
    <CustomModal
      className="email-modal"
      title={`${t('TS.EMAIL.AT')}  ${moment.utc(timeSent).format(DATE_FORMAT_WITH_TIME)}`}
      visible
      width={800}
      footer={(
        <div className="email-modal__footer">
          <CustomButton
            onClick={onPreviousEmail}
            className="email__previous"
            label={t('TS.PREV.EMAIL')}

          />
          <CustomButton
            className="email__next"
            onClick={onNextEmail}
            label={t('TS.NEXT.EMAIL')}
            backgroundColor={ORANGE}
          />
        </div>
      )}
      onCancel={onCloseEmailModal}
    >

      <div className="email-modal__body">
        <Carousel
          dots={false}
          ref={carousel}
          afterChange={(current: number) => onchangeTitle(current)}
        >
          { emailDetail.map((email) => (
            <div key={email.contactDate}>
              <p
                dangerouslySetInnerHTML={{
                  __html: email.content,
                }}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </CustomModal>
  );
};

export default EmailModal;
