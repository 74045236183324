import { Modal, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { CloseIcon } from 'components/Icons';

interface ICustomModal {
  title?: string | React.ReactNode,
  children?: React.ReactNode,
  onCancel: () => void,
  width?: number,
  footer?: React.ReactNode,
  className?: string,
  visible: boolean,
  onOk?: () => void,
}

const CustomModal = (props: ICustomModal) => {
  const { t } = useTranslation();
  const {
    title = '',
    onCancel = () => null,
    width = 400,
    children = null,
    footer = null,
    className = '',
    visible = false,
    onOk = () => null,
  } = props;

  return (
    <Modal
      title={title}
      className={className}
      closeIcon={(
        <Tooltip
          title={t('TS.CLOSE')}
          placement="right"
        >
          <div>
            <CloseIcon />
          </div>
        </Tooltip>
      )}
      width={width}
      footer={footer}
      onCancel={onCancel}
      visible={visible}
      onOk={onOk}
      destroyOnClose
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
