import { IClub } from 'models/Club';

export interface ISurvey {
  no: number;
  id: number;
  surveyName: string;
  creator: string;
  createdDate: string;
  status: string | boolean;
  club?: IClub,
  feedbackContent?: string;
  memberSurveyEventId?: number;
  membershipNumber?: string;
  point?: number;
  responseId?: number;
  fullName?: string;
  key?: number;
  memberId?: string;
  imageUrl?: string;
}

class Survey implements ISurvey {
  id: number;
  surveyName: string;
  creator: string;
  createdDate: string;
  status: string | boolean;
  no: number;
  club?: IClub;
  feedbackContent?: string;
  memberSurveyEventId?: number;
  membershipNumber?: string;
  point?: number;
  responseEdited?: {
    point: number;
    feedbackContent: string;
    club: {
      id: number;
      name: string;
    };
  };
  responseId?: number;
  fullName?: string;
  key?: number;
  memberId?: string;
  imageUrl?: string;

  constructor(
    survey: ISurvey,
  ) {
    this.id = survey.id;
    this.surveyName = survey.surveyName;
    this.createdDate = survey.createdDate;
    this.status = survey.status;
    this.creator = survey.creator;
    this.no = survey.no;
    this.club = survey.club;
    this.feedbackContent = survey.feedbackContent;
    this.memberSurveyEventId = survey.memberSurveyEventId;
    this.membershipNumber = survey.membershipNumber;
    this.point = survey.point;
    this.responseId = survey.responseId;
    this.fullName = survey.fullName;
    this.key = survey.no;
    this.memberId = survey.memberId;
    this.imageUrl = survey.imageUrl;
  }
}
export default Survey;
