import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import MENU_SLIDE_BAR from 'constants/menus';
import { CREATE_SURVEY_ROUTE } from 'constants/routes';
import { useAppSelector } from 'store/hooks';

const SideBar: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const { permissions } = useAppSelector((state) => state.role);

  return (
    <div className="sidebar">
      <h5 className="sidebar__title">{t('TS.TITLE')}</h5>
      <ul>
        {
          MENU_SLIDE_BAR.map((menu) => (menu.permissionAccess
            .some((menuPermission) => permissions.includes(menuPermission))
            ? (
              <li key={menu.url}>
                <Link
                  to={menu.url}
                  className={classNames({
                    active: location.pathname === menu.url
                  || (menu.title === MENU_SLIDE_BAR[1].title
                     && location.pathname === CREATE_SURVEY_ROUTE),
                  })}
                >
                  <div className="sidebar__menu">
                    { React.createElement(menu.icon)}
                    <span>{t(menu.title)}</span>
                  </div>
                </Link>
              </li>
            )
            : null))
        }
      </ul>
    </div>
  );
};
export default SideBar;
