import { OidcClientSettings } from 'oidc-client-ts';

const DOMAIN_URL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
const CALLBACK_URL = '/callback';
const { _env } = window;
const oidcConfig: OidcClientSettings = {
  authority: _env.REACT_APP_OIDC_AUTHORITY,
  client_id: _env.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: `${DOMAIN_URL}${CALLBACK_URL}`,
  scope: 'openid profile config-data role-data member-data',
  post_logout_redirect_uri: DOMAIN_URL,

};

export default oidcConfig;
