import { useTranslation } from 'react-i18next';

import CustomButton from 'components/CustomButton';
import CustomModal from 'components/CustomModal';
import { ORANGE } from 'constants/button';

interface IConfirmModal {
  className?: string;
  content?: string,
  isSubmitting?: boolean
  title?: string,
  okLabel?: string,
  cancelLabel?: string,
  onConfirmModal: () => void,
  onCloseModal: () => void,
}

const ConfirmModal = (props: IConfirmModal) => {
  const { t } = useTranslation();
  const {
    title,
    onConfirmModal,
    onCloseModal,
    content = t('TS.ARE_YOU_SURE_REMOVE'),
    okLabel = t('TS.OK'),
    cancelLabel = t('TS.CANCEL'),
    isSubmitting = false,
    className = '',
  } = props;

  return (
    <CustomModal
      className={`confirm-modal ${className}`}
      title={title}
      visible
      onCancel={onCloseModal}
      width={400}
    >
      <p className="confirm-modal__content">
        {content}
      </p>
      <div className="confirm-modal__footer">
        <CustomButton
          label={cancelLabel}
          className="confirm-modal__cancel"
          onClick={onCloseModal}
        />
        <CustomButton
          label={okLabel}
          className="confirm-modal__confirm"
          onClick={onConfirmModal}
          loading={isSubmitting}
          backgroundColor={ORANGE}
        />
      </div>
    </CustomModal>
  );
};

export default ConfirmModal;
