export const defaultFilter = {
  datePicker: [],
  responses: [],
  assignedOperators: [],
  membershipMonth: [],
  club: [],
  surveyName: [],
};

export const defaultPaging = {
  pageIndex: 1,
  pageSize: 10,
};

export default {
  defaultFilter,
  defaultPaging,
};
