import React from 'react';

interface IMenu {
  url: string,
  title: string,
  icon: React.FunctionComponent,
  permissionAccess: string[]
}

class Menu implements IMenu {
  url: string;
  title: string;
  icon: React.FunctionComponent;
  permissionAccess: string[];

  constructor(
    url: string,
    title: string,
    icon: React.FunctionComponent,
    permissionAccess: string[],
  ) {
    this.url = url;
    this.title = title;
    this.icon = icon;
    this.permissionAccess = permissionAccess;
  }
}

export default Menu;
