import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Calendar, Notification } from 'components/Icons';
import { signOutRedirect } from 'store/profile';
import { AppDispatch } from 'store';

const Header = () => {
  const { t } = useTranslation();
 const dispatch = useDispatch<AppDispatch>();

  const handleLogout = () => {
    dispatch(signOutRedirect());
  };

  return (
    <header className="header">
      <div className="header__info">
        <button type="button" className="btn">
          <Notification />
        </button>
        <button type="button" className="btn btn-link" onClick={handleLogout}>
          <Calendar />
          <span className="btn-link__text">{t('TS.LOGOUT.TITLE')}</span>
        </button>
      </div>
    </header>
  );
};

export default Header;
